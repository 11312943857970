import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AreaMatchModel, DayModel, SportModel, TodaysEventsState } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { TodaysEventsStore } from 'src/app/modules/sport/state/todays-events/todays-events.store';
import { AreaModel, MarketModel } from 'src/app/shared/models/sport.model';

@Injectable({ providedIn: 'root' })
export class TodaysEventsQuery extends Query<TodaysEventsState> {
  correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
  areaMatches$ = this.select(state => state.areaMatches);
  regionsWithAreas$ = this.select(state => state.regionsWithAreas);
  areas$ = this.select(state => state.areas);
  regions$ = this.select(state => state.regions);
  sports$ = this.select(state => state.sports);
  selectedTournamentId$ = this.select(state => state.selectedTournamentId);
  matchesByTournament$ = this.select(state => state.matchesByTournament);
  tournaments$ = this.select(state => state.tournaments);
  selectedDay$ = this.select(state => state.selectedDay);
  selectedRegion$ = this.select(state => state.selectedRegion);
  selectedArea$ = this.select(state => state.selectedArea);
  selectedMarket$ = this.select(state => state.selectedMarket);
  sortType$ = this.select(state => state.sortType);
  isEventsLoading$ = this.select(state => state.ui.isEventsLoading);
  firstTimeLoadIsInProgress$ = this.select(state => state.ui.firstTimeLoadIsInProgress);
  flowControl$ = combineLatest(this.selectedDay$, this.selectedRegion$, this.selectedArea$);
  isItMultiline$ = this.select(state => state.isItMultiline);
  isItCorrectScores$ = this.selectedArea$.pipe(
    map(area => {
      let isItCorrectScore = false;
      this.correctScoreAreaIds.forEach(areaId => {
        if (area && areaId === area.id) {
          isItCorrectScore = true;
        }
      });
      return isItCorrectScore;
    })
  );
  isItOverUnder$ = this.select(state => state.isItOverUnder);
  isRegularMarket$ = this.select(state => state.isRegularMarket);
  isPlayerMarket$ = this.select(state => state.isPlayerMarket);

  constructor(private readonly appConfig: AppConfigService, protected store: TodaysEventsStore) {
    super(store);
  }

  get areaMatches(): AreaMatchModel[] {
    return this.getValue().areaMatches;
  }
  get isItMultiline(): boolean {
    return this.getValue().isItMultiline;
  }
  get isItCorrectScores(): boolean {
    return this.getValue().isItCorrectScores;
  }
  get isItOverUnder(): boolean {
    return this.getValue().isItOverUnder;
  }

  get selectedTournamentId(): number {
    return this.getValue().selectedTournamentId;
  }

  get selectedDay(): DayModel {
    return this.getValue().selectedDay;
  }

  get selectedArea(): AreaModel {
    return this.getValue().selectedArea;
  }

  get selectedSport(): SportModel {
    return this.getValue().selectedSport;
  }

  get selectedMarket(): MarketModel {
    return this.getValue().selectedMarket;
  }

  get tournaments(): any {
    return this.getValue().tournaments;
  }

  get isEventsLoading(): boolean {
    return this.getValue().ui.isEventsLoading;
  }

  get firstTimeLoadIsInProgress(): boolean {
    return this.getValue().ui.firstTimeLoadIsInProgress;
  }
}
