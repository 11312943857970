import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { XSellSwiperComponent } from 'src/app/modules/x-sell/components/swiper/x-sell-swiper.component';
import { CasinoXSellService } from 'src/app/modules/x-sell/services/casino-x-sell.service';
import { CasinoXSellQuery } from 'src/app/modules/x-sell/state/casino-x-sell.query';

@Component({
  selector: 'x-sell-casino-swiper',
  templateUrl: './casino-x-sell-swiper.component.html',
  styleUrls: ['./casino-x-sell-swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoXSellSwiperComponent implements OnInit, OnDestroy {
  @ViewChild(XSellSwiperComponent, { static: true }) readonly swiper: XSellSwiperComponent;

  readonly swiperContentHasBeenShown$ = new BehaviorSubject(false);
  readonly showSwiper$ = new BehaviorSubject(false);

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    readonly xSellQuery: CasinoXSellQuery,
    private readonly accountQuery: AccountQuery,
    private readonly casinoXSellService: CasinoXSellService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.casinoXSellService.initialise();
    this.swiper.swipeableContentHasBeenShown$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(hasBeenShown => this.swiperContentHasBeenShown$.next(hasBeenShown));

    combineLatest([
      this.xSellQuery.isInitialised$,
      this.xSellQuery.hasContentURL$,
      this.xSellQuery.isWhitelistedToShow$,
      this.xSellQuery.showInRelationToAuthCheck$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isInitialised, hasContentURL, isWhitelistedToShow, showInRelationToAuthCheck]) => {
        this.showSwiper$.next(isInitialised && hasContentURL && isWhitelistedToShow && showInRelationToAuthCheck);
      });

    this.swiper.swipeableContentOpen$
      .pipe(
        distinctUntilChanged(),
        tap(isOpen => {
          if (isOpen) {
            this.dataLayerService.createDataLayerEvent({
              event: 'Casino_Gameplay_Initiated',
              location: 'Sportsbook_Live_betting_xsell',
              user_id: this.accountQuery.isAuthenticated ? this.accountQuery.userData.id : 'no-auth',
              product: this.xSellQuery.contentProduct,
              interaction_type: this.swiper.previousInteractionWasASwipe$.value ? 'swipe' : 'tap',
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
