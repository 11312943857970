import { ChangeDetectionStrategy, Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';

@Component({
  selector: 'app-acca-bonus-unacceptable-selections-prompt',
  templateUrl: './acca-bonus-unacceptable-selections-prompt.component.html',
  styleUrls: ['./acca-bonus-unacceptable-selections-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccaBonusUnacceptableSelectionsPromptComponent implements OnDestroy {
  @Output() readonly closePrompt = new EventEmitter();

  readonly MIN_ACCA_ODD_VALUE_KEY = '[[Min_Acca_Odd_Value]]';
  private readonly destroy$ = new Subject<boolean>();

  constructor(readonly accumulatorBonusQuery: AccumulatorBonusQuery) {}

  close(): void {
    this.closePrompt.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
