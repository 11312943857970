<app-header [headerPinToTop]="headerPinToTop"></app-header>
<nav class="breadcrumb-navigation">
  <div class="breadcrumb-navigation-back-icon">
    <span class="material-icons" (click)="goBack()" data-testid="breadcrumb-navigation-go-back">{{ 'arrow_back' }}</span>
  </div>
  <ul class="breadcrumb-navigation-scrollable-container">
    <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <app-sports-breadcrumb-item
        [ngClass]="{ active: last }"
        [breadcrumb]="breadcrumb"
        [isLastCrumb]="last"
        [openedDropdownItem]="openedBreadcrumbSection$ | async"
      >
      </app-sports-breadcrumb-item>
    </li>
  </ul>
</nav>
