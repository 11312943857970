<x-sell-swiper
  [hidden]="(showSwiper$ | async) === false"
  [xSellIconURL]="xSellQuery.xSellIcon$ | async"
  [xSellIconBGColor]="xSellQuery.xSellColor$ | async"
  [spbIconURL]="xSellQuery.spbIcon$ | async"
  [spbIconBGColor]="xSellQuery.spbColor$ | async"
  [startingScreenPosition]="xSellQuery.startingScreenPosition$ | async"
  data-testid="casino-xsell-swiper"
>
  <div class="content-wrapper" x-sell-content>
    <iframe *ngIf="swiperContentHasBeenShown$ | async" [src]="xSellQuery.contentURL$ | async | safeUrl"
      id="swiper-iframe"></iframe>
  </div>
</x-sell-swiper>