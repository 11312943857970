<swal
  #evaluation
  (confirm)="evaluationReplyClicked(couponData.couponCode, true, couponData.userId)"
  (cancel)="evaluationReplyClicked(couponData.couponCode, false, couponData.userId)"
  [swalOptions]="options"
>
  <div *swalPortal class="evaluation-container">
    <div class="max-win-changed">
      <span class="label" i18n>Potential winnings have changed:</span>
      <div [class.taxed]="couponData.maxWithholdingTax > 0">
        <span class="value">{{ couponData.maxWinNet | currencyFormat }}</span>
        <span class="tax value" *ngIf="couponData.maxWithholdingTax > 0">
          <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>(Income TAX (15%):</ng-container>
          <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>(Tax:</ng-container>
          {{ couponData.maxWithholdingTax | currencyFormat }}
          <ng-container>)</ng-container>
        </span>
      </div>
    </div>

    <div class="coupon-code">{{ couponData.couponCode }}</div>

    <div class="event-details" *ngIf="couponData.evalCouponStatusId !== 8">
      <ng-container *ngFor="let event of couponData.odds; trackBy: indexTrackBy">
        <div class="match-content" *ngIf="event.oddValue !== event.confirmedOddValue">
          <div class="match-details">
            <div class="smart-code" *ngIf="event.eventCategory !== 'L'">{{ event.onLineCode }}</div>
            <div class="live" *ngIf="event.eventCategory === 'L'" i18n>L</div>
            <span class="divider">|</span>
            <div class="match-name">{{ event.eventName }}</div>
          </div>
          <div class="market-details">
            <div class="market">
              <span class="market-name">{{ event.marketName }}</span>
              <span class="divider">-</span>
              <span class="selection-name">{{ parseSelectionName(event.marketTypeId, event.selectionName, event.eventName) }}</span>
            </div>
            <div class="odd">
              <span class="odd-value">
                {{ event.oddValue | number: '1.2-2' }}
              </span>
              <i
                class="fa fa-caret-right"
                [class.low]="event.oddValue > event.confirmedOddValue"
                [class.high]="event.oddValue < event.confirmedOddValue"
              ></i>
              <span class="confirmed-odd">
                {{ event.confirmedOddValue | number: '1.2-2' }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="stake-details" *ngIf="couponData.evalCouponStatusId !== 9">
      <div class="old-stake">
        <span class="label" i18n>Old Stake:</span>
        <span class="value">{{ couponData.stake | currencyFormat }}</span>
      </div>
      <div class="confirmed-stake">
        <span class="label" i18n>Confirmed Stake:</span>
        <span class="value">{{ couponData.confirmedStake | currencyFormat }}</span>
      </div>
    </div>
  </div>
</swal>
