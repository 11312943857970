import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { Quicklinks } from 'src/app/shared/models/sport.model';
@Component({
  selector: 'app-tab',
  templateUrl: './app-tab.component.html',
  styleUrls: ['./app-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTabComponent {
  @Input() tabsData: [] = [];
  @Input() selectedTab: any;
  @Input() labelIdentifier: string = 'name';
  @Input() selectedIdentifier: string = 'type';
  @Output() readonly tabSwitchEvent = new EventEmitter();

  readonly Quicklinks: typeof Quicklinks = Quicklinks;

  onChange(targetWidget): void {
    this.tabSwitchEvent.emit(targetWidget);
  }
}
