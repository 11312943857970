<ng-container *ngIf="!isCustomized">
  <div class="option-wrap" [class.selected]="selected">
    <div class="option">
      <div class="cut-info">
        <div class="cut-value" data-testid="flexicut-cut-number" i18n>Cut {{ cutNumber$ | async }}</div>
        <p class="winning-info" i18n>{{ winningSelections$ | async }} or more matches to win</p>
        <div i18n>Potential Winnings: <span class="potential-winning">{{ potentialWinning | currencyFormat }}</span></div>
      </div>
      <div class="select-cut" (click)="onOptionSelected($event)">
        <span class="material-icons icon">{{'check'}}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isCustomized">
  <div class="option-wrap" [class.selected]="selected">
    <div class="option">
      <div class="cut-info">
        <div class="flexicut-cut-config-container" data-testid="flexicut-cut-config">
          <div class="flexicut-cut-config">
            <button
              class="selections-arrow"
              [class.disabled]="cannotDecreaseCut$ | async"
              (click)="reduceCut()"
              data-testid="flexicut-decrease-cut"
            >
              <i class="material-icons"> {{ 'remove' }}</i>
            </button>

            <div class="cut-value" data-testid="flexicut-cut-number" i18n>Cut {{ cutNumber$ | async }}</div>

            <button
              class="selections-arrow"
              [class.disabled]="cannotIncreaseCut$ | async"
              (click)="increaseCut()"
              data-testid="flexicut-increase-cut"
            >
              <i class="material-icons"> {{ 'add' }}</i>
            </button>
          </div>
        </div>

        <p class="winning-info" i18n>{{ winningSelections$ | async }} or more matches to win</p>
        <div i18n>Potential Winnings: <span class="potential-winning">{{ potentialWinning | currencyFormat }}</span></div>
      </div>
      <div class="select-cut" (click)="onOptionSelected()">
        <span class="material-icons icon">{{'check'}}</span>
      </div>
    </div>
    <div class="info-message" *ngIf="showTotalOddsInfoText$ | async" data-testid="flexicut-component-total-odds-info-message" i18n>
      Can not cut further selections due to limitations on total odds.
    </div>
  </div>
</ng-container>
