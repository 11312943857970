import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { BetCoupon, CouponType } from 'clientside-coupon';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { fadeInOutDelay } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { VirtualsCouponStakeHandlerService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-stake-handler.service';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';

@Component({
  selector: 'app-virtuals-coupon-totals',
  templateUrl: './virtuals-coupon-totals.component.html',
  styleUrls: ['./virtuals-coupon-totals.component.scss'],
  animations: [fadeInOutDelay()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsCouponTotalsComponent implements OnInit, OnChanges {
  @Input() couponData: BetCoupon;
  @Input() bookedCoupon: boolean = false;
  @Input() bookedCouponCode: string;
  @Input() bookedCouponDate: string;
  @Input() inSlideUp: boolean = false;

  currencySymbol: string;
  fixedStakes: number[];
  readonly buttonType: typeof ButtonType = ButtonType;
  readonly couponType: typeof CouponType = CouponType;
  readonly freeBetProductType: typeof FreeBetProductType = FreeBetProductType;
  readonly taxPercentageToShow = this.appConfigService.get('sports').coupon.stakeTaxPercentageToShow;
  readonly winningsInfoBubble = this.appConfigService.get('virtuals').winningsInfoBubble;
  readonly winningsInfoBubble$ = new BehaviorSubject<boolean>(false);
  readonly winningDetailsExpanded$ = new BehaviorSubject(false);
  readonly selectedFreebetVoucher$ = this.freebetQuery.selectedFreebetVoucher$(FreeBetProductType.VirtualsScheduled);
  readonly hasSelectedFreebetVoucher$ = this.freebetQuery.hasSelectedFreebetVoucher$(FreeBetProductType.VirtualsScheduled);

  constructor(
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    readonly applicationQuery: ApplicationQuery,
    private readonly freebetQuery: FreebetsQuery,
    private readonly appConfigService: AppConfigService,
    private readonly currencyService: CurrencyService,
    private readonly stakeHandlerService: VirtualsCouponStakeHandlerService,
    private readonly virtualsCouponStore: VirtualsCouponStore
  ) {}

  ngOnInit(): void {
    const virtualsConfig = this.appConfigService.get('virtuals');
    this.fixedStakes = virtualsConfig.fixedStakes;

    this.currencySymbol = this.currencyService.getCurrency(this.applicationQuery.currency).symbol;

    // set minimum stake if the stake is 0 on load
    if (this.couponData.StakeGross === 0) {
      this.updateStakeValue(this.couponData.BetCouponGlobalVariable.MinBetStake);
    }

    // reset the value of the stakeChanged state to false
    this.virtualsCouponStore.updateUI({ stakeChanged: false });
  }

  ngOnChanges(): void {
    this.winningsInfoBubble$.next(
      this.winningsInfoBubble &&
        ((this.couponData.TurnoverTax > 0 &&
          this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled &&
          this.couponData.CouponType !== this.couponType.System) ||
          (this.couponData.MinOdd > 0 && this.couponData.MinOdd !== this.couponData.MaxOdd))
    );

    // If info bubble is expanded, check whether we are still using it to see if we should keep it open
    this.winningDetailsExpanded$.next(this.winningDetailsExpanded$.getValue() ? this.winningsInfoBubble$.getValue() : false);
  }

  updateStakeValue(stakeValue: number): void {
    this.stakeHandlerService.updateStakeValue(stakeValue);
    this.virtualsCouponStore.updateUI({ stakeChanged: true });
  }

  resetStakeValue(): void {
    // reset stake to 0
    this.updateStakeValue(0);
  }

  addStakeValue(amount: string): void {
    let stakeAmount;

    if (this.virtualsCouponQuery.stakeChanged) {
      stakeAmount = this.couponData.StakeGross + parseFloat(amount);
    } else {
      stakeAmount = parseFloat(amount);
    }

    this.updateStakeValue(stakeAmount);
  }

  updateInputStakeValue(inputStake: number): void {
    const stakeValue = !inputStake || isNaN(inputStake) || inputStake < 0 ? 0 : inputStake;
    this.updateStakeValue(stakeValue);
  }

  toggleWinningsDetails(): void {
    this.winningDetailsExpanded$.next(!this.winningDetailsExpanded$.value);
  }

  hideInfoBubble(): void {
    this.winningDetailsExpanded$.next(false);
  }

  indexTrackBy(index: number): number {
    return index;
  }
}
