import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/account/login.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LogTypeModel } from 'src/app/shared/models/log.model';
import { has } from 'lodash-es';
import { RefreshTokenService } from 'src/app/core/services/account/refresh-token.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { AccountService } from 'src/app/core/services/account/account.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginResolver implements Resolve<any> {
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly loginService: LoginService,
    private readonly loggerService: LoggerService,
    private readonly accountService: AccountService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly refreshTokenService: RefreshTokenService,
    private readonly loadingService: LoadingService,
    private readonly cookieService: CookieService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    const token = route.queryParams.btk || route.queryParams.bkToken;
    if (token) {
      if (this.accountQuery.isAuthenticated) {
        if (token === this.accountQuery.accessToken) {
          if (route.queryParams.redirect) {
            this.router.navigateByUrl(route.queryParams.redirect);
          }
          return of(undefined).pipe(finalize(() => this.cleanURL()));
        } else {
          // If already logged in, loginWithToken function will log the user out and re-login with current token
          this.loggerService.logEvent(
            'Logging out current user',
            this.accountQuery.userData.username,
            SeverityLevel.Verbose,
            LogTypeModel.Network
          );
        }
      }
      return this.performAutoLogin(route, token);
    } else {
      if (
        !this.cookieService.getCookie('accessToken') &&
        this.cookieService.getCookie('refreshToken') &&
        this.cookieService.getCookie('app_id') === 'KINGMAKERS'
      ) {
        this.loadingService.enqueueLoader();
        return this.refreshTokenService.getRefreshToken().pipe(
          catchError(() => {
            this.accountService.clearUserData();
            this.cookieService.clearCookie('accessToken');
            this.cookieService.clearCookie('refreshToken');
            this.router.navigate(['/login']);
            return of(null);
          }),
          finalize(() => {
            this.loadingService.dequeueLoader();
          })
        );
      }
    }
    return of(undefined);
  }

  goToLogin(): void {
    this.router.navigate(['account', 'login']);
  }

  private performAutoLogin(route: ActivatedRouteSnapshot, token: string): Observable<void> {
    return this.loginService.loginWithToken(token).pipe(
      map((response: boolean) => {
        if (response) {
          this.loggerService.logEvent('AutoLogin performed', token, SeverityLevel.Verbose, LogTypeModel.Network);
        } else {
          this.loggerService.logEvent('AutoLogin failed', token, SeverityLevel.Information, LogTypeModel.Network);
          if (!has(route.queryParams, 'noLoginRedirect')) {
            this.goToLogin();
          }
        }
      }),
      catchError(err => {
        this.loggerService.logEvent('Error with AutoLogin', token, SeverityLevel.Error, LogTypeModel.Network);
        if (route.queryParams.showLoginOnError !== 'false') {
          this.goToLogin();
        }
        return of(undefined);
      }),
      finalize(() => {
        if (route.queryParams.redirect) {
          window.location.href = route.queryParams.redirect;
        } else if (typeof URLSearchParams !== 'undefined') {
          this.cleanURL();
        }
      })
    );
  }

  private cleanURL(): void {
    setTimeout(() => {
      const url = new URL(document.location as any);
      if (url.searchParams.has('btk')) {
        url.searchParams.delete('btk');
      }
      if (url.searchParams.has('bkToken')) {
        url.searchParams.delete('bkToken');
      }

      this.location.replaceState(url.pathname, url.search.length > 0 ? url.search : undefined);
    }, 0);
  }
}
