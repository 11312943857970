import { LiveQuery } from 'src/app/core/state/live/live.query';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LiveService } from 'src/app/core/services/live.service';
import { MatchService } from 'src/app/modules/sport/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { fadeIn } from 'src/app/shared/animations';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-header-live',
  templateUrl: './event-header-live.component.html',
  styleUrls: ['./event-header-live.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventHeaderLiveComponent implements OnInit {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showSportIcon: boolean = true;
  @Input() showTournamentName: boolean = true;
  productIconClass: string;

  constructor(
    readonly liveQuery: LiveQuery,
    readonly liveService: LiveService,
    readonly statisticsQuery: StatisticsQuery,
    readonly statisticsService: StatisticsService,
    private readonly matchService: MatchService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.productIconClass = `icon-${this.sportId}`;
  }
}
