import { Injectable } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';

@Injectable({
  providedIn: 'root',
})
/**
 * Full screen loader is not a regular Angular component as it is needed prior to app load.
 * It is located is loaded into the index.html and manipulated here.
 */
export class LoadingService {
  private readonly queue$: BehaviorSubject<number>;

  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly applicationQuery: ApplicationQuery,
    private readonly router: Router
  ) {
    this.queue$ = new BehaviorSubject<number>(0);
  }

  /**
   * Subscribe to route change events to automatically show/hide the
   * loading spinner
   */
  initialize(): void {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        // When switching route always reset the $queue value to 0
        // to cater for cases when dequeueLoader() is not called due
        // to components being destroyed
        this.enqueueLoader(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.dequeueLoader();
      }
    });

    this.handleLoaderChanges();
  }

  updateText(text: string = ''): void {
    this.applicationStore.updateLoader({
      text,
    });
  }

  updateFullscreen(isFullscreen: boolean = true): void {
    this.applicationStore.updateLoader({
      isFullscreen,
    });
  }

  /**
   * Increment the loader queue count
   *
   * @param resetCount If true, the queue count will be reset to 0 before incrementing it
   */
  enqueueLoader(resetCount: boolean = false): void {
    const queueCount = resetCount ? 0 : this.queue$.value;
    this.queue$.next(queueCount + 1);
  }

  /**
   * Decrement the loader queue count
   */
  dequeueLoader(): void {
    const queueCount = this.queue$.value - 1;
    this.queue$.next(queueCount > 0 ? queueCount : 0);
  }

  private handleLoaderChanges(): void {
    this.queue$
      .pipe(
        distinctUntilChanged(),
        tap(queueCount => {
          this.applicationStore.updateLoader({ show: queueCount > 0 });
        })
      )
      .subscribe();

    this.applicationQuery.loaderState$
      .pipe(
        distinctUntilChanged(),
        tap(state => {
          state.show ? this.showLoader() : this.hideLoader();
          state.isFullscreen ? this.setFullscreen() : this.setNotFullscreen();
          state.text ? this.setText(state.text) : this.removeText();
        })
      )
      .subscribe();
  }

  addClass(className: string): void {
    (document.getElementsByTagName('loading-spinner')[0] as HTMLElement).classList.add(className);
  }

  removeClass(className: string): void {
    (document.getElementsByTagName('loading-spinner')[0] as HTMLElement).classList.remove(className);
  }

  private showLoader(): void {
    (document.getElementsByTagName('loading-spinner')[0] as HTMLElement).style.display = 'block';
  }

  private hideLoader(): void {
    (document.getElementsByTagName('loading-spinner')[0] as HTMLElement).style.display = 'none';
  }

  private setFullscreen(): void {
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner').classList.remove('not-fullscreen');
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner').classList.add('fullscreen');
  }

  private setNotFullscreen(): void {
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner').classList.remove('fullscreen');
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner').classList.add('not-fullscreen');
  }

  private setText(text: string): void {
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner .text-container').style.display = 'block';
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner .text-container span').innerText = text;
  }

  private removeText(): void {
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner .text-container').style.display = 'none';
    document.querySelector<HTMLElement>('loading-spinner .loading-spinner .text-container span').innerText = '';
  }
}
