import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { CouponType } from 'clientside-coupon';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ABTestService } from 'src/app/core/services/ab-test.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponSelectionService } from 'src/app/core/services/coupon/coupon-selections.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { CouponOddsModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-quick-coupon',
  templateUrl: './quick-coupon.component.html',
  styleUrls: ['./quick-coupon.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickCouponComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  canBookCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  overlayRef: OverlayRef;

  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '14px',
    height: '44px',
    borderRadius: '50px',
  };

  oddChangesInterval = undefined;
  private oddChangesTimer;
  private overlayTimeout: any;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationService: ApplicationService,
    readonly couponQuery: CouponQuery,
    private readonly appConfigService: AppConfigService,
    private readonly couponEditService: CouponEditService,
    private readonly couponSelectionService: CouponSelectionService,
    private readonly couponService: CouponService,

    private readonly notificationService: NotificationService,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly router: Router,
    private readonly abTestService: ABTestService
  ) {
    this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
  }

  ngOnInit(): void {
    // TODO: Coupon Init
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(couponData => {
      if (couponData) {
        setTimeout(() => {
          // This had to be done so that the parsing is done in a separate digest cycle
          // if the coupon is edited within the betslip screen
          this.couponSelectionService.parseSelections(couponData.Odds);
        });
      }
    });

    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });

    if (this.couponQuery.oddChanges !== undefined && this.couponQuery.oddChanges.length > 0) {
      this.runCouponOddChanges();
    } else {
      this.stopCouponOddChanges();
    }

    let prevCouponSelectionIds = 0;
    this.couponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;
      }
    });
  }

  close(): void {
    this.applicationService.closeAnySlideUps();
  }

  runCouponOddChanges(): void {
    this.stopCouponOddChanges();

    const couponOdds = this.getCouponOdds();

    if (couponOdds !== undefined) {
      const containsLiveEvents = couponOdds.some(odd => odd.isLive);

      if (containsLiveEvents) {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.liveOddChangesTimer;
      } else {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
      }

      this.couponService.getOddsChanged(couponOdds).subscribe();
    }

    this.oddChangesInterval = window.setInterval(() => {
      this.runCouponOddChanges();
    }, this.oddChangesTimer);
  }

  stopCouponOddChanges(): void {
    if (this.oddChangesInterval) {
      clearInterval(this.oddChangesInterval);
    }
  }

  acceptOddChanges(): void {
    this.couponService.acceptOddChanges();
  }

  getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        marketTypeId: odd.MarketTypeId,
        selectionId: odd.SelectionId,
        selectionValue: odd.OddValue,
      });
    });

    return couponOdds;
  }

  postCoupon(): void {
    if (!this.canPostCoupon$.getValue()) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      this.canPostCoupon$.next(false);
      this.overlayTimeout = setTimeout(() => {
        this.showOverlay();
      }, 1000);
      this.couponService
        .validateAndPostCoupon(true)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          success => {
            // Success
            this.canPostCoupon$.next(true);
            this.disposeOverlay();
            if (success) {
              this.couponService.clearCouponData();
              this.close();
            }
          },
          () => {
            // Error
            this.canPostCoupon$.next(true);
            this.disposeOverlay();
            this.notificationService.showErrorNotification(
              $localize`An error has occurred. Please try again.`,
              $localize`Coupon Not Posted`
            );
          }
        );
    } else {
      this.applicationService.setShowLoginDialog(true);
    }
  }

  ngOnDestroy(): void {
    this.couponEditService.clearEditData();
    this.stopCouponOddChanges();
    this.disposeOverlay();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private showOverlay(): void {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay',
      panelClass: 'bet-loading',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const betInProgress = new TemplatePortal(this.betInProgress, this.vcr);
    this.overlayRef.attach(betInProgress);
  }

  private disposeOverlay(): void {
    if (this.overlayTimeout) {
      clearTimeout(this.overlayTimeout);
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }
}
