import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, of, race, Subject } from 'rxjs';
import { catchError, delay, map, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { LocalStorageService } from 'ngx-webstorage';
import merge from 'lodash-es/merge';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { APIType } from 'src/app/shared/models/api.model';
import { APIService } from 'src/app/core/services/api.service';

interface ServiceConfig {
  enabled: boolean;
  timeout: number;
}

@Injectable({
  providedIn: 'root',
})
export class ABTestService implements OnDestroy {
  private readonly FINGERPRINT_KEY = 'fingerprint';
  private readonly provider = 'VWO'; // used only for logging
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly loggerService: LoggerService,
    private readonly accountQuery: AccountQuery,
    private readonly localStorage: LocalStorageService,
    private readonly dataLayerService: DataLayerService,
    private readonly apiService: APIService
  ) {}

  private get fingerprint(): string | undefined {
    // TODO: This fingerprint logic can now probably be replaced with the _vwo_uuid cookie created by the client script
    const savedUserId = this.localStorage.retrieve(this.FINGERPRINT_KEY);
    if (savedUserId) {
      // return saved id
      return savedUserId.toString();
    }

    const userId = getBrowserFingerprint()?.toString();
    if (userId) {
      // save id if it was generated successfully
      this.localStorage.store(this.FINGERPRINT_KEY, userId);
      return userId;
    }
  }

  private get predefinedOptions() {
    return {
      ...(this.accountQuery.isAuthenticated && {
        customVariables: {
          userId: this.accountQuery.userData.id,
          userType: this.accountQuery.dataLayerUserType,
        },
      }),
    };
  }

  /**
   * Get the value of an AB test for the current user
   * @param testId The ID of the AB test
   * @param defaultValue The default value for the test
   * @param options Additional params to pass as described in the docs: [https://developers.vwo.com/docs/javascript-activate].
   *                userId and userType are automatically provided as customVariables for logged in users.
   * @returns Returns the value of the AB test if it is available for the current user, else returns the defaultValue
   */
  getValue(testId: string, defaultValue: string, options: any = {}): Observable<string> {
    const vwoConfig: ServiceConfig = this.appConfig.get('abTesting')?.config;
    if (!vwoConfig || !vwoConfig.enabled) {
      this.loggerService.logEvent(
        `ABTestService [${this.provider}]`,
        '[getValue] Service is disabled, default value will be used',
        SeverityLevel.Information
      );
      return of(defaultValue);
    } else if (!testId) {
      // avoid querying if a test id was not provided
      return of(defaultValue);
    }

    const activateCall = this.apiService
      .post(APIType.BFFGateway, `api/integration/v1/vwo/activate`, {
        campaignKey: testId,
        userId: this.fingerprint,
        options: merge(options, this.predefinedOptions),
      })
      .pipe(
        map(res => {
          return res?.data?.value ?? defaultValue;
        }),
        catchError((error: Error) => {
          this.loggerService.logEvent(
            `ABTestService [${this.provider}]`,
            `[getValue] Unexpected error occurred: ${error?.message}`,
            SeverityLevel.Error
          );
          return of(defaultValue);
        }),
        takeUntil(this.destroy$)
      );

    const timeoutCall = of(defaultValue).pipe(
      delay(vwoConfig.timeout),
      tap(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'vwo-timeout',
          userId: this.accountQuery.userData?.id,
          timeoutSetting: vwoConfig.timeout,
          fingerprintId: this.fingerprint,
        });
        this.loggerService.logEvent(
          `ABTestService [${this.provider}]`,
          `[getValue] Operation exceeded the configured timeout of ${vwoConfig.timeout}ms`,
          SeverityLevel.Error
        );
      })
    );

    return race(activateCall, timeoutCall);
  }

  /**
   * Track an event for the specified AB test
   * @param testId The ID of the AB test
   * @param eventId The ID of the event
   * @param options Additional params to pass as described in the docs: [https://developers.vwo.com/docs/javascript-track].
   *                Event property values should be passed in the 'eventProperties' object.
   *                'userId' and 'userType' are automatically added as customVariables for logged in users.
   */
  track(testId: string, eventId: string, options: any = {}): Observable<boolean> {
    const vwoConfig: ServiceConfig = this.appConfig.get('abTesting')?.config;
    if (!testId || !eventId || !vwoConfig || !vwoConfig.enabled) {
      return of(false);
    }

    return this.apiService
      .post(APIType.BFFGateway, `api/integration/v1/vwo/track`, {
        campaignKey: testId,
        userId: this.fingerprint,
        goalIdentifier: eventId,
        options: merge(options, this.predefinedOptions),
      })
      .pipe(
        map(res => {
          return res?.data?.tracked ?? false;
        }),
        catchError((error: Error) => {
          this.loggerService.logEvent(
            `ABTestService [${this.provider}]`,
            `[track] Unexpected error occurred: ${error?.message}`,
            SeverityLevel.Error
          );
          return of(false);
        }),
        takeUntil(this.destroy$)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
