import { ActiveState, EntityState, guid, ID } from '@datorama/akita';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';

export enum CashoutStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export enum CashoutSource {
  RecentBets,
  CouponDetails,
}

export enum CashoutStatusTab {
  Available,
  Unavailable,
  Requested,
}

export interface CashoutState extends EntityState<CashoutModel>, ActiveState {}

export class CashoutModel {
  betCashout: BetCashoutModel;
  oldBetCashout: BetCashoutModel;
  betFinalState: BetFinalState;
  cashoutId: number;
  couponCode: string;
  cashoutResponse: BetCashoutResponseModel;
  cashoutInProgress: boolean = false;
  cashoutRefreshing: boolean = false;
  cashoutRequested: boolean = false;
  cashoutSource: CashoutSource;
  id: ID = guid();
  userId: string;
  timeOfRetrieval: number;

  constructor(init: Partial<CashoutModel>) {
    Object.assign(this, init);
  }

  get cashoutStatusTab(): CashoutStatusTab {
    if (
      this.betFinalState === BetFinalState.Placed &&
      !this.cashoutRequested &&
      !this.betCashout.availability &&
      this.betCashout.message &&
      this.betCashout.message.length > 0
    ) {
      return CashoutStatusTab.Unavailable;
    } else if (this.betFinalState === BetFinalState.Placed && this.cashoutRequested) {
      return CashoutStatusTab.Requested;
    } else if (this.betFinalState === BetFinalState.Placed && !this.cashoutRequested && this.betCashout.availability) {
      return CashoutStatusTab.Available;
    }
  }
}
export class BetCashoutModel {
  availability: boolean;
  allowCashout: boolean;
  result: number;
  value: number;
  valueNet: number;
  tax: number;
  message: string;
  serverData: any;

  constructor(init: Partial<BetCashoutModel>) {
    Object.assign(this, init);
  }
}

export class BetCashoutResponseModel {
  success: boolean;
  cashoutAccepted: boolean;
  userMessage: string;
  responseCode: number;
  cashoutId: number;
  couponCode: string;
  cashoutValue: number;
  currencySymbol: string;

  constructor(init: Partial<BetCashoutResponseModel>) {
    Object.assign(this, init);
  }
}

export class CashoutStatusModel {
  status: CashoutStatus;
  cashoutId: number;
  couponCode: string;
  cashoutValue: number;

  constructor(init: Partial<CashoutStatusModel>) {
    Object.assign(this, init);
  }
}

export enum CashoutResponseCodes {
  CashoutAlreadyRequested = 301,
  CashoutDisabledRunningMarketTypes = 312,
  CombinationsBet = 106,
  ContainsForbidden = 104,
  Error = 0,
  EventCashoutDisabled = 116,
  FlexiCut = 119,
  FreeBet = 118,
  HashIntegrityViolation = 309,
  InactiveEvent = 113,
  InactiveMarket = 114,
  InactiveSelection = 115,
  IsInPlay = 105,
  LiveNotAvailable = 306,
  MiscellaneousError = 300,
  NotOpened = 103,
  OddsMismatch = 302,
  OfferedCashoutMismatch = 303,
  OfferedCashoutValueDiffersFromComputed = 304,
  OutOfLeeway = 308,
  ReadOnly = 311,
  Success = 1,
  TimeOut = 307,
  WonCoupon = 117,
}
