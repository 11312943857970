import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';

@Directive({
  selector: '[appShowMore]',
})
export class ShowMoreDirective implements AfterViewInit {
  @Input() toggleSpan: ElementRef;
  @Input() itemsPerPage: number;
  private items: any[] = [];
  private currentPage: number = 1;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    this.items = Array.from(this.el.nativeElement.children);
    this.updateView();
    this.updateToggleElement();
  }

  showMore() {
    this.currentPage++;
    this.updateView();
    this.updateToggleElement();
  }

  showLess() {
    this.currentPage = 1;
    this.updateView();
    this.updateToggleElement();
    smoothScrollTo(window, 'top', this.el.nativeElement.offsetTop);
  }

  resetItems() {
    this.currentPage = 1;
    this.renderItems();
  }

  renderItems() {
    this.items = Array.from(this.el.nativeElement.children);
    this.updateView();
  }

  private updateView() {
    const totalItems = this.itemsPerPage * this.currentPage;
    this.items.forEach((item, index) => {
      this.renderer.setStyle(item, 'display', index < totalItems ? 'block' : 'none');
    });
  }

  updateToggleElement() {
    if (!this.toggleSpan) return;

    const totalItems = this.items.length;
    const displayedItems = this.itemsPerPage * this.currentPage;

    if (displayedItems >= totalItems) {
      this.toggleSpan.nativeElement.innerText = $localize`Show Less`;
      this.renderer.removeClass(this.toggleSpan.nativeElement, 'show-more');
      this.renderer.addClass(this.toggleSpan.nativeElement, 'show-less');
    } else {
      this.toggleSpan.nativeElement.innerText = $localize`Show More`;
      this.renderer.removeClass(this.toggleSpan.nativeElement, 'show-less');
      this.renderer.addClass(this.toggleSpan.nativeElement, 'show-more');
    }
  }
}
