import { Injectable } from '@angular/core';

import { ClientsideCouponService, CouponAction, CouponType, UpdateCouponRequest, UpdateFlexicutCouponRequest } from 'clientside-coupon';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';

@Injectable({
  providedIn: 'root',
})
export class ClientsideCouponWrapperService {
  constructor(
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponFlexicutService: CouponFlexicutService,
    private readonly couponQuery: CouponQuery
  ) {}

  updateCoupon = (request: UpdateCouponRequest) => {
    if (
      (request.action === CouponAction.AddOdd ||
        request.action === CouponAction.RemoveOdd ||
        request.action === CouponAction.UpdateStakeValue ||
        request.action === CouponAction.ValidateCoupon) &&
      request.coupon &&
      request.coupon.CouponType === CouponType.Flexicut
    ) {
      if (!this.couponQuery.hasFlexicutOdds) {
        //  Do first time computation
        this.couponFlexicutService.updateFlexicutOdds();
      }

      let flexicutOdds = undefined;
      if (request.action !== CouponAction.UpdateStakeValue && request.action !== CouponAction.ValidateCoupon) {
        // Recalculate Flexicut odds
        let odds = request.coupon.Odds.map(odd => ({
          id: odd.SelectionId,
          value: odd.OddValue,
        }));
        switch (request.action) {
          case CouponAction.AddOdd:
            // Append odd to list for Flexicut CSC computation
            odds = [...odds, { id: request.selection.oddId, value: request.selection.oddValue }];
            break;
          case CouponAction.RemoveOdd:
            // Remove odd from list for Flexicut CSC computation
            const index = odds.findIndex(odd => odd.id === request.selection.oddId);
            if (index !== -1) {
              odds.splice(index, 1);
            }
            break;
          default:
            break;
        }
        flexicutOdds = this.couponFlexicutService.updateFlexicutOdds(odds.map(odd => odd.value));
      } else {
        flexicutOdds = this.couponQuery.flexicutOdds;
      }
      if (flexicutOdds.length > 0) {
        // Check if cut number is still applicable
        if (request.coupon.BetDetails.FlexiCutDetails.Cut > flexicutOdds.length) {
          const maxCut = flexicutOdds.length;
          request.coupon.BetDetails.FlexiCutDetails.Cut = maxCut;
          this.couponFlexicutService.updateFlexicutSelectedOptionByCutNumber(maxCut);
        }

        return this.clientsideCouponService.updateFlexicutCoupon(
          UpdateFlexicutCouponRequest.fromUpdateCoupon(
            request,
            flexicutOdds[request.coupon.BetDetails.FlexiCutDetails.Cut - 1],
            request.coupon.BetDetails.FlexiCutDetails.Cut
          )
        );
      } else {
        return this.clientsideCouponService.updateCoupon(request);
      }
    } else {
      return this.clientsideCouponService.updateCoupon(request);
    }
  };
}
