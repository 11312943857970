import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSetShadowLeftRightTab]',
})
export class SetShadowLeftRightTabDirective implements AfterViewInit {
  private readonly el: ElementRef;

  constructor(element: ElementRef) {
    this.el = element;
  }

  ngAfterViewInit(): void {
    this.checkScroll();
  }

  @HostListener('scroll', ['$event'])
  checkScroll() {
    if (!this.el || !this.el.nativeElement) return false;

    const scrollContainer = this.el.nativeElement.parentNode;
    const scrollContent = this.el.nativeElement;

    const scrollLeft = scrollContent.scrollLeft;
    const maxScrollLeft = scrollContent.scrollWidth - scrollContent.clientWidth;

    if (scrollLeft > 0) {
      scrollContainer.classList.add('shadow-left');
    } else {
      scrollContainer.classList.remove('shadow-left');
    }

    if (scrollLeft < maxScrollLeft) {
      scrollContainer.classList.add('shadow-right');
    } else {
      scrollContainer.classList.remove('shadow-right');
    }
  }
}
