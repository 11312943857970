import { guid, ID } from '@datorama/akita';
import { BetCouponFreebetDetails, BetCouponFlexiCutDetails } from 'clientside-coupon/lib/clientside-coupon.model';
import { APIMyBetsCouponStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { JackpotType } from 'src/app/shared/models/jackpot.model';

export class RecentBetModel {
  betDetails: BetDetailsModel;
  betFinalState: BetFinalState;
  collapsed: boolean = true;
  betInfoCollapsed: boolean = false;
  couponCode: string;
  couponDate: string;
  couponType: string;
  couponTypeId: number;
  couponStatus: string;
  couponStatusId: number;
  currencySymbol: string;
  id: ID = guid();
  jackpotId: JackpotType;
  jackpotWinnings: number;
  stakeGross: number;
  totalOdds: number;
  totalCombinations: number;
  minOdd: number;
  maxOdd: number;
  won: number;
  maxWinNet: number;
  userId: string;
  selectionCount: number;
  freebetsVoucher?: BetCouponFreebetDetails;
  flexicutDetails?: BetCouponFlexiCutDetails;
  virtualsProductType?: number;

  constructor(init: Partial<RecentBetModel>) {
    Object.assign(this, init);
  }

  get isJackpotWinner(): boolean {
    return !!this.jackpotId && !!this.jackpotWinnings;
  }

  get isWinningBet(): boolean {
    const { betFinalState } = this;
    // Winning amount is not credited when a freebet is Void or Cancelled
    if (this.hasFreebetsVoucher && betFinalState === BetFinalState.Void) {
      return false;
    }
    return [BetFinalState.Won, BetFinalState.PartiallyWon, BetFinalState.Cashout, BetFinalState.Void].includes(betFinalState);
  }

  get isJackpotBets(): boolean {
    return false;
  }

  get hasFreebetsVoucher(): boolean {
    return !!this.freebetsVoucher;
  }

  get isFlexicut(): boolean {
    return !!this.flexicutDetails && this.couponTypeId === 4;
  }
}
export class JackpotBetsRecentBetModel extends RecentBetModel {
  exciseDuty: number;
  exciseDutyPercentage: number;
  incomeTax: number;
  incomeTaxPercentage: number;
  incomeTaxDisclaimer: string;

  constructor(init: Partial<JackpotBetsRecentBetModel>) {
    super(init);
    Object.assign(this, init);
  }

  get isJackpotBets(): boolean {
    return true;
  }
}
export interface BetDetailsModel {
  maxWin: number;
  maxWinNet: number;
  events: EventModel[];
  won: number;
  couponType: string;
}

export interface SportVirtualBetDetailsModel extends BetDetailsModel {
  totalOdds: number;
  totalCombinations: number;
  netStakeMinWin: number;
  netStakeMaxWin: number;
  minWin: number;
  minWithholdingTax: number;
  maxWithholdingTax: number;
  minBonus: number;
  maxBonus: number;
  minPercentageBonus: number;
  maxPercentageBonus: number;
  turnoverTax: number;
  wonTax: number;
  minOdd: number;
  maxOdd: number;
  virtualsProductType?: number;
}

export interface EventModel {
  eventId: number;
  homeTeamName: string;
  awayTeamName: string;
  eventDate: Date;
  eventStatusId: number;
  isLive?: boolean;
  fullTimeScore: string;
  result: string;
  odds: EventOddsModel[];
}

export interface SportVirtualEventModel extends EventModel {
  sportId: string;
  championship: string;
  categoryName: string;
  eventName: string;
  marketOutright: number;
  isGoalScorer: boolean;
  halfTimeScore: string;
}

export interface EventOddsModel {
  marketName: string;
  selectionName: string;
  oddStatusId: number;
  isBoosted?: boolean;
  unboostedOddValue?: number;
  oddValue?: number;
  isBanker?: boolean;
  marketTypeId?: number;
  isBetBuilder?: boolean;
}

export interface EventsPerRoundModel {
  categoryName: string;
  leagueNo: number;
  roundNumber: number;
  tournamentDate: string;
  roundDisplayName: string;
  events: EventModel[];
  isBoosted: boolean;
}

export interface VirtualsRecentBetRequestModel {
  couponStatus: APIMyBetsCouponStatus;
  dateFrom: Date;
  dateTo: Date;
  pageSize: number;
  requestedPage: number;
  includUsers: boolean;
  lang: string;
  couponCode: string;
  settlementDate: boolean;
}

export interface JackpotBetsRecentBetRequestModel {
  Status: APIMyBetsCouponStatus;
  PageSize: number;
  PageNumber: number;
}

export interface Parameter {
  ParameterName: string;
  ParameterValue: string | boolean;
}

export interface BetLiveDetailsModel {
  awayGameScore?: string;
  eventId: number;
  eventStatus: { Id: number; Description: string }; // new Match Status
  homeGameScore?: string;
  matchStatus: number; // Old Match Status for backwards compatibility
  matchTime: number;
  setScores?: string;
}

export interface MyBetsContentModel {
  noOpenBets: string;
  noSettledBets: string;
  noBookedBets: string;
  betsMovedToSettled: string;
  betNow: string;
  virtualsScheduledLeagueOpenBetsText: string;
  virtualsScheduledLeagueSettledBetsText: string;
  virtualsInstantLeagueOpenBetsText: string;
  virtualsInstantLeagueSettledBetsText: string;
}
