import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { uniq } from 'lodash-es';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable({
  providedIn: 'root',
})
export class SportsCreateSIDsResolver implements Resolve<any> {
  constructor(
    private readonly couponService: CouponService,
    private readonly couponQuery: CouponQuery,
    private readonly loggerService: LoggerService,
    private readonly location: Location
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const selectionIdsFromUrl = route.queryParams.createSIDs || route.params['createSIDs'] || undefined;
    const stakeFromUrl = route.queryParams.stake || route.params['stake'] || undefined;

    const selectionIds = selectionIdsFromUrl
      ? selectionIdsFromUrl
          .split(',')
          .filter((val: string) => val.trim().length > 0)
          .map((val: string) => parseInt(val.trim(), 10))
      : [];

    if (selectionIds.length > 0) {
      if (this.couponQuery.couponData && this.couponQuery.couponData.Odds) {
        this.couponQuery.couponData.Odds.forEach(odd => selectionIds.push(odd.SelectionId));
      }

      this.couponService.createCouponFromSelectionIds(uniq(selectionIds), stakeFromUrl).subscribe(
        () => false,
        () => {
          this.loggerService.logEvent(
            'Failed to create coupon from external Selection IDs',
            route.queryParams.createSIDs,
            SeverityLevel.Error,
            LogTypeModel.Application
          );
        },
        () => {
          if (typeof URLSearchParams !== 'undefined') {
            this.cleanURL();
          }
        }
      );
    }
  }

  private cleanURL(): void {
    setTimeout(() => {
      const url = new URL(document.location as any);
      url.searchParams.delete('createSIDs');
      if (url.searchParams.has('stake')) {
        url.searchParams.delete('stake');
      }

      this.location.replaceState(url.pathname, url.search.length > 0 ? url.search : undefined);
    }, 0);
  }
}
