import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MatchStoreState } from 'src/app/modules/sport/models/event-view/prematch.model';
import { MatchStore } from 'src/app/modules/sport/state/match-view/match.store';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { MarketModelSpreadValueGroup, CorrectScoreOddsModel } from 'src/app/shared/models/sport.model';

@Injectable({ providedIn: 'root' })
export class MatchQuery extends Query<MatchStoreState> {
  readonly marketCountForGroupAutoExpandConfig = this.appConfig.get('sports').matchView.marketCountForGroupAutoExpand;

  loading$ = this.selectLoading();
  areaTabs$ = this.select(state => state.marketCategories);
  selectedAreaTab$ = this.select(state => state.ui.selectedMarketCategory);
  match$ = this.select(store => (store.match ? store.match.categories[0].tournaments[0].matches[0] : undefined));
  matchSportId$ = this.select(store => (store.match ? store.match.id : undefined));
  matchId$ = this.select(store => (store.match ? store.match.categories[0].tournaments[0].matches[0].id : undefined));
  matchGroupingType$ = this.select(store => (store.match ? store.match.groupingType : undefined));
  boostedOdds$ = this.select(store => store.boostedOdds);
  fixtureInfo$ = this.select(store => (store.match ? store.fixtureInfo : undefined));
  dynamicBetBuilderFixtureId$ = this.select(store => (store.match ? store.dynamicBetBuilderFixtureId : undefined));
  matchMarkets$ = this.select(store => {
    const marketsGroupedBySpreadValue: MarketModelSpreadValueGroup[] = [];
    const handledMarkets: number[] = [];

    if (store.match) {
      store.match.categories[0].tournaments[0].regions[0].areas[0].markets.map(market => {
        const marketIndex = handledMarkets.indexOf(market.id);

        if (marketIndex !== -1 && market.selections.length < 4) {
          marketsGroupedBySpreadValue[marketIndex].name = marketsGroupedBySpreadValue[marketIndex].name.replace(/ *\([^)]*\) */g, '');
          marketsGroupedBySpreadValue[marketIndex].spreadValues.push({
            spreadValue: market.spreadValue,
            spreadDisplayValue: market.spreadDisplayValue,
          });
        } else {
          handledMarkets.push(market.id);
          const newGroup = new MarketModelSpreadValueGroup({
            ...market,
            spreadValue: undefined,
            spreadDisplayValue: undefined,
          });
          newGroup.spreadValues.push({
            spreadValue: market.spreadValue,
            spreadDisplayValue: market.spreadDisplayValue,
          });
          marketsGroupedBySpreadValue.push(newGroup);
        }
      });
      return marketsGroupedBySpreadValue.sort(
        (marketGroup1: MarketModelSpreadValueGroup, marketGroup2: MarketModelSpreadValueGroup) => marketGroup1.order - marketGroup2.order
      );
    } else {
      return undefined;
    }
  });

  matchBoostedMarkets$ = this.matchMarkets$.pipe(
    filter(markets => !!markets && markets.length > 0),
    map(markets =>
      markets
        .map(market =>
          market.selections.filter(selection =>
            market.spreadValues.length > 1
              ? market.spreadValues
                  .map(spread => this.getMatchOddsByMarket(market.typeId, spread.spreadValue))
                  .map(odds => odds.filter(odd => !!odd.unboostedValue).length > 0)
                  .some(isBoosted => isBoosted)
              : this.getBoostedMatchOddsByMarketAndSelection(market.typeId, selection.spreadValue, selection.name).length > 0
          ).length > 0
            ? market
            : undefined
        )
        .filter(boostedMarkets => !!boostedMarkets)
    )
  );

  constructor(protected store: MatchStore, private readonly appConfig: AppConfigService) {
    super(store);
  }

  get sportName(): string {
    return this.getValue().match.name;
  }

  get matchId(): number {
    return this.getValue().match ? this.getValue().match.categories[0].tournaments[0].matches[0].id : undefined;
  }

  getMatchOddsByMarket(marketTypeId: number, spreadValue: number): OddModel[] {
    return this.getValue().match.categories[0].tournaments[0].matches[0].odds.filter(
      (odd: OddModel) => odd.marketTypeId === marketTypeId && odd.spreadValue === spreadValue
    );
  }

  getBoostedMatchOddsByMarketAndSelection(marketTypeId: number, spreadValue: number, selectionName?: string): OddModel[] {
    return this.getValue().match.categories[0].tournaments[0].matches[0].odds.filter(
      (odd: OddModel) =>
        odd.marketTypeId === marketTypeId &&
        odd.spreadValue === spreadValue &&
        (selectionName ? odd.selectionName === selectionName : true) &&
        odd.isBoosted
    );
  }

  getMatchCorrectScoreOddsByMarket(marketTypeId: number, spreadValue: number): CorrectScoreOddsModel {
    return this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.homeToWin.findIndex(
      (csOddHTW: OddModel) => csOddHTW.marketTypeId === marketTypeId && csOddHTW.spreadValue === spreadValue
    ) > -1
      ? new CorrectScoreOddsModel({
          homeToWin: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.homeToWin.filter(
            (csOddHTW: OddModel) => csOddHTW.marketTypeId === marketTypeId && csOddHTW.spreadValue === spreadValue
          ),
          awayToWin: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.awayToWin.filter(
            (csOddATW: OddModel) => csOddATW.marketTypeId === marketTypeId && csOddATW.spreadValue === spreadValue
          ),
          draw: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.draw.filter(
            (csOddD: OddModel) => csOddD.marketTypeId === marketTypeId && csOddD.spreadValue === spreadValue
          ),
          others: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.others.filter(
            (csOddO: OddModel) => csOddO.marketTypeId === marketTypeId && csOddO.spreadValue === spreadValue
          ),
        })
      : undefined;
  }
}
