import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'coupon-expired-selection',
  templateUrl: './coupon-expired-selection.component.html',
  styleUrls: ['./coupon-expired-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponExpiredSelectionComponent implements OnInit {
  @Input() bookedCoupon: boolean = false;
  @Input() eventCategory: string;
  @Input() eventName: string;
  @Input() marketName: string;
  @Input() selectionName: string;
  @Input() betBuilderConditions: string[] = [];

  readonly hasBetBuilderConditions$ = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.hasBetBuilderConditions$.next(this.betBuilderConditions.length > 0);
  }
}
