import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { EventSummaryModel } from 'src/app/shared/models/sport.model';

@Directive({
  selector: '[appHideIfEmpty]',
})
export class HideIfEmptyDirective implements AfterViewChecked {
  @Input() appHideIfEmpty: EventSummaryModel;

  constructor(private readonly elementRef: ElementRef, private readonly sportQuery: SportQuery, private readonly renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    this.handleChange();
  }

  handleChange(): void {
    if (this.appHideIfEmpty.groupingType === 2) {
      return;
    }
    let selectedMatchFound = false;

    if (this.appHideIfEmpty) {
      this.appHideIfEmpty.matches.forEach(match => {
        if (match.selectedInView) {
          selectedMatchFound = true;
        }
      });
    }

    if (!selectedMatchFound) {
      this.renderer.addClass(this.elementRef.nativeElement, 'hidden');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'hidden');
    }
  }
}
