import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';
import { SortFilterOptionModel } from 'src/app/shared/models/filter-sort.model';

import { LandingState } from 'src/app/shared/models/landing.model';

function createInitialState(): LandingState {
  return {
    widgetsInfo: undefined,
    liveBettingEvents: undefined,
    liveBettingNewEvents: undefined,
    betBuilderEvents: [],
    mostPopularEvents: undefined,
    upcomingEvents: undefined,
    todayEvents: undefined,
    todaySports: [],
    oddsBoostEvents: undefined,
    menuItems: [],
    ui: {
      liveBettingEventsLoading: false,
      liveBettingNewEventsLoading: false,
      betBuilderEventsLoading: false,
      mostPopularEventsLoading: false,
      upcomingEventsLoading: false,
      todayEventsLoading: false,
      oddsBoostEventsLoading: false,
      menuExpanded: false,
    },
    selectedDate: undefined,
    sortType: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'landing' })
export class LandingStore extends Store<LandingState> {
  constructor() {
    super(createInitialState());
  }

  updateUI(ui: Partial<LandingState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateSelectedDate(selectedDate: SortFilterOptionModel): void {
    this.update({ selectedDate });
  }

  updateSortType(sortType: SortFilterOptionModel): void {
    this.update({ sortType });
  }
}
