import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  animations: [fadeInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent {
  @Input() show = true;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() bodyHTML = '';
  @Input() buttonText = 'Okay';
  @Input() materialIcon = '';
  @Input() icon = '';
  @Input() hasActions: boolean = true;
  @Output() readonly closeModal = new EventEmitter();

  readonly buttonType = ButtonType;
}
