import { Injectable } from '@angular/core';

import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  GoalscorerAvailableMarketsModel,
  GoalscorerModel,
  GoalscorerPlayersModel,
  GoalscorerTeamsModel,
} from 'src/app/shared/models/sport.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { SelectionDescriptionService } from 'src/app/modules/sport/services/selection-description.service';

@Injectable({
  providedIn: 'root',
})
export class GoalscorerService {
  constructor(
    private readonly appConfig: AppConfigService,
    private readonly couponService: CouponService,
    private readonly selectionDescriptionService: SelectionDescriptionService
  ) {}

  readonly mapLiteGoalscorerDataToModel = (data: any, sportId: number, sportName: string): GoalscorerModel => {
    if (!data || !data.GS) {
      return;
    }

    const showPlayersCount = this.appConfig.get('sports').goalscorerShowPlayersCount;

    const goalscorer: GoalscorerModel = {
      hasMorePlayers: false,
      hasMarketDescriptions: false,
      marketOrder: -1,
      availableMarkets: [],
      teams: [],
    };

    let marketCount = 0;
    data.GS.GT.forEach(team => {
      const objTeam: GoalscorerTeamsModel = {
        teamID: team.IT,
        teamName: team.TN,
        teamOrder: team.TO,
        players: [],
      };

      if (team.GP.length > showPlayersCount) {
        goalscorer.hasMorePlayers = true;
      }

      team.GP.forEach(player => {
        const objPlayer: GoalscorerPlayersModel = {
          playerID: player.IP,
          playerName: player.PN,
          markets: [],
        };

        player.MK.forEach(market => {
          // get the first market order for the whole goalscorer section
          if (marketCount === 0) {
            goalscorer.marketOrder = market.OT.OO;
          }
          marketCount++;

          if (!goalscorer.hasMarketDescriptions && market.OT.OD) {
            goalscorer.hasMarketDescriptions = true;
          }

          const selectionDescription = market.MO[0].OA.SD;
          const selectionDescriptionDisplay = this.selectionDescriptionService.parseSelectionDescription(data.IN, selectionDescription);

          if (!goalscorer.hasMarketDescriptions && selectionDescriptionDisplay) {
            goalscorer.hasMarketDescriptions = true;
          }

          const marketTypeId = market.OT.OI;
          const marketName = market.OT.ON;

          const selectionId = market.MO[0].OA.OI;
          const selectionName = market.MO[0].OA.ON;

          const uniqueKey = [marketTypeId, marketName].join('_');

          objPlayer.markets[uniqueKey] = new OddModel({
            id: market.MO[0].OI,
            value: market.MO[0].OT && market.MO[0].OT.OO ? market.MO[0].OT.OO : undefined,
            unboostedValue: market.MO[0].OT && market.MO[0].OT.UO ? market.MO[0].OT.UO : undefined,
            spreadValue: market.MO[0].OA.SV,
            sportId,
            sportName,
            categoryId: data.CI,
            categoryName: data.CN,
            tournamentId: data.TI,
            tournamentName: data.TN,
            matchId: data.II,
            matchName: data.IN,
            matchDate: data.ID,
            marketId: market.OI,
            marketTypeId: marketTypeId,
            marketName: marketName,
            smartCode: data.SB,
            eventCategory: data.EC,
            combinability: market.CB,
            selectionId: selectionId,
            selectionName: selectionName,
            selectionDescription: selectionDescriptionDisplay,
            incompatibleEvents: data.IE,
            selected: this.couponService.isOddInCoupon(market.MO[0].OI),
            enabled: true,
          });

          const marketAlreadyExist = goalscorer.availableMarkets.filter(item => item.marketTypeId === marketTypeId);
          if (marketAlreadyExist.length === 0) {
            const objAvailableMarket: GoalscorerAvailableMarketsModel = {
              uniqueKey: uniqueKey,
              marketTypeId: marketTypeId,
              marketName: marketName,
              marketDescription: market.OT.OD,
            };

            goalscorer.availableMarkets.push(objAvailableMarket);
          }
        });

        objTeam.players.push(objPlayer);
      });

      goalscorer.teams.push(objTeam);
    });

    return goalscorer;
  };

  mapGoalscorerDataItemsToModel(
    responseDataItem: any, // responseDataItem: 1 of AreaMatches.Items
    sportId?: number,
    sportName?: string
  ): GoalscorerModel {
    if (!responseDataItem || !responseDataItem.Goalscorer) {
      return;
    }

    const showPlayersCount = this.appConfig.get('sports').goalscorerShowPlayersCount;

    const goalscorer: GoalscorerModel = {
      hasMorePlayers: false,
      hasMarketDescriptions: false,
      marketOrder: -1,
      availableMarkets: [],
      teams: [],
    };

    responseDataItem.Goalscorer.GoalscorerTeams.forEach(team => {
      const objTeam: GoalscorerTeamsModel = {
        teamID: team.IDTeam,
        teamName: team.TeamName,
        teamOrder: team.TeamOrder,
        players: [],
      };

      if (team.GoalscorerPlayers.length > showPlayersCount) {
        goalscorer.hasMorePlayers = true;
      }

      team.GoalscorerPlayers.forEach(player => {
        const objPlayer: GoalscorerPlayersModel = {
          playerID: player.IDPlayer,
          playerName: player.PlayerName,
          markets: [],
        };

        player.Markets.forEach(market => {
          if (!goalscorer.hasMarketDescriptions && market.OddsType.OddsDescription) {
            goalscorer.hasMarketDescriptions = true;
          }

          const selectionDescription = market.MatchOdds[0].OddAttribute.SelectionDescription;
          const selectionDescriptionDisplay = this.selectionDescriptionService.parseSelectionDescription(
            responseDataItem.ItemName,
            selectionDescription
          );

          if (!goalscorer.hasMarketDescriptions && selectionDescriptionDisplay) {
            goalscorer.hasMarketDescriptions = true;
          }

          const marketTypeId = market.OddsType.OddsTypeID;
          const marketName = market.OddsType.OddsTypeName;

          const selectionId = market.MatchOdds[0].OddAttribute.OddTypeID;
          const selectionName = market.MatchOdds[0].OddAttribute.OddName;

          const uniqueKey = [marketTypeId, marketName].join('_');

          objPlayer.markets[uniqueKey] = new OddModel({
            id: market.MatchOdds[0].MatchOddsID,
            value:
              market.MatchOdds[0].Outcome && market.MatchOdds[0].Outcome.OddOutcome ? market.MatchOdds[0].Outcome.OddOutcome : undefined,
            unboostedValue:
              market.MatchOdds[0].Outcome && market.MatchOdds[0].Outcome.UnboostedOddValue
                ? market.MatchOdds[0].Outcome.UnboostedOddValue
                : undefined,
            spreadValueDisplay:
              market.MatchOdds[0].OddAttribute.SpecialValueDisplay === '0'
                ? market.SpecialBetValue
                : market.MatchOdds[0].OddAttribute.SpecialValueDisplay,
            spreadValue: market.SpecialBetValue,
            sportId,
            sportName,
            categoryId: responseDataItem.CategoryId,
            categoryName: responseDataItem.CategoryName,
            tournamentId: responseDataItem.TournamentId,
            tournamentName: responseDataItem.TournamentName,
            matchId: responseDataItem.ItemID,
            matchName: responseDataItem.ItemName,
            matchDate: responseDataItem.ItemDate,
            marketId: market.OddCollectionID,
            marketTypeId: marketTypeId,
            marketName: marketName,
            smartCode: responseDataItem.SmartBetCode,
            eventCategory: responseDataItem.EventCategory,
            combinability: market.Combinability,
            selectionId: selectionId,
            selectionName: selectionName,
            selectionDescription: selectionDescriptionDisplay,
            incompatibleEvents: responseDataItem.IncompatibleEvents,
            selected: this.couponService.isOddInCoupon(market.MatchOdds[0].MatchOddsID),
            enabled: true,
          });

          const marketAlreadyExist = goalscorer.availableMarkets.filter(item => item.marketTypeId === marketTypeId);
          if (marketAlreadyExist.length === 0) {
            const objAvailableMarket: GoalscorerAvailableMarketsModel = {
              uniqueKey: uniqueKey,
              marketTypeId: marketTypeId,
              marketName: marketName,
              marketDescription: market.OddsType.OddsDescription,
            };

            goalscorer.availableMarkets.push(objAvailableMarket);
          }
        });

        objTeam.players.push(objPlayer);
      });

      goalscorer.teams.push(objTeam);
    });

    return goalscorer;
  }
}
