<div class="acca-bonus-details-container" *ngIf="show">
  <div class="icon-and-perc" (click)="openAccBonusInfoPopup()">
    <div class="icon"></div>
    <div class="percentage" i18n>+{{ percentage | number: '1.0-0' }}%</div>
    <div class="label" i18n>&nbsp;Acca Bonus!</div>
  </div>
  <div class="amount" *ngIf="bonusAmount > 0">
    <div class="arrow"></div>
    <div class="number">{{ bonusAmount | currencyFormat }}</div>
  </div>
</div>

<app-info-modal
  [title]="cmsContent?.accumulatorBonusInfoModalTitle"
  [subtitle]="cmsContent?.accumulatorBonusInfoModalSubtitle"
  [bodyHTML]="cmsContent?.accumulatorBonusInfoModalBody | replaceAll: MIN_ACCA_ODD_VALUE_KEY : accumulatorBonusQuery.minOddsValue?.toString()"
  [buttonText]="cmsContent?.accumulatorBonusInfoModalCloseCTA"
  [show]="showInfoModal$ | async"
  (closeModal)="closeAccBonusInfoPopup()"
></app-info-modal>
