import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceAll',
})
export class ReplaceAllPipe implements PipeTransform {
  transform(value: string, key: string, replace: string): string {
    if (!replace) {
      return value;
    }

    const eKeyword = escapeRegExp(key);
    const pattern = new RegExp(eKeyword, 'gi');

    return value.replace(pattern, replace);
  }
}

function escapeRegExp(value: string): string {
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
