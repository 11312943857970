<div *ngIf="isBannerVisible" class="native-app-banner">
    <div class="native-app-container">
        <button i18n class="close-button material-icons" (click)="closeBanner('x')">{{ 'close' }}</button>
        <div class="logo-container">
            <div class="logo"></div>
            <div class="info">
                <p i18n class="name">BetKing</p>
                <p *ngIf="isAndroid()" i18n class="store-link">Get it on GooglePlay</p>
                <p *ngIf="isIOS()" i18n class="store-link">Get it on App Store</p>
            </div>
        </div>
        <a [href]="linkToApp" i18n><button (click)="closeBanner('install')" class="install-now-button">Install
                Now</button></a>
    </div>
</div>
