import { Component, Input } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { fadeIn } from 'src/app/shared/animations';
import { MatchModel, ServiceOwner } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  animations: [fadeIn()],
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent {
  @Input() event: MatchModel;
  @Input() showDate = true;
  @Input() isNewLiveWidget: boolean = false;

  serviceOwner: typeof ServiceOwner = ServiceOwner;

  constructor(private readonly appConfig: AppConfigService) {}

  teamNameCanBeSplit(name: string): boolean {
    return /\s/g.test(name);
  }

  calculateTimePercentage(matchTime: number): number {
    return (matchTime / 90) * 100;
  }

  showExtraScores(sportId: number): boolean {
    // if sport is found in the list, show extra scores
    return this.appConfig.get('sports').live.extraScoresWhiteList.findIndex(val => val === sportId) >= 0;
  }
}
