<div class="spread-dropdown">
  <div class="spread-dropdown-overlay" *ngIf="isOpen$ | async" (click)="toggleDropdown()"></div>
    <span class="spread-selector" (click)="toggleDropdown()" [class.spread-dropdown-btn]="button">
      {{ button ? selectedOption?.spreadDisplayValue : placeholder }}

      <i class="fa fa-caret-down select-icon" [ngClass]="(isOpen$ | async) ? 'fa-caret-up' : 'fa-caret-down'"></i>
    </span>
    <div class="spread-dropdown-menu" [class.show]="isOpen$ | async">
      <div *ngIf="!button" i18n class="title">Change All To:</div>
      <button *ngFor="let option of options$ | async" class="spread-dropdown-item" [class.selected]="selectedOption?.spreadValue === option?.spreadValue" (click)="selectOption(option)">
        {{ option.name }}
      </button>
    </div>
</div>
