import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { SportModel } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { SortFilterOptionModel } from 'src/app/shared/models/filter-sort.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { EventSummaryModel } from './sport.model';

export interface LandingState {
  widgetsInfo: WidgetInfoModel[];
  liveBettingEvents: EventSummaryModel;
  liveBettingNewEvents: EventSummaryModel;
  betBuilderEvents: WidgetBetBuilderModel[];
  mostPopularEvents: EventSummaryModel;
  upcomingEvents: EventSummaryModel;
  oddsBoostEvents: EventSummaryModel;
  todayEvents: EventSummaryModel;
  todaySports: SportModel[];
  menuItems: MenuItemModel[];
  ui: LandingUIState;
  selectedDate: SortFilterOptionModel;
  sortType: SortFilterOptionModel;
}

export interface LandingUIState {
  menuExpanded: boolean;
  liveBettingEventsLoading: boolean;
  liveBettingNewEventsLoading: boolean;
  betBuilderEventsLoading: boolean;
  mostPopularEventsLoading: boolean;
  upcomingEventsLoading: boolean;
  todayEventsLoading: boolean;
  oddsBoostEventsLoading: boolean;
}

export interface WidgetInfoModel {
  type: string;
  name: string;
  isGrouped: boolean;
  numberOfEvents: number;
  pageSize: number;
  refreshInterval: number;
  sortOrder: number;
  widgetSports: WidgetSportModel[];
  hasSportFilter: boolean;
  includeLiveEvents: boolean;
}

export interface WidgetSportModel {
  sportName: string;
  sportId: number;
  sortOrder: number;
  widgetMarkets: WidgetMarketModel[];
  isCustomFilter: boolean;
}

export interface WidgetMarketModel {
  marketName: string;
  marketId: number;
  sortOrder: number;
  marketEnabled: boolean;
  groupMarkets: WidgetGroupMarketModel[];
}

export interface WidgetGroupMarketModel {
  marketName: string;
  marketId: number;
  sortOrder: number;
}

export class WidgetBetBuilderModel {
  noOfSelections: number;
  odd: OddModel;

  constructor(init?: Partial<WidgetBetBuilderModel>) {
    Object.assign(this, init);
  }
}

export enum WidgetTypes {
  LiveBetting = 'liveBetting',
  LiveBettingNew = 'liveBettingNew',
  BetBuilder = 'betBuilder',
  MostPopular = 'mostPopular',
  Upcoming = 'upcomingEvents',
  Today = 'todayEvents',
  OddsBoost = 'oddsBoost',
  FootballGo = 'footballGo',
}
