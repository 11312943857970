/* eslint-disable no-console */
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

import { addDays, differenceInDays, differenceInHours, differenceInMilliseconds, format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, Observable, throwError, of as observableOf, of } from 'rxjs';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { ShopOwnerService } from 'src/app/core/services/shop-owner.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { NativeUserDetailsService } from 'src/app/modules/native-app/services/native-user-details.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import {
  AccountMenuLinkModel,
  AccountUIState,
  LoginPageContent,
  LoginResponseModel,
  TransferCandidateModel,
  UserModel,
  UserType,
  VerifyAccountModel,
  VerifyAccountType,
} from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { UserProfilesModel } from 'src/app/shared/models/bank-profile.model';
import { CurrencyModel } from 'src/app/shared/models/currency.model';
import { RegistrationModel } from 'src/app/shared/models/registration.model';
import { VERIFY_ACCOUNT_DATA_KEY } from 'src/app/shared/utils/local-storage-keys';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private isLoggingOut = false;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountStore: AccountStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly appInsightsService: AppInsightsService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly authService: AuthenticationService,
    private readonly dataLayerService: DataLayerService,
    private readonly localStorage: LocalStorageService,
    private readonly nativeUserDetailsService: NativeUserDetailsService,
    private readonly shopOwnerService: ShopOwnerService,
    private readonly virtualsStore: VirtualsStore,
    private readonly languageService: LanguageService,
    private readonly cookieService: CookieService
  ) {}

  initAccountSection(): void {
    this.getAccountSectionItems();
  }

  initLogin(): void {
    if (!this.accountQuery.hasLoginPageContent) {
      this.accountStore.queueLoading();
      this.apiService
        .get(APIType.CMS, 'Account/GetLoginPageContent')
        .pipe(
          first(),
          finalize(() => {
            this.accountStore.dequeueLoading();
          })
        )
        .subscribe((content: LoginPageContent) => {
          this.accountStore.updateLoginPageContent(content);
        });
    }
  }

  clearUserData(): void {
    this.cookieService.clearCookie('accessToken');
    this.cookieService.clearCookie('refreshToken');
    this.shopOwnerService.clearSubUsersList();
    this.accountStore.clearUserData();
    this.virtualsStore.clearGroupEntityId();
    this.virtualsStore.clearUserOnDemandData();
    this.accountStore.clearSaveBankAccInfo();
    if (this.appInsightsService.isInitialized) {
      this.appInsightsService.appInsights.clearAuthenticatedUserContext();
    }
  }

  /**
   * Converts from brand specific type code to a general one
   *
   * @param userTypeCode Type code from API
   */
  getUserType(userTypeCode: string): UserType {
    const accountSettings = this.appConfig.get('account');
    if (!accountSettings || !accountSettings.accountTypes || typeof accountSettings.accountTypes !== 'object') {
      console.error(`UserType couldn't be determined because config.account.accountTypes doesn't exist`);
      return UserType.User;
    }

    const userTypes = accountSettings.accountTypes;
    return (Object.keys(userTypes).find(key => userTypes[key] === userTypeCode) as UserType) || UserType.User;
  }

  getUserData(accessToken: string): Observable<LoginResponseModel> {
    return forkJoin([
      this.getUserProfile(accessToken),
      this.getWallets(accessToken),
      this.getUserStatus(accessToken),
      this.getLoginMessages(accessToken),
      this.getIsAgent(accessToken),
      this.isVirtualsEnabled(accessToken),
      this.isGoldenRaceEnabled(accessToken),
      this.isJackpotBetsEnabled(accessToken),
      this.getPaymentsABTestingConfig(accessToken),
    ]).pipe(
      map(
        ([
          profileData,
          wallets,
          userStatus,
          loginMessages,
          isAgent,
          isVirtualsEnabled,
          isGoldenRaceEnabled,
          isJackpotBetsEnabled,
          kingPayABTestingConfig,
          vouchers,
        ]) => {
          if (!profileData) {
            return new LoginResponseModel({ success: false, errorMessage: $localize`User data not found` });
          }

          let mobileDetails: any;
          if (profileData.PhoneContactDetails) {
            profileData.PhoneContactDetails.forEach(phoneContactDetails => {
              if (phoneContactDetails.PhoneContactTypeCode === 'MB') {
                mobileDetails = phoneContactDetails;
              }
            });
          }

          const userData = this.parseUserData(
            accessToken,
            profileData,
            wallets,
            userStatus,
            mobileDetails,
            isVirtualsEnabled,
            isGoldenRaceEnabled,
            isJackpotBetsEnabled
          );
          if (!userData) {
            return new LoginResponseModel({ success: false, errorMessage: $localize`Invalid user data` });
          }

          // data cleanse: verify account
          let verifyType: VerifyAccountType;
          if (
            this.applicationQuery.enableOTPBySMS &&
            this.appConfig.get('otp').enableMobilePhoneVerification &&
            !isAgent &&
            mobileDetails
          ) {
            switch (userData.mobile.verifyType) {
              case undefined: // No Status
              case '': // No Status
              case 'UNCF': // Unconfirmed
              case 'STRVER': // Started Verification
                verifyType = VerifyAccountType.VerifyInProgress;
                break;
              case 'VER': // Verified
                verifyType = VerifyAccountType.Verified;
                break;
              case 'LCKD': // Blocked
                verifyType = VerifyAccountType.Locked;
                break;
              default:
                // Verified
                verifyType = VerifyAccountType.Verified;
                break;
            }
          } else {
            verifyType = VerifyAccountType.Verified;
          }

          let daysLeft;
          let hoursLeft;
          let gracePeriod: boolean = false;
          const registrationConfig: any = this.appConfig.get('registration');
          if (registrationConfig && registrationConfig.deadlineDate !== '') {
            const deadlineDate = new Date(registrationConfig.deadlineDate);

            const currentTime = new Date();
            const duration = differenceInMilliseconds(deadlineDate, currentTime);

            daysLeft = differenceInDays(deadlineDate, currentTime);
            hoursLeft = differenceInHours(deadlineDate, addDays(currentTime, daysLeft));

            if (duration <= 0 && verifyType !== VerifyAccountType.Verified) {
              verifyType = VerifyAccountType.Locked;
              userData.mobile.verifyType = 'LCKD';
              gracePeriod = true;
            }
          }

          this.accountStore.updateUserData(userData);

          const verifyAccountState = new VerifyAccountModel({
            type: verifyType,
            gracePeriod: gracePeriod,
            daysLeft: daysLeft,
            hoursLeft: hoursLeft,
            mobileNumber: userData.mobile.mobileNumber,
          });

          this.accountStore.updateVerifyAccountState(verifyAccountState);
          const kingPayExtraDetail = profileData.ExtraDetails.find(
            item => item.PropertyName === 'KingPayABTestingIsEnabled'
          )?.PropertyValue;
          if (kingPayABTestingConfig) this.setPaymentsABTestingConfig(kingPayABTestingConfig, userData.id.toString(), kingPayExtraDetail);
          this.getBankProfile().subscribe();

          if (this.appInsightsService.isInitialized) {
            this.appInsightsService.appInsights.setAuthenticatedUserContext(userData.id.toString(), undefined, true);
          }
          return new LoginResponseModel({ success: true, loginMessages: loginMessages });
        }
      ),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  getWinNotificationSetting(): Observable<void> {
    return this.apiService.get(APIType.Platform, 'api/GamingVendors/3BGSportsbook/GetWinNotificationSetting').pipe(
      map(responseData => {
        this.accountStore.updateWinNotificationSetting(responseData.Result.IsEnabled);
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  setWinNotification(value: boolean): Observable<void> {
    return this.apiService.post(
      APIType.Platform,
      'api/GamingVendors/3BGSportsbook/SetWinNotificationSetting',
      {
        Enable: value,
      },
      new APISettings({
        forceAuthToken: this.accountQuery.accessToken,
      })
    );
  }

  updateShowUnverifiedTooltip(showUnverifiedTooltip: boolean): void {
    this.accountStore.updateShowUnverifiedTooltip(showUnverifiedTooltip);
  }

  updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers: boolean): void {
    this.accountStore.updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers);
  }

  logout(): Observable<boolean> {
    if (!this.isLoggingOut) {
      const userId = this.accountQuery.userData ? this.accountQuery.userData.id.toString() : undefined;
      this.isLoggingOut = true;
      return this.authService.revokeToken(this.accountQuery.accessToken).pipe(
        map(() => {
          // remove user from local storage
          this.clearUserData();
          return true;
        }),
        tap(() => {
          window?.MobileNative?.setUserId('');
          window?.webkit?.messageHandlers?.MobileNative?.postMessage({ userId: '' });
          this.nativeUserDetailsService.removeUser();
          this.dataLayerService.createDataLayerEvent({
            event: 'user-logout',
            userID: userId,
          });
        }),
        finalize(() => {
          this.isLoggingOut = false;
        }),
        catchError(err => {
          this.nativeUserDetailsService.removeUser();
          return throwError(err);
        })
      );
    } else {
      return observableOf(false);
    }
  }

  getWallets(accessToken: string): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    if (accessToken) {
      apiSettings.forceAuthToken = accessToken;
    }

    return this.apiService.get<any>(APIType.Platform, 'api/Finance/Accounts/ByPlaySource/web', apiSettings).pipe(
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return undefined;
        }

        return responseData.Result.map(wallet => ({
          id: wallet.Id,
          name: wallet.Name,
          balance: wallet.Balance,
        }));
      })
    );
  }

  getGeneralBankBanksList(): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    return this.apiService.get(APIType.Platform, 'api/Finance/BankInformation/BankList', apiSettings).pipe(
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return undefined;
        } else {
          this.accountStore.updateGeneralBanksList(responseData.Result);
          return responseData;
        }
      })
    );
  }

  getInterswitchBankBanksList(): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    return this.apiService.get(APIType.Platform, 'api/PaymentVendors/Interswitch/GetBankList', apiSettings);
  }

  getZenithBankBanksList(): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    return this.apiService.get(APIType.Platform, 'api/PaymentVendors/Zenith/GetBankList', apiSettings);
  }

  getOnlyBankProfiles() {
    return this.apiService.get(APIType.Platform, 'api/Finance/BankTransferDetails').pipe(
      map(response => {
        return response;
      })
    );
  }

  getBankProfile(checkAllowedBankProfiles = true): Observable<boolean> {
    if (this.accountQuery.isAuthenticated) {
      const bankProfileBlackList = this.appConfig.get('account').bankProfileBlackList || [];
      const isAllowedBankAccInfo = bankProfileBlackList.includes(this.accountQuery.userData.userTypeCode) ? false : true;

      this.accountStore.updateIsAllowedBankAccInfo(isAllowedBankAccInfo);

      if (isAllowedBankAccInfo) {
        return this.apiService.get(APIType.Platform, 'api/Finance/BankTransferDetails').pipe(
          map(responseData => {
            if (!responseData || !responseData.Result) {
              this.parseBankProfiles([]);
              return false;
            } else {
              this.parseBankProfiles(responseData.Result, checkAllowedBankProfiles);
              return true;
            }
          })
        );
      }
    }

    return observableOf(false);
  }

  parseBankProfiles(profileList: any, checkAllowedBankProfiles = true): any {
    let userState: string = 'profileEmpty';
    let sbadIndicatorState: boolean = true;
    let enabledProfiles: number = 0;
    let sortedProfiles: any[] = [];
    const profiles: any[] = [];
    profileList.forEach(profile => {
      if (profile.Verification.VerificationStatusID === 3) {
        // rejected
        const expDate = new Date(profile.Verification.StatusChangedDate);
        expDate.setHours(expDate.getHours() + 48);
        const currDate = new Date();
        if (currDate < expDate) {
          profile.class = 'fa fa-exclamation-triangle icon yellow';
          profile.imageRef = '&#xf058;';
          profile.state = 'profileRejected';
          profiles.push(this.mapUserProfileToBankModel(profile));
        } else {
          return;
        }
      } else if (profile.Enabled) {
        if (profile.Verification.VerificationStatusID === 2 || profile.Verification.VerificationStatusID === 4) {
          // verified
          enabledProfiles++;
          profile.class = 'fa fa-check-circle icon';
          profile.imageRef = '&#xf058;';
          profile.state = 'profileVerified';
          if (userState === 'profileEmpty' || userState === 'profileNotVerified') {
            userState = 'profileVerified';
          }
          sbadIndicatorState = false;
          profiles.push(this.mapUserProfileToBankModel(profile));
        } else if (profile.Verification.VerificationStatusID === 1) {
          // pending
          enabledProfiles++;
          profile.class = 'fa fa-clock-o icon';
          profile.imageRef = '&#xf017;';
          profile.state = 'profileNotVerified';
          if (userState === 'profileEmpty') {
            userState = 'profileNotVerified';
          }
          sbadIndicatorState = false;
          profiles.push(this.mapUserProfileToBankModel(profile));
        }
      } else {
        return;
      }
    });

    checkAllowedBankProfiles && this.checkAllowedBankProfiles().subscribe();
    this.accountStore.updateAmountOfProfilesEnabled(enabledProfiles);
    sortedProfiles = profiles;
    this.accountStore.updateBankProfilesAndState(sortedProfiles, userState);
    this.accountStore.updateSbadIndicatorState(sbadIndicatorState);
  }

  createBankProfile(model: any): Observable<any> {
    return this.apiService.post(APIType.Platform, 'api/Finance/BankTransferDetails', model);
  }

  checkBankProfileStatus(id: number): Observable<any> {
    return this.apiService.get(APIType.Platform, `api/Finance/BankTransferDetails/${id}`);
  }

  checkAllowedBankProfiles(): Observable<void> {
    return this.apiService.get(APIType.Platform, 'api/Finance/BankTransferDetails/MaxNumberOfSavedAccounts').pipe(
      first(),
      map(responseData => {
        this.accountStore.updateAmountOfProfilesAllowed(responseData.Result);
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  swapBankProfile(model: any): Observable<any> {
    return this.apiService.post(APIType.Platform, 'api/Finance/BankTransferDetails/UpdateDefaultBankTransferDetails', model);
  }

  updateBalance(): void {
    this.updateRefreshingBalance(true);

    const apiCalls = [this.updateBalanceSubscription()];
    // CE-327: If brand is KEN and userStatus is UNCONF, refresh the userStatus as well
    if (this.appConfig.get('brandId') === 1903 && this.accountQuery.userStatus === 'UNCONF') {
      apiCalls.push(this.getUserStatus());
    }

    forkJoin(apiCalls)
      .pipe(
        finalize(() => {
          this.updateRefreshingBalance(false);
        })
      )
      .subscribe();
  }

  updateBalanceSubscription(): Observable<void> {
    return this.getWallets(this.accountQuery.accessToken).pipe(
      map(wallets => {
        this.accountStore.updateStoredWallets(this.accountQuery.userData, wallets);
      })
    );
  }

  getChangePhoneNumberOption(): Observable<void> {
    if (!this.accountQuery.hasChangePhoneNumberOption) {
      return this.apiService.get(APIType.Platform, 'api/Security/Accounts/RequestChangePhoneNumber/Current').pipe(
        first(),
        map(responseData => {
          this.accountStore.updateChangePhoneNumberOption(responseData.Result);
          this.accountStore.updatePhoneOptionTriggered(true);
        }),
        catchError((err: HttpErrorResponse) => {
          this.accountStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  updatePhoneOptionTriggered(phoneOptionTriggered: boolean): void {
    this.accountStore.updatePhoneOptionTriggered(phoneOptionTriggered);
  }

  validateOneTimePassword(username: string, code: string, OTPOption: number): Observable<boolean> {
    const body = {
      Username: username,
      OneTimePassword: code,
      VerifyOTPOption: OTPOption,
    };
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/ValidateOneTimePassword', body, apiSettings);
  }

  startVerificationBySMS(userId: number, mobileNumber: string): Observable<boolean> {
    const body = {
      UserId: userId,
      Mobile: mobileNumber,
    };
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/Request/StartVerificationBySMS', body, apiSettings);
  }

  regenerateTokenForService(body: any): Observable<boolean> {
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/RegenerateTokenForService', body, apiSettings);
  }

  regenerateTokenForCallOTP(body: any): Observable<boolean> {
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post<any>(APIType.Website, 'api/user-registration/Request/OTP/Voice', body, apiSettings);
  }

  updateUI(ui: AccountUIState): void {
    this.accountStore.updateUI(ui);
  }

  generatePasswordValidators(): ValidatorFn[] {
    return [Validators.required, Validators.maxLength(15), Validators.minLength(6), this.hasSpaces()];
  }

  allAreSpaces(control: FormControl): Validators {
    if (control?.value?.length > 0 && control.value?.trim() === '') {
      return { allAreSpaces: true };
    }
    return null;
  }
  generateEmailValidators(): ValidatorFn[] {
    return [Validators.required, Validators.pattern(new RegExp(/^\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}\s*$/))];
  }

  editProfile(user: RegistrationModel): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    this.accountStore.queueLoading();
    const apiCalls = [];
    const winNotificationSetting = this.appConfig.get('account').enableWinNotificationSetting;
    if (winNotificationSetting && user.winNotificationSetting !== undefined) {
      apiCalls.push(this.setWinNotification(user.winNotificationSetting));
    }
    // because we are  to checking otherthan win notification any field is empty so deleting if property exist in user object.
    delete user.winNotificationSetting;

    const isEmpty = !Object.values(user).some(x => x); // checking fields are empty.

    if (!isEmpty) {
      const callBody = {
        UserDetails: {
          BirthDate: format(new Date(user.dateOfBirth), 'yyyy-MM-dd'),
          Name: user.firstName,
          Surname: user.lastName,
          GenderCode: user.gender,
          Currency: user.currency,
          Username: this.accountQuery.userData.username,
          Email: user.email,
          CountryCode: user.country,
          LanguageCode: 'eng',
        },
        UserAddressDetails: [
          {
            Id: this.accountQuery.userData.address.id,
            CountryCode: user.country,
            Region: user.state,
            Province: '',
            City: user.city,
            Line1: user.address,
            PostalCode: 'N/A',
            AddressTypeCode: 'MAIN',
          },
        ],
        PhoneContactDetails: [
          {
            Id: this.accountQuery.userData.mobile.id,
            PhoneContactTypeCode: 'MB',
            ContactNumber: this.accountQuery.userData.mobile.mobileNumber,
          },
        ],
        ExtraDetails: [
          {
            Id: this.accountQuery.userData.marketingConsent.id,
            PropertyName: 'ReceiveMarketingMessages',
            PropertyValue: `${user.extraDetails.receiveMarketingMessages}`,
          },
        ],
        SecurityDetails: {
          SecurityQuestionCode: 'QST1',
          SecurityAnswer: 'Not Provided',
        },
      };

      apiCalls.push(this.apiService.post<any>(APIType.Platform, 'api/User/Profile', callBody, apiSettings));
    }

    return forkJoin(apiCalls).pipe(
      map(() => {
        this.accountStore.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        this.accountStore.dequeueLoading();
        return throwError(err.message);
      })
    );
  }

  editPhoneNumber(user: RegistrationModel): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken,
    });

    const mobileNumber: string = `${user.mobilePrefix}${user.mobileNumber}`;

    this.accountStore.queueLoading();

    const callBody = {
      UserDetails: {
        BirthDate: format(new Date(this.accountQuery.userData.dateOfBirth), 'yyyy-MM-dd'),
        Name: this.accountQuery.userData.name,
        Surname: this.accountQuery.userData.surname,
        GenderCode: this.accountQuery.userData.gender,
        Currency: this.accountQuery.userData.currency.name,
        Username: this.accountQuery.userData.username,
        Email: this.accountQuery.userData.email,
        CountryCode: this.accountQuery.userData.address.countryCode,
        LanguageCode: 'eng',
      },
      UserAddressDetails: [
        {
          Id: this.accountQuery.userData.address.id,
          CountryCode: this.accountQuery.userData.address.countryCode,
          Region: this.accountQuery.userData.address.state,
          Province: '',
          City: this.accountQuery.userData.address.cityCode,
          Line1: this.accountQuery.userData.address.addressLine,
          PostalCode: 'N/A',
          AddressTypeCode: 'MAIN',
        },
      ],
      PhoneContactDetails: [
        {
          Id: this.accountQuery.userData.mobile.id,
          PhoneContactTypeCode: 'MB',
          ContactNumber: mobileNumber,
        },
      ],
      ExtraDetails: [
        {
          Id: this.accountQuery.userData.marketingConsent.id,
          PropertyName: 'ReceiveMarketingMessages',
          PropertyValue: `${this.accountQuery.userData.marketingConsent.value}`,
        },
      ],
      SecurityDetails: {
        SecurityQuestionCode: 'QST1',
        SecurityAnswer: 'Not Provided',
      },
    };

    return this.apiService.post(APIType.Platform, 'api/User/Profile', callBody, apiSettings).pipe(
      first(),
      map(() => {
        this.accountStore.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        this.accountStore.dequeueLoading();
        return throwError(err.message);
      })
    );
  }

  clearError(): void {
    this.accountStore.setError(undefined);
  }

  updateTransferCandidates(transferCandidates: any): void {
    this.accountStore.updateTransferCandidates(transferCandidates.map(tc => this.mapTransferCandidatesResponseToModel(tc)));
  }

  getTransferCandidates(statusFilter: string): Observable<void> {
    return this.apiService
      .get<any>(
        APIType.Platform,
        `api/Finance/Accounts/Agents/TransferCandidates?UserStatusesFilterBy=${statusFilter}&DirectDescendantsOnly=true&Recursive=false&AgentsOnly=false`
      )
      .pipe(
        first(),
        map(m => {
          this.updateTransferCandidates(m.Result);
        })
      );
  }

  transferFunds(type: 'D' | 'W', userId: number, amount: number): Observable<boolean> {
    return this.apiService
      .post<any>(APIType.Platform, `api/Finance/Accounts/Agents/Transfer${type === 'D' ? 'To' : 'From'}`, {
        UserId: userId,
        Amount: amount,
      })
      .pipe(
        first(),
        map(m => m.Result && m.SystemMessage === 'Success')
      );
  }

  getPlyOnUserStatus(mobileNumber: string): Observable<any> {
    return this.apiService.get(APIType.UserService, `api/user/v1/account/status/${mobileNumber}`);
  }
  plyOnLogin(payload: { username: string; password: string }): Observable<any> {
    const body = new HttpParams().set('username', payload.username).set('password', payload.password).toString();
    const apiSettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
    });
    return this.apiService.post(APIType.UserService, `api/user/v1/account/login`, body, apiSettings);
  }

  isVirtualsEnabled(accessToken: string): Observable<boolean> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get(APIType.Platform, 'api/GamingVendors/BetagyVirtuals/IsEnabled', apiSettings).pipe(
      catchError(err => observableOf(false)),
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return false;
        }
        return responseData.Result;
      })
    );
  }

  isGoldenRaceEnabled(accessToken: string): Observable<boolean> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get(APIType.Platform, 'api/GamingVendors/GoldenRace/IsEnabled', apiSettings).pipe(
      catchError(err => observableOf(false)),
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return false;
        }
        return responseData.Result;
      })
    );
  }

  isJackpotBetsEnabled(accessToken: string): Observable<boolean> {
    const jackpotBetsConfig = this.appConfig.get('jackpotBets');
    if (jackpotBetsConfig && !jackpotBetsConfig.userWhiteListEnabled) {
      return observableOf(true);
    }

    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get(APIType.JackpotBets, 'api/v1/user/status', apiSettings).pipe(
      catchError(err => observableOf(false)),
      map(responseData => {
        if (!responseData) {
          return false;
        }
        return responseData.enabled;
      })
    );
  }

  refreshEnabledGameProviders(): Observable<void> {
    if (!this.accountQuery.userData?.accessToken) {
      return of(undefined);
    }

    const accessToken = this.accountQuery.userData.accessToken;
    return forkJoin([this.isVirtualsEnabled(accessToken), this.isGoldenRaceEnabled(accessToken)]).pipe(
      map(([isVirtualsEnabled, isGoldenRaceEnabled]) => {
        this.accountStore.updateUserData({
          ...this.accountQuery.userData,
          isVirtualsEnabled: isVirtualsEnabled,
          isGoldenRaceEnabled: isGoldenRaceEnabled,
        });
      })
    );
  }

  verifyMobileNumber(mobileNumber: string): Observable<[boolean, number]> {
    return this.apiService
      .post<any>(
        APIType.Platform,
        'api/User/Profile/VerifyMobileNumber',
        {
          mobileNumber,
        },
        new APISettings({
          forceAuthToken: this.accountQuery.accessToken,
        })
      )
      .pipe(map(response => (response.ResponseCode === 0 ? [true, response.ResponseCode] : [false, response.ResponseCode])));
  }

  updatePhoneVerificationStatus(status: string): void {
    this.accountStore.updatePhoneVerifyType(status);
  }

  updateUserStatus(userStatus: string): void {
    this.accountStore.updateUserStatus(userStatus);
  }

  isExistingUsername(username: string): Observable<boolean> {
    this.accountStore.queueLoading();
    return this.apiService.get(APIType.Website, `api/User/Profile/Agents/IsExistingUsername?UsernameToCheck=${username}`).pipe(
      first(),
      finalize(() => {
        this.accountStore.dequeueLoading();
      })
    );
  }

  private mapTransferCandidatesResponseToModel(transferCandidate: any): TransferCandidateModel {
    return new TransferCandidateModel({
      userId: transferCandidate.UserId,
      username: transferCandidate.Username,
      name: transferCandidate.Name,
      surname: transferCandidate.Surname,
      balance: transferCandidate.Balance,
    });
  }

  private hasSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value.replace(/ /g, '') !== control.value) {
        return { hasSpaces: true };
      }
      return undefined;
    };
  }

  private updateRefreshingBalance(refreshingBalance: boolean): void {
    this.accountStore.updateUI({ refreshingBalance });
  }

  private getUserProfile(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Profile', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  private getPaymentsABTestingConfig(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    return this.apiService
      .get(APIType.CMS, `Account/GetPaymentsABTestingConfig?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
      .pipe(
        catchError(err => observableOf(false)),
        map(responseData => {
          if (responseData === undefined) {
            return undefined;
          }
          return responseData;
        })
      );
  }

  private setPaymentsABTestingConfig(kingPayABTestingConfig: any, id?: any, extraDetailInfo?: string): void {
    let abKingPayCookieValue = 'old';
    if (!extraDetailInfo) {
      const { kingPayABTestingIsEnable, kingPayABTestingApplyId, kingPayABTestingApplyForAll } = kingPayABTestingConfig;
      if (kingPayABTestingIsEnable) {
        abKingPayCookieValue =
          kingPayABTestingApplyForAll || kingPayABTestingApplyId?.split(',').includes(id) ? 'new' : abKingPayCookieValue;
        this.cookieService.setCookie('ab-kingpay-cookie', abKingPayCookieValue);
      }
    } else {
      abKingPayCookieValue = extraDetailInfo;
    }
    this.cookieService.setCookie('ab-kingpay-cookie', abKingPayCookieValue);
  }

  private getUserStatus(accessToken?: string): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    if (accessToken) {
      apiSettings.forceAuthToken = accessToken;
    }

    return this.apiService.get<any>(APIType.Platform, 'api/Security/Accounts/Status', apiSettings).pipe(
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return undefined;
        }

        if (this.accountQuery.isAuthenticated) {
          this.updateUserStatus(responseData.Result.Code);
        }

        return responseData.Result;
      })
    );
  }

  private getLoginMessages(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Messages/LoginMessages', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  private getIsAgent(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken,
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Profile/Agents/IsAgent', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  private getAccountSectionItems(): void {
    const mobilePhoneEditability: boolean = this.appConfig.get('account').mobilePhoneEditability;
    const verifyData: any = this.localStorage.retrieve(VERIFY_ACCOUNT_DATA_KEY);
    const verifyAccountType: typeof VerifyAccountType = VerifyAccountType;

    this.apiService
      .get(APIType.CMS, '/Account/GetAccountSettingsLinks')
      .pipe(first())
      .subscribe(
        data => {
          if (data !== undefined && data.length > 0) {
            this.accountStore.updateAccountMenuItems(data.map(item => this.mapCMSLinkToAccountMenuLinkModel(item)));
          }
        },
        () => {
          if (this.accountQuery.menuItems.length > 0) {
            const menuItemsCopy: AccountMenuLinkModel[] = cloneDeep(this.accountQuery.menuItems);

            menuItemsCopy.forEach(item => {
              if (item.link === '/account/change-phone-number') {
                item.isVisible = mobilePhoneEditability && verifyData && verifyData.type !== verifyAccountType.Verified;
              }
            });

            this.accountStore.updateAccountMenuItems(menuItemsCopy);
          }
        }
      );

    this.apiService
      .get(APIType.CMS, '/Account/GetAccountHelpLinks')
      .pipe(first())
      .subscribe(data => {
        if (data !== undefined && data.length > 0) {
          this.accountStore.updateAccounteHelpMenuItems(data.map(item => this.mapCMSLinkToAccountMenuLinkModel(item)));
        }
      });
  }

  private mapCMSLinkToAccountMenuLinkModel(cmsLink: any): AccountMenuLinkModel {
    return new AccountMenuLinkModel({
      text: cmsLink.title,
      link: cmsLink.linkURL,
      iconFontValue: cmsLink.icon,
      visibleToTheseUserTypes: cmsLink.visibleToTheseUserTypes ? cmsLink.visibleToTheseUserTypes : [],
      isCustomIcon: !cmsLink.isFontAwesomeIcon,
    });
  }

  private parseUserData(
    accessToken: string,
    profileData: any,
    wallets: any,
    userStatus: any,
    mobileDetails: any,
    isVirtualsEnabled: boolean,
    isGoldenRaceEnabled: boolean,
    isJackpotBetsEnabled: boolean
  ): UserModel {
    try {
      const receiveMarketingMessages: any = profileData.ExtraDetails.find(item => item.PropertyName === 'ReceiveMarketingMessages');
      const sportsConfig: any = this.appConfig.get('sports');
      let defaultCurrency: string = '';
      if (sportsConfig && sportsConfig.coupon && sportsConfig.coupon.defaultCurrency) {
        defaultCurrency = sportsConfig.coupon.defaultCurrency;
      }

      return new UserModel({
        id: profileData.UserDetails.UserId,
        parentId: profileData.ParentDetails ? profileData.ParentDetails.ParentId : undefined,
        name: profileData.UserDetails.Name,
        surname: profileData.UserDetails.Surname,
        username: profileData.UserDetails.Username,
        email: profileData.UserDetails.Email,
        userTypeCode: this.getUserType(profileData.UserDetails.UserTypeCode),
        userStatus: userStatus.Code,
        accessToken: accessToken,
        isVirtualsEnabled: isVirtualsEnabled,
        isGoldenRaceEnabled: isGoldenRaceEnabled,
        isJackpotBetsEnabled: isJackpotBetsEnabled !== undefined ? isJackpotBetsEnabled : true,
        loginDate: new Date(),
        wallets: wallets,
        currency: new CurrencyModel({
          // defaultCountry should never be empty, but we still need to supply a value or an exception would be thrown
          code: profileData.UserDetails.CountryCode || defaultCurrency,
          name: profileData.UserDetails.Currency,
          symbol: profileData.UserDetails.CurrencySymbol,
        }),
        mobile: {
          id: mobileDetails.Id,
          mobileNumber: mobileDetails.ContactNumber,
          verifyType: mobileDetails.PhoneContactVerificationStatus,
          lastStatusChangeDate: mobileDetails.LastStatusChangeDate,
        },
        dateOfBirth: new Date(profileData.UserDetails.BirthDate),
        gender: profileData.UserDetails.GenderCode,
        marketingConsent: {
          id: receiveMarketingMessages !== undefined ? receiveMarketingMessages.Id : undefined,
          value: receiveMarketingMessages !== undefined ? receiveMarketingMessages.PropertyValue.toLowerCase() === 'true' : undefined,
        },
        address: {
          id: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Id : undefined,
          countryCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].CountryCode : undefined,
          state: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Region : undefined,
          cityCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].City : undefined,
          addressLine:
            profileData.UserAddressDetails.length > 0
              ? profileData.UserAddressDetails[0].Line2
                ? `${profileData.UserAddressDetails[0].Line1} ${profileData.UserAddressDetails[0].Line2}`
                : profileData.UserAddressDetails[0].Line1
              : undefined,
        },
      });
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private mapUserProfileToBankModel(profile: any): UserProfilesModel {
    return new UserProfilesModel({
      id: profile.Id,
      bankCode: profile.BankCode,
      accountNumber: profile.AccountNumber,
      accountHolderName: profile.AccountHolderName,
      sortCode: profile.SortCode,
      swiftCode: profile.SwiftCode,
      routingNumber: profile.RoutingNumber,
      iBANCode: profile.IBANCode,
      default: profile.Default,
      profileEnabled: profile.Enabled,
      createdOn: profile.CreatedOn,
      updatedOn: profile.UpdatedOn,
      verificationStatusID: profile.Verification.VerificationStatusID,
      verificationStatusCode: profile.Verification.VerificationStatusCode,
      declineReason: profile.Verification.DeclineReason,
      statusChangedDate: profile.Verification.StatusChangedDate,
      statusChangedBy: profile.Verification.StatusChangedBy,
      imageRef: profile.imageRef,
      class: profile.class,
      state: profile.state,
    });
  }
}
