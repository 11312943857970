import { Component, Input, OnInit } from '@angular/core';

import { slowFadeInOut } from 'src/app/shared/animations';
import { Router } from '@angular/router';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Component({
  selector: 'virtuals-jackpot-banner-item',
  templateUrl: './virtuals-jackpot-banner-item.component.html',
  styleUrls: ['./virtuals-jackpot-banner-item.component.scss'],
  animations: [slowFadeInOut()],
})
export class VirtualsJackpotBannerItemComponent implements OnInit {
  @Input() jackpotDropInfo: string;
  @Input() jackpotInfo: string;
  @Input() jackpotAmount: number;
  @Input() jackpotClass: string;
  @Input() jackpotLogoUrl: string;
  @Input() latestJackpotUrl: string;
  @Input() jackpotDropAmount: number;
  @Input() jackpotNextDrop: string;
  @Input() calculateByDropAmount: boolean;
  @Input() inGameBanner: boolean = false;
  progressBarFill: number = 0;
  constructor(public currencyService: CurrencyService, public applicationQuery: ApplicationQuery, private readonly router: Router) {}

  ngOnInit(): void {
    this.progressBarFill = Math.min(
      this.calculateByDropAmount ? this.calculateProgressByDropAmount() : this.calculateProgressByDropDate(),
      100
    );
  }

  navigateToLatestJackpots(): void {
    this.router.navigateByUrl(this.latestJackpotUrl);
  }

  private calculateProgressByDropDate(): number {
    if (this.jackpotNextDrop) {
      const currentDate = new Date();
      const nextDropDate = new Date(this.jackpotNextDrop);
      const timeDifference = nextDropDate.getTime() - currentDate.getTime();
      const secondsDifference = timeDifference / 1000;
      const timeLeft = (secondsDifference / 3604) * 100;

      return 100 - timeLeft;
    }

    return 0;
  }

  private calculateProgressByDropAmount(): number {
    if (this.jackpotAmount > 100000 && this.jackpotDropAmount) {
      return (this.jackpotAmount / this.jackpotDropAmount) * 100;
    }

    return 0;
  }
}
