<ng-container *ngIf="{
  coupon: instantCouponQuery.couponData$ | async,
  selectedGroupingsTab: instantCouponQuery.groupingsTabSelected$ | async,
  authenticated: accountQuery.isAuthenticated$ | async,
  filteredCoupon: instantCouponQuery.couponDataFiltered$ | async,
  canUserBetOnVirtuals: accountQuery.isVirtualsEnabled$ | async,
  canPostCoupon: canPostCoupon$ | async,
  showAnimation: showAnimation$ | async,
  inSlideUp: virtualsQuery.showingCouponSlideUp$ | async,
  minTotalStake: instantCouponQuery.minTotalStake$ | async,
  maxTotalStake: instantCouponQuery.maxTotalStake$ | async,
  minTotalStakeErrorMessage: minTotalStakeErrorMessage$ | async,
  maxTotalStakeErrorMessage: maxTotalStakeErrorMessage$ | async
} as vm">
  <ng-container *ngIf="!vm.showAnimation && vm.coupon && vm.coupon.Odds.length > 0; else emptyCoupon">
    <div class="coupon-header" *ngIf="vm.inSlideUp === false" data-testid="coupon-header">
      <div class="coupon-title">
        <span data-testid="coupon-header-title" i18n>Virtuals Instaleague</span>
        <span class="coupon-selections-count" data-testid="coupon-header-selections-count">{{ vm.coupon.Odds.length
          }}</span>
      </div>
      <span class="coupon-close material-icons" data-testid="coupon-close-icon" (click)="goBack()">{{'close'}}</span>
    </div>
    <div class="top-panel" [class.no-tabs]="vm.coupon.CouponType === couponType.Single" appResizeOnScroll
      [minHeight]="80" [resizeEnabled]="vm.inSlideUp === false" [siblingHeight]="56">
      <div class="clear-all" (click)="clearCouponData()" data-testid="coupon-header-clear-selections">
        <span class="icon material-icons">{{'delete icon'}}</span>
        <span class="text" i18n>Clear All</span>
      </div>

      <div class="groupings-tab-control" *ngIf="vm.coupon.CouponType !== couponType.Single" data-testid="groupings-tab">
        <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
        <div class="groupings-tab" *ngIf="groupingTabsVisible.multiple"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Multiple" data-testid="groupings-tab-multiple"
          (click)="groupingTabClicked(groupingType.Multiple)" i18n>Multiple</div>
        <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
        <div class="groupings-tab" *ngIf="groupingTabsVisible.split"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Split" data-testid="groupings-tab-split-bet"
          (click)="groupingTabClicked(groupingType.Split)" i18n>Split Bet</div>
        <div class="groupings-tab" *ngIf="groupingTabsVisible.singles"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Singles" data-testid="groupings-tab-singles"
          (click)="groupingTabClicked(groupingType.Singles)" i18n>Singles</div>
        <div class="groupings-tab" *ngIf="groupingTabsVisible.combination && allowCombinationBets"
          [class.selected]="vm.selectedGroupingsTab === groupingType.Combination"
          data-testid="groupings-tab-combination-bets" (click)="groupingTabClicked(groupingType.Combination)" i18n>
          Combination
        </div>
      </div>
    </div>

    <div #couponWrapper class="coupon-wrapper" cdkScrollable>
      <div class="main-content" [ngClass]="{authenticated: vm.authenticated}" data-testid="coupon">
        <app-virtuals-instant-coupon-selections data-testid="coupon-selections"
          [selectedGroupingTab]="vm.selectedGroupingsTab">
        </app-virtuals-instant-coupon-selections>

        <app-virtuals-instant-coupon-groupings *ngIf="vm.coupon.CouponType !== couponType.Single"
          data-testid="coupon-groupings" [hidden]="vm.selectedGroupingsTab === groupingType.Multiple"
          [groups]="vm.coupon.AllGroupings">
        </app-virtuals-instant-coupon-groupings>

        <app-virtuals-instant-coupon-totals data-testid="coupon-totals" [couponData]="vm.filteredCoupon"
          [inSlideUp]="vm.inSlideUp">
        </app-virtuals-instant-coupon-totals>

        <div *ngIf="vm.coupon.StakeGross < vm.minTotalStake" class="stake-alert">
          <virtuals-alert [type]="virtualsAlertType.Error" [message]="vm.minTotalStakeErrorMessage" i18n-ctaText
            ctaText="Update" (ctaClick)="updateStake(vm.minTotalStake)"></virtuals-alert>
        </div>

        <div *ngIf="vm.coupon.StakeGross > vm.maxTotalStake" class="stake-alert">
          <virtuals-alert [type]="virtualsAlertType.Error" [message]="vm.maxTotalStakeErrorMessage" i18n-ctaText
            ctaText="Update" (ctaClick)="updateStake(vm.maxTotalStake)"></virtuals-alert>
        </div>

        <div class="buttons">
          <app-button i18n-text text="Place Bet" data-testid="coupon-place-bet-btn"
            [customButtonStyle]="actionButtonStyle" [buttonType]="buttonType.HighlightSecondary"
            [disabled]="!vm.canUserBetOnVirtuals || !vm.canPostCoupon || vm.coupon.StakeGross < vm.minTotalStake || vm.coupon.StakeGross > vm.maxTotalStake"
            (btnClick)="postCoupon()">
          </app-button>

          <app-virtuals-instant-coupon-checkbox
            data-testid="coupon-proceed-to-results"></app-virtuals-instant-coupon-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyCoupon>
  <div class="coupon-empty-wrapper"
    *ngIf="(virtualsQuery.showingCouponSlideUp$ | async) === false && (showAnimation$ | async) === false"
    data-testid="coupon-empty">
    <div class="coupon-header" data-testid="coupon-header">
      <div class="coupon-title">
        <span data-testid="coupon-header-title" i18n>Virtuals Instaleague</span>
      </div>
      <span class="coupon-close material-icons" data-testid="coupon-close-icon" (click)="goBack()">{{ 'close' }}</span>
    </div>
    <div class="coupon-empty">
      <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
      <div class="coupon-empty-content" i18n>Close Betslip and click on the odds to add your selections.</div>
    </div>
    <app-button i18n-text text="Continue Betting" (click)="continueBetting()" [customButtonStyle]="actionButtonStyle"
      [buttonType]="buttonType.Highlight" data-testid="coupon-continue-betting"></app-button>
  </div>
</ng-template>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" data-testid="coupon-bet-in-progress" i18n>
    Your bet is being processed
  </div>
</ng-template>

<ng-container *ngIf="showAnimation$ | async">
  <div class="success-animation" data-testid="coupon-bet-processing-animation">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>
</ng-container>
