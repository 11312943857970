import { LiveMatchStatuses, OldLiveMatchStatuses } from 'src/app/shared/models/live.model';

export const DEFAULT_LIVE_MATCH_STATUSES: LiveMatchStatuses = {
  '1': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '2': [
    {
      statusId: 1,
      statusLabel: '1st period',
    },
    {
      statusId: 2,
      statusLabel: '2nd period',
    },
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 13,
      statusLabel: '1st quarter',
    },
    {
      statusId: 14,
      statusLabel: '2nd quarter',
    },
    {
      statusId: 15,
      statusLabel: '3rd quarter',
    },
    {
      statusId: 16,
      statusLabel: '4th quarter',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 40,
      statusLabel: 'Overtime',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '3': [
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 401,
      statusLabel: '1st inning top',
    },
    {
      statusId: 402,
      statusLabel: '1st inning bottom',
    },
    {
      statusId: 403,
      statusLabel: '2nd inning top',
    },
    {
      statusId: 404,
      statusLabel: '2nd inning bottom',
    },
    {
      statusId: 405,
      statusLabel: '3rd inning top',
    },
    {
      statusId: 406,
      statusLabel: '3rd inning bottom',
    },
    {
      statusId: 407,
      statusLabel: '4th inning top',
    },
    {
      statusId: 408,
      statusLabel: '4th inning bottom',
    },
    {
      statusId: 409,
      statusLabel: '5th inning top',
    },
    {
      statusId: 410,
      statusLabel: '5th inning bottom',
    },
    {
      statusId: 411,
      statusLabel: '6th inning top',
    },
    {
      statusId: 412,
      statusLabel: '6th inning bottom',
    },
    {
      statusId: 413,
      statusLabel: '7th inning top',
    },
    {
      statusId: 414,
      statusLabel: '7th inning bottom',
    },
    {
      statusId: 415,
      statusLabel: '8th inning top',
    },
    {
      statusId: 416,
      statusLabel: '8th inning bottom',
    },
    {
      statusId: 417,
      statusLabel: '9th inning top',
    },
    {
      statusId: 418,
      statusLabel: '9th inning bottom',
    },
    {
      statusId: 419,
      statusLabel: 'Extra inning top',
    },
    {
      statusId: 420,
      statusLabel: 'Extra inning bottom',
    },
    {
      statusId: 421,
      statusLabel: 'Break top 1 bottom 1',
    },
    {
      statusId: 422,
      statusLabel: 'Break top 2 bottom 1',
    },
    {
      statusId: 423,
      statusLabel: 'Break top 2 bottom 2',
    },
    {
      statusId: 424,
      statusLabel: 'Break top 3 bottom 2',
    },
    {
      statusId: 425,
      statusLabel: 'Break top 3 bottom 3',
    },
    {
      statusId: 426,
      statusLabel: 'Break top 4 bottom 3',
    },
    {
      statusId: 427,
      statusLabel: 'Break top 4 bottom 4',
    },
    {
      statusId: 428,
      statusLabel: 'Break top 5 bottom 4',
    },
    {
      statusId: 429,
      statusLabel: 'Break top 5 bottom 5',
    },
    {
      statusId: 430,
      statusLabel: 'Break top 6 bottom 5',
    },
    {
      statusId: 431,
      statusLabel: 'Break top 6 bottom 6',
    },
    {
      statusId: 432,
      statusLabel: 'Break top 7 bottom 6',
    },
    {
      statusId: 433,
      statusLabel: 'Break top 7 bottom 7',
    },
    {
      statusId: 434,
      statusLabel: 'Break top 8 bottom 7',
    },
    {
      statusId: 435,
      statusLabel: 'Break top 8 bottom 8',
    },
    {
      statusId: 436,
      statusLabel: 'Break top 9 bottom 8',
    },
    {
      statusId: 437,
      statusLabel: 'Break top 9 bottom 9',
    },
    {
      statusId: 438,
      statusLabel: 'Break top EI bottom 9',
    },
    {
      statusId: 439,
      statusLabel: 'Break top EI bottom EI',
    },
    {
      statusId: 548,
      statusLabel: 'Break top EI bottom 7',
    },
  ],
  '4': [
    {
      statusId: 1,
      statusLabel: '1st period',
    },
    {
      statusId: 2,
      statusLabel: '2nd period',
    },
    {
      statusId: 3,
      statusLabel: '3rd period',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 40,
      statusLabel: 'Overtime',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
  ],
  '5': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 92,
      statusLabel: 'Retired',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
  ],
  '6': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '12': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 60,
      statusLabel: 'Postponed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 440,
      statusLabel: 'Sudden death',
    },
    {
      statusId: 443,
      statusLabel: 'Awaiting sudden death',
    },
    {
      statusId: 444,
      statusLabel: 'After sudden death',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '13': [
    {
      statusId: 13,
      statusLabel: '1st quarter',
    },
    {
      statusId: 14,
      statusLabel: '2nd quarter',
    },
    {
      statusId: 15,
      statusLabel: '3rd quarter',
    },
    {
      statusId: 16,
      statusLabel: '4th quarter',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 440,
      statusLabel: 'Sudden death',
    },
    {
      statusId: 443,
      statusLabel: 'Awaiting sudden death',
    },
    {
      statusId: 444,
      statusLabel: 'After sudden death',
    },
  ],
  '16': [
    {
      statusId: 13,
      statusLabel: '1st quarter',
    },
    {
      statusId: 14,
      statusLabel: '2nd quarter',
    },
    {
      statusId: 15,
      statusLabel: '3rd quarter',
    },
    {
      statusId: 16,
      statusLabel: '4th quarter',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 40,
      statusLabel: 'Overtime',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
  ],
  '19': [
    {
      statusId: 21,
      statusLabel: 'In progress',
    },
    {
      statusId: 30,
      statusLabel: 'Break',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 445,
      statusLabel: 'Break',
    },
  ],
  '20': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
    {
      statusId: 305,
      statusLabel: 'Fifth break',
    },
    {
      statusId: 306,
      statusLabel: 'Sixth break',
    },
    {
      statusId: 441,
      statusLabel: '6th set',
    },
    {
      statusId: 442,
      statusLabel: '7th set',
    },
  ],
  '21': [
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 501,
      statusLabel: 'First innings    home team',
    },
    {
      statusId: 502,
      statusLabel: 'First innings    away team',
    },
    {
      statusId: 503,
      statusLabel: 'Second innings    home team',
    },
    {
      statusId: 504,
      statusLabel: 'Second innings    away team',
    },
    {
      statusId: 505,
      statusLabel: 'Awaiting super over',
    },
    {
      statusId: 506,
      statusLabel: 'Super over    home team',
    },
    {
      statusId: 507,
      statusLabel: 'Super over    away team',
    },
    {
      statusId: 508,
      statusLabel: 'After super over',
    },
    {
      statusId: 509,
      statusLabel: 'Innings break',
    },
    {
      statusId: 510,
      statusLabel: 'Super over break',
    },
    {
      statusId: 511,
      statusLabel: 'Lunch break',
    },
    {
      statusId: 512,
      statusLabel: 'Tea break',
    },
    {
      statusId: 513,
      statusLabel: 'Stumps',
    },
  ],
  '22': [
    {
      statusId: 21,
      statusLabel: 'In progress',
    },
    {
      statusId: 30,
      statusLabel: 'Break',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
  ],
  '23': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 17,
      statusLabel: 'Golden set',
    },
    {
      statusId: 37,
      statusLabel: 'Awaiting golden set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 130,
      statusLabel: 'After golden set',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
    {
      statusId: 305,
      statusLabel: 'Fifth break',
    },
    {
      statusId: 306,
      statusLabel: 'Sixth break',
    },
    {
      statusId: 441,
      statusLabel: '6th set',
    },
    {
      statusId: 442,
      statusLabel: '7th set',
    },
  ],
  '24': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 13,
      statusLabel: '1st quarter',
    },
    {
      statusId: 14,
      statusLabel: '2nd quarter',
    },
    {
      statusId: 15,
      statusLabel: '3rd quarter',
    },
    {
      statusId: 16,
      statusLabel: '4th quarter',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 60,
      statusLabel: 'Postponed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 440,
      statusLabel: 'Sudden death',
    },
    {
      statusId: 443,
      statusLabel: 'Awaiting sudden death',
    },
    {
      statusId: 444,
      statusLabel: 'After sudden death',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '29': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '31': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
  ],
  '32': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
  ],
  '34': [
    {
      statusId: 8,
      statusLabel: '1st set',
    },
    {
      statusId: 9,
      statusLabel: '2nd set',
    },
    {
      statusId: 10,
      statusLabel: '3rd set',
    },
    {
      statusId: 11,
      statusLabel: '4th set',
    },
    {
      statusId: 12,
      statusLabel: '5th set',
    },
    {
      statusId: 17,
      statusLabel: 'Golden set',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
  ],
  '37': [
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 97,
      statusLabel: 'Player 1 defaulted    player 2 won',
    },
    {
      statusId: 98,
      statusLabel: 'Player 2 defaulted    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 151,
      statusLabel: '1st Game',
    },
    {
      statusId: 152,
      statusLabel: '2nd Game',
    },
    {
      statusId: 153,
      statusLabel: '3rd Game',
    },
    {
      statusId: 154,
      statusLabel: '4th Game',
    },
    {
      statusId: 155,
      statusLabel: '5th Game',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
  ],
  '109': [
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 141,
      statusLabel: '1st map',
    },
    {
      statusId: 142,
      statusLabel: '2nd map',
    },
    {
      statusId: 143,
      statusLabel: '3rd map',
    },
    {
      statusId: 144,
      statusLabel: '4th map',
    },
    {
      statusId: 145,
      statusLabel: '5th map',
    },
    {
      statusId: 146,
      statusLabel: '6th map',
    },
    {
      statusId: 147,
      statusLabel: '7th map',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
    {
      statusId: 305,
      statusLabel: 'Fifth break',
    },
    {
      statusId: 306,
      statusLabel: 'Sixth break',
    },
  ],
  '110': [
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 141,
      statusLabel: '1st map',
    },
    {
      statusId: 142,
      statusLabel: '2nd map',
    },
    {
      statusId: 143,
      statusLabel: '3rd map',
    },
    {
      statusId: 144,
      statusLabel: '4th map',
    },
    {
      statusId: 145,
      statusLabel: '5th map',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
  ],
  '111': [
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 93,
      statusLabel: 'Walkover    player 1 won',
    },
    {
      statusId: 94,
      statusLabel: 'Walkover    player 2 won',
    },
    {
      statusId: 95,
      statusLabel: 'Player 1 retired    player 2 won',
    },
    {
      statusId: 96,
      statusLabel: 'Player 2 retired    player 1 won',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 141,
      statusLabel: '1st map',
    },
    {
      statusId: 142,
      statusLabel: '2nd map',
    },
    {
      statusId: 143,
      statusLabel: '3rd map',
    },
    {
      statusId: 144,
      statusLabel: '4th map',
    },
    {
      statusId: 145,
      statusLabel: '5th map',
    },
    {
      statusId: 146,
      statusLabel: '6th map',
    },
    {
      statusId: 147,
      statusLabel: '7th map',
    },
    {
      statusId: 301,
      statusLabel: 'First break',
    },
    {
      statusId: 302,
      statusLabel: 'Second break',
    },
    {
      statusId: 303,
      statusLabel: 'Third break',
    },
    {
      statusId: 304,
      statusLabel: 'Fourth break',
    },
    {
      statusId: 305,
      statusLabel: 'Fifth break',
    },
    {
      statusId: 306,
      statusLabel: 'Sixth break',
    },
  ],
  '138': [
    {
      statusId: 6,
      statusLabel: '1st half',
    },
    {
      statusId: 7,
      statusLabel: '2nd half',
    },
    {
      statusId: 18,
      statusLabel: 'Golden raid',
    },
    {
      statusId: 19,
      statusLabel: 'Coin toss',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 33,
      statusLabel: 'Extra time halftime',
    },
    {
      statusId: 38,
      statusLabel: 'Awaiting golden raid',
    },
    {
      statusId: 41,
      statusLabel: '1st extra',
    },
    {
      statusId: 42,
      statusLabel: '2nd extra',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
    {
      statusId: 140,
      statusLabel: 'After golden raid',
    },
    {
      statusId: 123,
      statusLabel: 'Half Time',
    },
    {
      statusId: 124,
      statusLabel: '1st Half',
    },
    {
      statusId: 125,
      statusLabel: '2nd Half',
    },
  ],
  '155': [
    {
      statusId: 21,
      statusLabel: 'In progress',
    },
    {
      statusId: 32,
      statusLabel: 'Awaiting extra time',
    },
    {
      statusId: 40,
      statusLabel: 'Overtime',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 110,
      statusLabel: 'AET',
    },
  ],
  '157': [
    {
      statusId: 1,
      statusLabel: '1st period',
    },
    {
      statusId: 2,
      statusLabel: '2nd period',
    },
    {
      statusId: 31,
      statusLabel: 'Halftime',
    },
    {
      statusId: 34,
      statusLabel: 'Awaiting penalties',
    },
    {
      statusId: 50,
      statusLabel: 'Penalties',
    },
    {
      statusId: 61,
      statusLabel: 'Start delayed',
    },
    {
      statusId: 80,
      statusLabel: 'Interrupted',
    },
    {
      statusId: 90,
      statusLabel: 'Abandoned',
    },
    {
      statusId: 100,
      statusLabel: 'Ended',
    },
    {
      statusId: 120,
      statusLabel: 'AP',
    },
  ],
};

export const DEFAULT_OLD_LIVE_MATCH_STATUSES: OldLiveMatchStatuses = {
  0: 'Not Set',
  1: 'Not started',
  2: 'Started',
  3: 'First period',
  4: 'Second period',
  5: 'Third period',
  6: 'Fourth period',
  7: 'Fifth period',
  8: 'First set',
  9: 'Second set',
  10: 'Third set',
  11: 'Fourth set',
  12: 'Fifth set',
  13: 'Sixth set',
  14: 'Seventh set',
  15: 'First quarter',
  16: 'Second quarter',
  17: 'Third quarter',
  18: 'Fourth quarter',
  19: 'Match pause',
  20: 'First pause',
  21: 'Second pause',
  22: 'Third pause',
  23: 'Fourth pause',
  24: 'Fifth pause',
  25: 'Sixth pause',
  26: 'Overtime',
  27: 'Awaiting overtime',
  28: 'First half overtime',
  29: 'Overtime half time',
  30: 'Second half overtime',
  31: 'After overtime',
  32: 'Awaiting penalities',
  33: 'Penalty shootout',
  34: 'After penalities',
  35: 'Ended',
  36: 'Postponed',
  37: 'Delayed',
  38: 'Cancelled',
  39: 'Cancelled',
  40: 'Interrupted',
  41: 'Abandoned',
  42: 'Walkover',
  43: 'Walkover 1',
  44: 'Walkover 2',
  45: 'Retired',
  46: 'Retired 1',
  47: 'Retired 2',
  48: 'Top of 1st inning',
  49: 'Bottom of 1st inning',
  50: 'Top of 2nd inning',
  51: 'Bottom of 2nd inning',
  52: 'Top of 3rd inning',
  53: 'Bottom of 3rd inning',
  54: 'Top of 4th inning',
  55: 'Bottom of 4th inning',
  56: 'Top of 5th inning',
  57: 'Bottom of 5th inning',
  58: 'Top of 6th inning',
  59: 'Bottom of 6th inning',
  60: 'Top of 7th inning',
  61: 'Bottom of 7th inning',
  62: 'Top of 8th inning',
  63: 'Bottom of 8th inning',
  64: 'Top of 9th inning',
  65: 'Bottom of 9th inning',
  66: 'Top of Extra inning',
  67: 'Bottom of Extra inning',
  68: 'Break Top of 1st - Bottom of 1st',
  69: 'Break Top of 2nd - Bottom of 1st',
  70: 'Break Top of 2nd - Bottom of 2nd',
  71: 'Break Top of 3rd - Bottom of 2nd',
  72: 'Break Top of 3rd - Bottom of 3rd',
  73: 'Break Top of 4th - Bottom of 3rd',
  74: 'Break Top of 4th - Bottom of 4th',
  75: 'Break Top of 5th - Bottom of 4th',
  76: 'Break Top of 5th - Bottom of 5th',
  77: 'Break Top of 6th - Bottom of 5th',
  78: 'Break Top of 6th - Bottom of 6th',
  79: 'Break Top of 7th - Bottom of 6th',
  80: 'Break Top of 7th - Bottom of 7th',
  81: 'Break Top of 8th - Bottom of 7th',
  82: 'Break Top of 8th - Bottom of 8th',
  83: 'Break Top of 9th - Bottom of 8th',
  84: 'Break Top of 9th - Bottom of 9th',
  85: 'Break Top of EI - Bottom of 9th',
  86: 'Break Top of EI - Bottom of EI',
  87: 'Golden Set',
  88: 'Sudden Death',
  89: 'After Sudden Death',
  90: 'In Progress',
  91: 'Session Break',
  92: 'Defaulted 1',
  93: 'Defaulted 2',
  94: '1st innings, home team',
  95: '1st innings, away team',
  96: '2nd innings, home team',
  97: '2nd innings, away team',
  98: 'Awaiting super over',
  99: 'Super over, home team',
  100: 'Super over, away team',
  101: 'After super over',
  102: 'Innings break',
  103: 'Super over break',
  104: 'Lunch break',
  105: 'Tea break',
  106: 'Stumps',
  107: 'Injury Break',
  108: 'Awaiting Sudden Death',
  109: 'First Map',
  110: 'Second Map',
  111: 'Third Map',
  112: 'Fourth Map',
  113: 'Fifth Map',
  114: 'Sixth Map',
  115: 'Seventh Map',
  116: 'Awaiting Golden Set',
  117: 'After Golden Set',
  118: 'First Game',
  119: 'Second Game',
  120: 'Third Game',
  121: 'Fourth Game',
  122: 'Fifth Game',
  123: 'Half Time',
  124: 'First Half',
  125: 'Second Half',
};

export const DEFAULT_OLD_LIVE_MATCH_STATUSES_ABBR: OldLiveMatchStatuses = {
  0: 'Not Set',
  1: 'Not started',
  2: 'Started',
  3: '1st period',
  4: '2nd period',
  5: '3rd period',
  6: '4th period',
  7: '5th period',
  8: '1st Set',
  9: '2nd Set',
  10: '3rd Set',
  11: '4th Set',
  12: '5th Set',
  13: '6th Set',
  14: '7th Set',
  15: '1st',
  16: '2nd',
  17: '3rd',
  18: '4th',
  19: 'Paused',
  20: '1st pause',
  21: '2nd pause',
  22: '3rd pause',
  23: '4th pause',
  24: '5th pause',
  25: '6th pause',
  26: 'Overtime',
  27: 'Overtime',
  28: '1st half overtime',
  29: 'Overtime half time',
  30: '2nd half overtime',
  31: 'Overtime',
  32: 'Pens',
  33: 'Pens',
  34: 'Pens',
  35: 'Ended',
  36: 'Postponed',
  37: 'Delayed',
  38: 'Cancelled',
  39: 'Cancelled',
  40: 'Interrupted',
  41: 'Abandoned',
  42: 'Walkover',
  43: 'Walkover 1',
  44: 'Walkover 2',
  45: 'Retired',
  46: 'Retired',
  47: 'Retired',
  48: 'Top of 1st inning',
  49: 'Bottom of 1st inning',
  50: 'Top of 2nd inning',
  51: 'Bottom of 2nd inning',
  52: 'Top of 3rd inning',
  53: 'Bottom of 3rd inning',
  54: 'Top of 4th inning',
  55: 'Bottom of 4th inning',
  56: 'Top of 5th inning',
  57: 'Bottom of 5th inning',
  58: 'Top of 6th inning',
  59: 'Bottom of 6th inning',
  60: 'Top of 7th inning',
  61: 'Bottom of 7th inning',
  62: 'Top of 8th inning',
  63: 'Bottom of 8th inning',
  64: 'Top of 9th inning',
  65: 'Bottom of 9th inning',
  66: 'Top of Extra inning',
  67: 'Bottom of Extra inning',
  68: 'Break Top of 1st - Bottom of 1st',
  69: 'Break Top of 2nd - Bottom of 1st',
  70: 'Break Top of 2nd - Bottom of 2nd',
  71: 'Break Top of 3rd - Bottom of 2nd',
  72: 'Break Top of 3rd - Bottom of 3rd',
  73: 'Break Top of 4th - Bottom of 3rd',
  74: 'Break Top of 4th - Bottom of 4th',
  75: 'Break Top of 5th - Bottom of 4th',
  76: 'Break Top of 5th - Bottom of 5th',
  77: 'Break Top of 6th - Bottom of 5th',
  78: 'Break Top of 6th - Bottom of 6th',
  79: 'Break Top of 7th - Bottom of 6th',
  80: 'Break Top of 7th - Bottom of 7th',
  81: 'Break Top of 8th - Bottom of 7th',
  82: 'Break Top of 8th - Bottom of 8th',
  83: 'Break Top of 9th - Bottom of 8th',
  84: 'Break Top of 9th - Bottom of 9th',
  85: 'Break Top of EI - Bottom of 9th',
  86: 'Break Top of EI - Bottom of EI',
  87: 'Golden Set',
  88: 'Sudden Death',
  89: 'After Sudden Death',
  90: 'In Progress',
  91: 'Session Break',
  92: 'Defaulted 1',
  93: 'Defaulted 2',
  94: '1st innings, home team',
  95: '1st innings, away team',
  96: '2nd innings, home team',
  97: '2nd innings, away team',
  98: 'Awaiting super over',
  99: 'Super over, home team',
  100: 'Super over, away team',
  101: 'After super over',
  102: 'Innings break',
  103: 'Super over break',
  104: 'Lunch break',
  105: 'Tea break',
  106: 'Stumps',
  107: 'Injury Break',
  108: 'Awaiting Sudden Death',
  109: '1st Map',
  110: '2nd Map',
  111: '3rd Map',
  112: '4th Map',
  113: '5th Map',
  114: '6th Map',
  115: '7th Map',
  116: 'Awaiting Golden Set',
  117: 'After Golden Set',
  118: '1st Game',
  119: '2nd Game',
  120: '3rd Game',
  121: '4th Game',
  122: '5th Game',
  123: 'HT',
  124: '1st',
  125: '2nd',
};
