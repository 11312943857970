import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { VirtualsInfoSubSection } from 'src/app/shared/models/virtuals.model';

@Component({
  selector: 'virtuals-info-sub-section',
  templateUrl: './virtuals-info-sub-section.component.html',
  styleUrls: ['./virtuals-info-sub-section.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None, // required in order to be able to style <a> elements in content
})
export class VirtualsInfoSubSectionComponent implements OnDestroy {
  @Input() subSection: VirtualsInfoSubSection;
  @Input() isExpanded: boolean = false;
  @Output() readonly headerClick: EventEmitter<void> = new EventEmitter();

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  headerClicked() {
    this.headerClick.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
