import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { StatisticsState } from 'src/app/shared/models/statistics.model';
import { StatisticsStore } from './statistics.store';

@Injectable({ providedIn: 'root' })
export class StatisticsQuery extends Query<StatisticsState> {
  matchStatisticsData$ = this.select(s => s.matchStatisticsData);
  headToHeadData$ = this.select(s => s.headToHeadData);
  winProbabilityData$ = this.select(s => s.winProbabilityData);
  lastMatchesData$ = this.select(s => s.lastMatchesData);
  nextMatchesData$ = this.select(s => s.nextMatchesData);
  overUnderData$ = this.select(s => s.overUnderData);
  goalscorerData$ = this.select(s => s.goalscorerData);
  assistsData$ = this.select(s => s.assistsData);
  cardsData$ = this.select(s => s.cardsData);
  leagueRankingData$ = this.select(s => s.leagueRankingData);
  prematchListStatisticsTabs$ = this.select(store => store.preMatchListStatisticsTabs);
  prematchEventStatisticsTabs$ = this.select(store => store.preMatchEventStatisticsTabs);
  liveListStatisticsTabs$ = this.select(store => store.liveListStatisticsTabs);
  liveEventStatisticsTabs$ = this.select(store => store.liveEventStatisticsTabs);
  enableCPPLiveWidget$ = this.select(store => store.enableCPPPreMatchWidget);
  enableCPPPreMatchWidget$ = this.select(store => store.enableCPPLiveWidget);
  selectedEventId$ = this.select(s => s.ui.selectedEventId);
  expandedStatistics$ = this.select(s => s.ui.expandedStatistics);

  constructor(protected store: StatisticsStore) {
    super(store);
  }

  get selectedEventId(): number {
    return this.getValue().ui.selectedEventId;
  }

  get expandedStatistics(): boolean {
    return this.getValue().ui.expandedStatistics;
  }

  get enableCPPLiveWidget(): boolean {
    return this.getValue().enableCPPLiveWidget;
  }

  get enableCPPPreMatchWidget(): boolean {
    return this.getValue().enableCPPPreMatchWidget;
  }
}
