import { Injectable } from '@angular/core';
import {
  BetCoupon,
  BetCouponGroup,
  ClientsideCouponService,
  CouponAction,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';

@Injectable({
  providedIn: 'root',
})
export class VirtualsCouponStakeHandlerService {
  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfig: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly virtualsCouponStore: VirtualsCouponStore
  ) {}

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      return this.updateCoupon({
        action: CouponAction.UpdateStakeValue,
        couponData: this.virtualsCouponQuery.couponData,
        requestData: { stakeValue },
      });
    }
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.UpdateGroupingStakeValue,
      couponData: this.virtualsCouponQuery.couponData,
      requestData: {
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      },
    });
  }

  // Centralized function for calling the CSC updateCoupon function, to reduce code repetition.
  // This is copied from virtuals-coupon.service.ts because injecting that service here
  // creates a circular DI error.
  private updateCoupon({
    action,
    couponData,
    requestData,
    saveResponse = true,
  }: {
    action: CouponAction;
    couponData: BetCoupon;
    requestData?: Partial<UpdateCouponRequest>;
    saveResponse?: boolean;
  }): UpdateCouponResponse {
    const request = new UpdateCouponRequest({
      action,
      coupon: couponData,
      brandID: this.appConfig.get('brandId'),
      bonusList: this.accumulatorBonusQuery.bonusList,
      globalVariables: this.virtualsCouponQuery.globalVariables,
      marketExceptions: undefined,
      correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
      ...requestData,
    });
    const response = this.clientsideCouponService.updateCoupon(request);

    if (saveResponse) {
      this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    }
    return response;
  }
}
