import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MatchMarketCategoryTab, MatchStoreState } from 'src/app/modules/sport/models/event-view/prematch.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { SportModel } from 'src/app/shared/models/sport.model';

const createInitialState = (): MatchStoreState => ({
  marketCategories: [],
  match: undefined,
  marketCategoryData: [],
  boostedOdds: [],
  fixtureInfo: undefined,
  dynamicBetBuilderFixtureId: undefined,
  ui: {
    selectedMarketCategory: undefined,
  },
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'match-view' })
export class MatchStore extends Store<MatchStoreState> {
  constructor() {
    super(createInitialState());
  }

  updateMarketCategories = (areaTabs: MatchMarketCategoryTab[]) => {
    this.update({
      marketCategories: areaTabs,
    });
  };

  updateSelectedMarketCategoryTab = (selectedAreaTab: MatchMarketCategoryTab) => {
    this.update(state => ({
      ui: {
        ...state.ui,
        selectedMarketCategory: selectedAreaTab,
      },
    }));
  };

  updateMatch(match: SportModel): void {
    this.update({ match });
  }

  updateMatchBoostedOdds(odds: OddModel[]): void {
    this.update({
      boostedOdds: odds,
    });
  }
}
