import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { SportsBreadcrumbService } from 'src/app/modules/sport/services/sports-breadcrumb.service';
import { SportBreadcrumb } from 'src/app/shared/models/sport.model';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sports-breadcrumb-item',
  templateUrl: './sports-breadcrumb-item.component.html',
  styleUrls: ['./sports-breadcrumb-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SportsBreadcrumbItemComponent implements AfterViewInit {
  @Input() breadcrumb: SportBreadcrumb;
  @Input() isLastCrumb: boolean;
  @Input() openedDropdownItem: string;
  @Input() breadcrumbNavigationStructure: BreadcrumbNavigation;
  @ViewChild('scrollableContainer') scrollableContainer: ElementRef;
  isMainParentActive: boolean = false;

  constructor(
    readonly sportsBreadcrumbService: SportsBreadcrumbService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly router: Router
  ) {}

  ngAfterViewInit() {
    // Scroll to be aligned on the right position
    const scrollableContainer = this.scrollableContainer?.nativeElement;
    scrollableContainer && scrollableContainer.scroll(scrollableContainer.clientWidth, 0);
  }

  onScroll() {
    this.sportsBreadcrumbService.closeDropdown();
  }

  onDropdownSelect(selected): void {
    this.sportsBreadcrumbService.closeDropdown();
    this.router.navigateByUrl(selected.url);
  }

  goBack(): void {
    this.location.back();
  }

  breadcrumbClick(): void {
    this.breadcrumb.id !== this.openedDropdownItem
      ? this.sportsBreadcrumbService.openDropdownForActiveItem(this.breadcrumb)
      : this.sportsBreadcrumbService.closeDropdown();
  }

  clickedOverlay(): void {
    this.sportsBreadcrumbService.closeDropdown();
  }

  goToPage(item: BreadcrumbNavigation): void {
    this.sportsBreadcrumbService.closeDropdown();
    /*
      Small workaround to make sure we refresh components when navigating route that leads to the same component as before
      It is either this or spend a sprint trying to figure out how/why league data is being loaded on component initialisation
      and figuring out the process of refactoring it.
     */
    this.router
      .navigateByUrl(`/sports/${this.activatedRoute.snapshot.params.sportName}/${this.activatedRoute.snapshot.params.eventType}`, {
        skipLocationChange: true,
      })
      .then(() => this.router.navigateByUrl(item.url));
  }
}
