import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { differenceInMilliseconds } from 'date-fns';
import { BehaviorSubject, interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { first, map, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-pie-countdown',
  templateUrl: './pie-countdown.component.html',
  styleUrls: ['./pie-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieCountdownComponent implements OnChanges, OnDestroy {
  /** Date value (in string format) used to determine the length of the countdown as well as the pie's initial fill percentage */
  @Input() targetDate: string;
  /** Value in ms of how long the pie should take to go from 100% to 0%. Will default to the time difference till targetDate value */
  @Input() pieFullDuration: number = 0;
  /** Value in either #hex, rgb() or rgba() which will determine the countdown pie color */
  @Input() color: string = 'rgba(255, 255, 255, 0.56)';
  /** Value in px which will determine the countdown pie size */
  @Input() pieSize: number = 8;
  /** Value in px which will determine the size of the border around the countdown pie */
  @Input() borderThickness: number = 1;
  /** Value in px which will determine the spacing between the countdown pie and its border */
  @Input() borderSpacing: number = 1;
  /** Determine whether to show the countdown time next to the pie countdown */
  @Input() showCountdownText: boolean = false;
  /** Value in either #hex, rgb() or rgba() which will determine the font color of the countdown */
  @Input() textColor: string = '#fff';
  /** Value in px which will determine the font size of the countdown time text */
  @Input() textSize: number = 11;
  /** Value in px which will determine the line height of the countdown time text */
  @Input() textLineHeight: number;
  /** Determine whether to show the background */
  @Input() showBackground: boolean = false;
  /** Value in either #hex, rgb() or rgba() which will determine the color of the container box when countdown text is visible */
  @Input() backgroundColor: string = 'rgba(255, 255, 255, 0.16)';
  /** Determine whether to keep showing the component when the remaining time hits zero */
  @Input() showWhenZero: boolean = true;
  /** Event emitter which will fire when the countdown value reaches 0 */
  @Output() readonly timeElapsed: EventEmitter<void> = new EventEmitter<void>();
  /** Value in px which will determine the width of the div that holds the 'LIVE' text and the pulsating circle */
  @Input() liveBadgeWidth: number = 42.5;
  /** Value in px which will determine the diameter of the outer ring of the pulsating circle */
  @Input() outerRingSize: number = 11;
  /** Value in px which will determine the thickness of the outer ring of the pulsating circle */
  @Input() outerRingBorderThickness: number = 1;
  /** Value in px which will determine the distance between the 'LIVE' text and the pulsating circle */
  @Input() outerRingMarginLeft: number = 5;
  /** Value in px which will determine the maximum diameter of the inner ring of the pulsating circle */
  @Input() innerCircleMaxSize: number = 7;
  /** Determine whether to keep showing the component when the remaining time hits zero */
  @Input() showLiveFlag: boolean = false;

  readonly remainingTime$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private countdownUpdater$: Subscription;
  private pieUpdater$: Subscription;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private _playoutTimeMs: number = 90000;
  constructor(
    private readonly renderer: Renderer2,
    private readonly pieCountdownElem: ElementRef,
    private readonly appConfig: AppConfigService
  ) {}
  private get timeLeft$(): Observable<number> {
    // return the time left (in ms) to reach the targetTime
    return timer(0, 1000).pipe(
      map(() => {
        const timeLeft = differenceInMilliseconds(new Date(this.targetDate), new Date());
        return timeLeft > 0 ? timeLeft : 0;
      }),
      takeUntil(this.destroy$)
    );
  }

  get playoutTimeMs(): number {
    return this._playoutTimeMs;
  }
  /** Value in ms which will determine the plaoyout duration or when the pie should show Live or inplay indicator */
  @Input() set playoutTimeMs(value: number) {
    this._playoutTimeMs = !value ? this.appConfig.get('virtuals')?.scheduledLeague.playoutTimeMs : value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setStyles();

    if (changes.targetDate) {
      // if the targetDate value changes we need to restart the updater functions
      this.startUpdaters();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setStyles(): void {
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-size', `${this.pieSize}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-color', this.color, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-border-thickness', `${this.borderThickness}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-border-spacing', `${this.borderSpacing}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-background-color', this.backgroundColor, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-text-color', this.textColor, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-text-size', `${this.textSize}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-text-line-height', `${this.textLineHeight || this.textSize}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--live-badge-width', `${this.liveBadgeWidth}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--outer-ring-size', `${this.outerRingSize}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--outer-ring-border-thickness', `${this.outerRingBorderThickness}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--outer-ring-margin-left', `${this.outerRingMarginLeft}px`, 2);
    this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--inner-circle-max-size', `${this.innerCircleMaxSize}px`, 2);
  }

  private startUpdaters(): void {
    this.timeLeft$.pipe(first(), takeUntil(this.destroy$)).subscribe(timeLeft => {
      this.startCountdownUpdater();
      this.startPieUpdater(timeLeft);
    });
  }

  private startCountdownUpdater(): void {
    this.stopCountdownUpdater(); // Cancel any existing subscription

    // Using 500ms to ensure we never skip displaying any value, ex: going from 00:20 to 00:18
    this.countdownUpdater$ = interval(500)
      .pipe(
        withLatestFrom(this.timeLeft$),
        tap(([, timeLeft]) => {
          this.remainingTime$.next(timeLeft);
          if (timeLeft <= 0) {
            this.timeElapsed.emit();
          }
        }),
        takeWhile(([, timeLeft]) => timeLeft > 0),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private stopCountdownUpdater(): void {
    if (this.countdownUpdater$) {
      this.countdownUpdater$.unsubscribe();
      this.countdownUpdater$ = undefined;
    }
  }

  private startPieUpdater(initialTimeLeft: number): void {
    this.stopPieUpdater(); // Cancel any existing subscription

    if (initialTimeLeft > this.pieFullDuration) {
      // Cater for cases when the timeLeft is longer than the defined pieDuration
      this.pieFullDuration = initialTimeLeft;
    }

    // calculate time interval required for a smooth countdown with 1% decrements
    const pieUpdateInterval = this.pieFullDuration / 100;

    // Calculate the initial pie fill percentage value
    this.timeLeft$.pipe(first(), takeUntil(this.destroy$)).subscribe(timeLeft => {
      const pieFillPercentage = (timeLeft * 100) / this.pieFullDuration;
      this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-fill-percentage', `${pieFillPercentage}%`, 2);
    });

    this.pieUpdater$ = interval(pieUpdateInterval)
      .pipe(
        withLatestFrom(this.timeLeft$),
        tap(([, timeLeft]) => {
          const pieFillPercentage = (timeLeft * 100) / this.pieFullDuration;
          this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-fill-percentage', `${pieFillPercentage}%`, 2);
        }),
        takeWhile(([, timeLeft]) => timeLeft > 0),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private stopPieUpdater(): void {
    if (this.pieUpdater$) {
      this.pieUpdater$.unsubscribe();
      this.pieUpdater$ = undefined;
      this.renderer.setStyle(this.pieCountdownElem.nativeElement, '--pie-fill-percentage', '0%', 2);
    }
  }
}
