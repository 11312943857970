import { ChangeDetectionStrategy, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BetCouponGroup, CouponType } from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { CouponGroupingType } from 'src/app/shared/models/coupon.model';

import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStakeHandlerService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-stake-handler.service';

@Component({
  selector: 'app-virtuals-coupon-groupings',
  templateUrl: './virtuals-coupon-groupings.component.html',
  styleUrls: ['./virtuals-coupon-groupings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsCouponGroupingsComponent implements OnInit {
  @Input() groups: BetCouponGroup[];
  @ViewChildren('groupingStake') vc: QueryList<any>;
  groupStakeId: string;

  constructor(
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly couponService: VirtualsCouponService,
    private readonly stakeHandlerService: VirtualsCouponStakeHandlerService
  ) {}

  ngOnInit(): void {
    this.virtualsCouponQuery.couponData$.subscribe(couponData => {
      if (couponData) {
        if (couponData.CouponType === CouponType.Single || couponData.AllGroupings === null) {
          return;
        }

        if (this.vc && this.vc.length > 0) {
          setTimeout(() => {
            const groupStake = this.vc.find(g => g.nativeElement.dataset.group === this.groupStakeId);

            if (groupStake) {
              groupStake.nativeElement.focus();
              this.groupStakeId = undefined;
            }
          }, 0);
        }
      }
    });
  }

  groupSelector(event: Event, group: BetCouponGroup): void {
    event.preventDefault();
    const groupCopy: BetCouponGroup = cloneDeep(group);

    // Works just for the combinations tab
    if (this.virtualsCouponQuery.groupingsTabSelected !== CouponGroupingType.Combination) {
      return;
    } else if (this.virtualsCouponQuery.couponData.Groupings.length === 1) {
      // ensure the last grouping is never unselected
      const selectedGrouping = this.virtualsCouponQuery.couponData.Groupings[0];
      if (selectedGrouping.Grouping === groupCopy.Grouping && selectedGrouping.Combinations === groupCopy.Combinations) {
        return;
      }
    }

    if (this.couponService.enforceSingleCombination && !groupCopy.Selected) {
      // deselect all selected groups if it's the first click after switching to combination tab
      const groupsToUpdate = this.virtualsCouponQuery.couponData.Groupings.filter(g => g.Selected);
      const groupsToUpdateCopy: BetCouponGroup[] = cloneDeep(groupsToUpdate);

      groupsToUpdateCopy.forEach(g => (g.Selected = false));

      // select the clicked group
      groupCopy.Selected = true;
      groupsToUpdateCopy.push(groupCopy);

      this.updateGroupings(groupsToUpdateCopy);
      this.couponService.enforceSingleCombination = false;
    } else {
      groupCopy.Selected = !groupCopy.Selected;
      this.updateGroupings([groupCopy]);
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): void {
    this.couponService.updateGroupings(groupings);
  }

  updateGroupStakeValue(stakeValue: number, group: BetCouponGroup): void {
    const groupCopy: BetCouponGroup = cloneDeep(group);
    this.groupStakeId = `groupStakeValue_${groupCopy.Grouping}`;
    groupCopy.Stake = !stakeValue || isNaN(stakeValue) || stakeValue < 0 ? 0 : stakeValue;
    this.stakeHandlerService.updateGroupingStakeValue(groupCopy);
  }

  indexTrackBy(index: number): number {
    return index;
  }
}
