import { Component, Input } from '@angular/core';

@Component({
  selector: 'virtuals-jackpot-tooltip',
  templateUrl: './virtuals-jackpot-tooltip.component.html',
  styleUrls: ['./virtuals-jackpot-tooltip.component.scss'],
})
export class VirtualsJackpotTooltipComponent {
  @Input() tooltipContent: string;
  showTooltip = false;

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }
}
