<ng-container *ngIf="isFlexicutCoupon$ | async">
  <div class="flexicut-wrapper" data-testid="flexicut-component" [ngClass]="{ 'no-action': (hasInvalidOdds$ | async)}">
    <div class="flexicut-container">
      <coupon-flexicut-selections (triedToGoBelowOddsThreshold)="triedToGoBelowOddsThreshold($event)">
      </coupon-flexicut-selections>

      <div class="right-pane">
        <div i18n class="badge-wrap remove" (click)="removeFlexicutCoupon()">
          <span class="material-icons close">{{'close'}}</span>
          <span class="remove-text">Remove</span>
        </div>
        <div class="material-icons right-arrow" (click)="onRightArrow()">{{'navigate_next'}}</div>
      </div>
    </div>

    <div class="error-message" *ngIf="hasInvalidOdds$ | async" data-testid="flexicut-invalid-odds-error" i18n>
      Flexicut is unavailable when there are selections with odds less than {{ couponQuery.globalVariables?.MinFlexiCutOdds }}
    </div>
    <div class="info-message" *ngIf="showTotalOddsInfoText$ | async" data-testid="flexicut-total-odds-limitation" i18n>
      Can not cut further selections due to limitations on total odds.
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(isFlexicutCoupon$ | async) === false">
  <div class="flexicut-wrapper" data-testid="flexicut-component" (click)="onRightArrow()" [ngClass]="{ 'no-action': (hasInvalidOdds$ | async)}">
    <div class="flexicut-container">
      <div>
        <div class="flexicut-logo"></div>
        <div i18n class="info-text">Increase your chances of winning!</div>
      </div>

      <div class="right-pane">
        <div i18n class="badge-wrap new" *ngIf="flexicutConfig.isNew">new</div>
        <div class="material-icons right-arrow">{{'navigate_next'}}</div>
      </div>
    </div>
    <div class="error-message" *ngIf="hasInvalidOdds$ | async" data-testid="flexicut-invalid-odds-error" i18n>
      Flexicut is unavailable when there are selections with odds less than {{ couponQuery.globalVariables?.MinFlexiCutOdds }}
    </div>
  </div>
</ng-container>

<!-- Load flexicut modal only when flexicut odds are computed & no errors -->
<coupon-flexicut-modal
  [show]="showModal$ | async"
  (closeModal)="toggleFlexicutModal()"
  (scrollToBottom)="scrollToBottomFn()">
</coupon-flexicut-modal>

<!-- Flexicut help modal -->
<app-info-modal
  i18n
  [show]="showHelpModal$ | async"
  (closeModal)="toggleFlexicutHelpModal()"
  data-testid="flexicut-help-modal"
  buttonText="GOT IT"
>
  <div class="help-modal-header" header>
    <div class="flexicut-logo"></div>
    <span class="header-info">You need a minimum of 5 selections in your betslip with a total odds of 1.05 or more to enable this feature.</span>
  </div>

  <div class="help-modal-body" body>
    <div class="section1">
      <h1>What is FlexiCUT?</h1>
      <p>FlexiCUT provides freedom and security by allowing you to CUT matches from your accumulator for a better chance of winning even if they lose.</p>
    </div>

    <div>
      <span>Example:</span>
      <ul>
        <li class="info"><span>CUT 1</span> and if one selection from your acca loses, we'll still pay your winnings</li>
        <li class="info"><span>CUT 2</span> and we'll still pay your winnings if two selections from your acca lose</li>
      </ul>
    </div>
  </div>
</app-info-modal>

