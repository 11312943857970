import { Injectable } from '@angular/core';
import { ApmBase } from '@elastic/apm-rum';
import { ApmService } from '@elastic/apm-rum-angular';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';

interface Config {
  enabled: boolean;
  serverUrl: string;
  serviceName: string;
  environment?: string;
  eventsLimit: number;
  transactionSampleRate: number;
  disableInstrumentations: InstrumentationTypes[];
  breakdownMetrics: boolean;
}

class ApmError extends Error {
  constructor(message: string, details: string) {
    super(details);
    this.name = message;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ElasticService {
  readonly apmClient$ = new BehaviorSubject<ApmBase>(undefined);

  constructor(private readonly appConfig: AppConfigService, private readonly apmService: ApmService) {}

  initialize(): void {
    // initialize APM
    if (!this.apmClient$.value) {
      const config = this.appConfig.get<Config>('apm');
      if (config?.enabled) {
        // full list of properties: https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/configuration.html
        const apmClient = this.apmService.init({
          serverUrl: config.serverUrl,
          serviceName: config.serviceName,
          environment: config.environment || window.location?.hostname, // use hostname if environment is not specified
          eventsLimit: config.eventsLimit,
          transactionSampleRate: config.transactionSampleRate,
          disableInstrumentations: config.disableInstrumentations,
          breakdownMetrics: config.breakdownMetrics,
        });
        this.apmClient$.next(apmClient);
      }
    }
  }

  logApmError(message: string, details: string): void {
    this.apmClient$.value?.captureError(new ApmError(message, details));
  }

  setUserContext(user: UserObject): void {
    this.apmClient$.value?.setUserContext(user);
  }

  clearUserContext(): void {
    this.apmClient$.value?.setUserContext({ id: '', username: '' });
  }
}
