import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { HeaderQuery } from 'src/app/modules/header/state/header.query';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() readonly headerPinToTop: boolean = true;
  isNativeAppBannerVisible: boolean = false;

  readonly isLoading$ = this.headerQuery.isLoading$;
  readonly hideHeaderEmbedConfig$ = this.applicationQuery.embedConfig$.pipe(map(config => config?.hideHeader));
  readonly displayHeader$ = combineLatest([this.isLoading$, this.hideHeaderEmbedConfig$]).pipe(
    map(([isLoading, hideHeaderEmbedConfig]) => !(isLoading || hideHeaderEmbedConfig))
  );
  readonly isAuth$ = this.accountQuery.isAuthenticated$;
  readonly showProductSwitcherBlacklistCheck$ = this.headerQuery.showProductSwitcherBlacklistCheck$;
  readonly showAppBarBlacklistCheck$ = this.headerQuery.showAppBarBlacklistCheck$;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly applicationQuery: ApplicationQuery,
    private readonly headerQuery: HeaderQuery
  ) {}

  onBannerVisibilityChanged(isVisible: boolean) {
    this.isNativeAppBannerVisible = isVisible && window.innerWidth > 900;
  }
}
