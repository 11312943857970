import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { JackpotMappingCMS, JackpotEngineModel } from 'src/app/shared/models/jackpot-engine.model';
import { JackpotEngineStore } from './jackpot-engine.store';

@Injectable({ providedIn: 'root' })
export class JackpotEngineQuery extends Query<JackpotEngineModel> {
  jackpotEngineMapping$ = this.select(state => state.jackpotEngineMappings);

  constructor(protected store: JackpotEngineStore) {
    super(store);
  }

  get jackpotEngineMappings(): JackpotMappingCMS[] {
    return this.getValue().jackpotEngineMappings;
  }
}
