import { Injectable } from '@angular/core';
import { interval, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  EventModel,
  FavouritesEventListModel,
  GroupedMarketsModel,
  LiveEventByArea,
  LiveFeedModel,
  LiveMatchStatuses,
  LiveMatchTrackerProvider,
  MarketGroupingDetailsModel,
  MarketModel,
  SelectionModel,
  TeamModel,
  TournamentModel,
} from 'src/app/shared/models/live.model';
import { LanguageService } from './language.service';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LiveService {
  liveEventsFeed$: Observable<LiveFeedModel[]>;
  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly languageService: LanguageService,
    private readonly liveQuery: LiveQuery,
    private readonly liveStore: LiveStore
  ) {
    this.liveEventsFeed$ = interval(this.appConfig.get('live').pollingTimer).pipe(switchMap(() => this.getLiveEvents(false)));
  }

  initialize(): void {
    this.apiService
      .get<LiveMatchStatuses>(APIType.CMS, `Live/GetLiveMatchStatuses?language=${this.languageService.selectedLanguage.locale}`)
      .pipe(
        first(),
        tap((statuses: LiveMatchStatuses) => {
          if (statuses) {
            this.liveStore.updateLiveMatchStatuses(statuses);
          }
        })
      )
      .subscribe();
  }

  getLiveEvents(isItFirstRun: boolean): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    if (isItFirstRun) {
      this.liveStore.updateUI({ liveFeedsLoading: true });
    }
    return this.apiService
      .get(APIType.SportsbookFeed, `api/feeds/live/overview/general/${this.languageService.selectedLanguage.language}`, apiSettings)
      .pipe(tap(data => this.parseLiveEvents(data, isItFirstRun)));
  }

  getMarketGroupingDetails(): void {
    const fallbackTimer = this.appConfig.get('fallbackTimer');
    const callStarted = this.applicationService.getEpochTime();

    const waitForApiResponse = window.setInterval(() => {
      const callStartedDiff = this.applicationService.getEpochTime() - callStarted;
      const isTimeOver = !(callStartedDiff < fallbackTimer);
      if (isTimeOver) {
        this.getMarketGroupingDetailsFromConfig();
        clearInterval(waitForApiResponse);
      }
    }, 1000);

    this.apiService
      .get(APIType.CMS, `Live/GetLiveMarketGroupingDetails`)
      .pipe(
        catchError(err => {
          this.getMarketGroupingDetailsFromConfig();
          return throwError(err);
        }),
        tap(marketGroupingDetails => {
          this.applicationService.updateCms({ lastLivePageMarketGroupingDetailsUpdate: this.applicationService.getEpochTime() });
          if (marketGroupingDetails && marketGroupingDetails.length > 0) {
            this.liveStore.update({ marketGroupingDetails });
          }
        }),
        finalize(() => {
          clearInterval(waitForApiResponse);
        })
      )
      .subscribe();
  }

  getLiveEventById(eventId: string, areaId: number, isItFirstRun: boolean = false): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    if (isItFirstRun) {
      this.liveStore.updateUI({ selectedLiveEventLoading: true });
    }

    return this.apiService
      .get(
        APIType.SportsbookFeed,
        areaId === 0
          ? `api/feeds/live/${eventId}/${this.languageService.selectedLanguage.language}`
          : `api/feeds/live/byArea/${eventId}/${areaId}/${this.languageService.selectedLanguage.language}`,
        apiSettings
      )
      .pipe(tap(data => this.parseLiveEvent(data, eventId, isItFirstRun)));
  }

  clearLiveEventsFlags(): void {
    this.liveStore.updateUI({ notValidEventId: false });
    this.liveStore.updateSelectedLiveEvent(undefined);
  }

  parseLiveEvent(response: any, eventId: string, isItFirstRun: boolean = false): LiveEventByArea {
    const retVal: LiveEventByArea = {
      areas: [],
      currentArea: { id: undefined, name: undefined, order: undefined },
      tournaments: [],
      eventCount: response.EventCount,
      id: response.Id,
      name: response.Name,
      noOfOdds: response.NoOfOdds,
    };
    if (!response.Tournaments || !response.Tournaments.length) {
      this.liveStore.updateUI({ notValidEventId: true });
      this.liveStore.updateSelectedLiveEvent(undefined);
      this.liveStore.updateUI({ selectedLiveEventLoading: false });
      return;
    } else if (this.liveQuery.notValidEventId) {
      this.liveStore.updateUI({ notValidEventId: false });
    }
    if (response.Areas) {
      let totalMarkets = 0;
      response.Areas.forEach(responseAreas => {
        const newArea = new AreaModel({ id: responseAreas.Id, name: responseAreas.Name, order: responseAreas.Order });
        retVal.areas.push(newArea);

        // add together all markets to get the number of markets available
        if (responseAreas.MarketCount) {
          totalMarkets += responseAreas.MarketCount;
        }
      });

      // find market grouping for a particular sport
      let marketGroupingDetails = this.liveQuery.marketGroupingDetails.find(marketGroup => marketGroup.sportID === response.Id);
      // get the default marketCount if sport not found
      if (!marketGroupingDetails) {
        marketGroupingDetails = this.liveQuery.marketGroupingDetails.find(marketGroup => marketGroup.sportID === 0);
      }

      if (marketGroupingDetails && totalMarkets !== 0 && totalMarkets <= marketGroupingDetails.marketsCount) {
        // if not showing All Markets already
        if (!this.liveQuery.selectedAreaInMatchView || this.liveQuery.selectedAreaInMatchView.id !== 0) {
          const allArea = new AreaModel({ id: 0, name: 'All', order: 0 });
          this.updateSelectedAreaInMatchView(allArea);
          this.getLiveEventById(eventId, allArea.id);
        }
      } else {
        // if not showing specific area already
        if (!this.liveQuery.selectedAreaInMatchView || this.liveQuery.selectedAreaInMatchView.id === 0) {
          const firstArea = retVal.areas[0];
          this.updateSelectedAreaInMatchView(firstArea);
          this.getLiveEventById(eventId, firstArea.id);
        }
      }

      if (response.CurrentArea) {
        const newCurrentArea = new AreaModel({
          id: response.CurrentArea.Id,
          name: response.CurrentArea.Name,
          order: response.CurrentArea.Order,
        });

        retVal.currentArea = newCurrentArea;
      }
    }

    response.Tournaments.forEach(responseTournament => {
      const newTournament = new TournamentModel({
        events: [],
        id: responseTournament.Id,
        name: responseTournament.Name,
        noOfOdds: responseTournament.NoOfOdds,
      });

      responseTournament.Events.forEach(responseEvents => {
        const newEvent = new EventModel({
          aamsMatchID: responseEvents.AAMSMatchID,
          aamsTemplateID: responseEvents.AAMSTemplateID,
          awayGameScore: responseEvents.AwayGameScore,
          categoryId: responseEvents.CategoryId,
          categoryName: responseEvents.CategoryName,
          date: responseEvents.Date,
          hasWidget: responseEvents.HasWidget,
          homeGameScore: responseEvents.HomeGameScore,
          id: responseEvents.Id,
          incompatibleEvents: responseEvents.IncompatibleEvents,
          isStreamed: responseEvents.IsStreamed,
          markets: [],
          eventStatus: responseEvents.EventStatus,
          matchStatus: responseEvents.MatchStatus,
          matchTime: responseEvents.MatchTime,
          name: responseEvents.Name,
          popularity: responseEvents.Popularity,
          providerId: responseEvents.ProviderId,
          providerSourceID: responseEvents.ProviderSourceID,
          score: responseEvents.Score,
          selectionCount: responseEvents.SelectionCount,
          serviceOwner: responseEvents.ServiceOwner,
          setScores: responseEvents.SetScores,
          smartCode: responseEvents.SmartCode,
          sportId: responseEvents.SportId,
          status: responseEvents.Status,
          teams: [],
          tournamentName: responseEvents.TournamentName,
        });

        responseEvents.Teams.forEach(responseTeam => {
          const newTeam = new TeamModel({
            id: responseTeam.Id,
            providerId: responseTeam.ExtProvIDTeam,
            itemOrder: responseTeam.ItemOrder,
            name: responseTeam.Name,
          });
          newEvent.teams.push(newTeam);
        });

        responseEvents.Markets = responseEvents.Markets.map((market, index) => ({
          ...market,
          SortOrder: index,
        }));

        responseEvents.Markets.forEach(responseMarket => {
          if (responseMarket.GroupNo === 0) {
            newEvent.markets.push(this.parseMarket(responseMarket, responseEvents, responseTournament, response));
          }
        });

        // Lightning Bets Grouped Markets
        newEvent.groupedMarkets = this.parseRapidGroupedMarkets(
          responseEvents.Markets.filter(responseMarket => responseMarket.GroupNo === 1),
          responseEvents,
          responseTournament,
          response
        );

        newTournament.events.push(newEvent);
      });
      retVal.tournaments.push(newTournament);
    });

    const newLiveEvent = new LiveEventByArea(retVal);
    this.liveStore.updateSelectedLiveEvent(newLiveEvent);
    if (isItFirstRun) {
      this.liveStore.updateUI({ selectedLiveEventLoading: false });
    }
    return newLiveEvent;
  }

  parseLiveEvents(response: any, isItFirstRun: boolean): LiveFeedModel[] {
    const retVal = [];
    if (!response.Sports || response.Sports.length === 0) {
      this.liveStore.updateUI({ noLiveFeedData: true });
      this.liveStore.updateLiveFeed(undefined);
      return;
    } else if (this.liveQuery.noLiveFeedData) {
      this.liveStore.updateUI({ noLiveFeedData: false });
    }

    const favouritesList = [];

    response.Sports.forEach(responseSport => {
      const liveFeedData = new LiveFeedModel({
        areas: responseSport.Areas,
        currentArea: responseSport.CurrentArea,
        eventCount: responseSport.EventCount,
        id: responseSport.Id,
        name: responseSport.Name,
        noOfOdds: responseSport.NoOfOdds,
        tournaments: [],
        markets: [],
      });

      response.SportsMarkets.forEach(sportMarket => {
        if (sportMarket.Id === responseSport.Id) {
          sportMarket.Markets.forEach(market => {
            const newSportMarket = new MarketModel({
              combinability: market.Combinability,
              compatibleMarkets: market.CompatibleMarkets,
              groupNo: market.GroupNo,
              id: market.Id,
              marketNameRaw: market.MarketNameRaw,
              name: market.Name,
              scheduleStatus: market.ScheduleStatus,
              selections: [],
              specialValue: market.SpecialValue,
              status: market.Status,
              typeId: market.TypeId,
              typeIdGroup: market.TypeIdGroup,
              sportId: responseSport.Id,
            });
            market.Selections.forEach(selection => {
              const newSelection = new SelectionModel({
                id: selection.Id,
                name: selection.Name,
                odds: [],
                typeId: selection.TypeId,
              });
              newSportMarket.selections.push(newSelection);
            });
            liveFeedData.markets.push(newSportMarket);
          });
        }
      });

      responseSport.Tournaments.forEach(responseTournament => {
        const newTournament = new TournamentModel({
          events: [],
          id: responseTournament.Id,
          name: responseTournament.Name,
          noOfOdds: responseTournament.NoOfOdds,
        });

        responseTournament.Events.forEach(responseEvents => {
          // check if the current event is in favourites. To remove the expired events
          if (
            this.liveQuery.favouritesEventsList &&
            this.liveQuery.favouritesEventsList.filter(f => f.sportId === responseSport.Id && f.eventId === responseEvents.Id).length > 0
          ) {
            favouritesList.push(
              new FavouritesEventListModel({
                sportId: responseSport.Id,
                eventId: responseEvents.Id,
              })
            );
          }

          const newEvent = new EventModel({
            aamsMatchID: responseEvents.AAMSMatchID,
            aamsTemplateID: responseEvents.AAMSTemplateID,
            awayGameScore: responseEvents.AwayGameScore,
            categoryId: responseEvents.CategoryId,
            categoryName: responseEvents.CategoryName,
            date: responseEvents.Date,
            hasWidget: responseEvents.HasWidget,
            homeGameScore: responseEvents.HomeGameScore,
            id: responseEvents.Id,
            incompatibleEvents: responseEvents.IncompatibleEvents,
            isStreamed: responseEvents.IsStreamed,
            markets: [],
            matchStatus: responseEvents.MatchStatus,
            eventStatus: responseEvents.EventStatus,
            matchTime: responseEvents.MatchTime,
            name: responseEvents.Name,
            popularity: responseEvents.Popularity,
            providerId: responseEvents.ProviderId,
            providerSourceID: responseEvents.ProviderSourceID,
            score: responseEvents.Score,
            selectionCount: responseEvents.SelectionCount,
            serviceOwner: responseEvents.ServiceOwner,
            setScores: responseEvents.SetScores,
            smartCode: responseEvents.SmartCode,
            sportId: responseEvents.SportId,
            status: responseEvents.Status,
            teams: [],
            tournamentName: responseEvents.TournamentName,
          });

          responseEvents.Teams.forEach(responseTeam => {
            const newTeam = new TeamModel({
              id: responseTeam.Id,
              providerId: responseTeam.ExtProvIDTeam,
              itemOrder: responseTeam.ItemOrder,
              name: responseTeam.Name,
            });
            newEvent.teams.push(newTeam);
          });

          responseEvents.Markets.forEach(responseMarkets => {
            newEvent.markets.push(this.parseMarket(responseMarkets, responseEvents, responseTournament, responseSport));
          });
          newTournament.events.push(newEvent);
        });
        liveFeedData.tournaments.push(newTournament);
      });

      retVal.push(liveFeedData);
    });

    // updated the favourites list
    this.updateFavouritesEventsList(favouritesList);

    // update Live Feed
    this.liveStore.updateLiveFeed(retVal);

    // if first run or no favourites are found, redirect to the first sport
    if (isItFirstRun || (this.liveQuery.selectedFeedId === 0 && this.liveQuery.favouritesEventsList.length === 0)) {
      // Id: 0 - 'favourites'
      if (retVal[0]) {
        this.updateSelectedMarket({ ...retVal[0].markets[0] });
        this.updateSelectedFeedId(retVal[0].id);
      }
      this.liveStore.updateUI({ liveFeedsLoading: false });
    }

    return retVal;
  }

  addToFavourites(sportId: number, eventId: number): void {
    this.updateFavouritesEventsList([
      ...(this.liveQuery.favouritesEventsList || []),
      new FavouritesEventListModel({ sportId: sportId, eventId: eventId }),
    ]);
  }

  removeFromFavourites(sportId: number, eventId: number): void {
    this.updateFavouritesEventsList(this.liveQuery.favouritesEventsList.filter(f => !(f.sportId === sportId && f.eventId === eventId)));

    // Id: 0 - 'favourites'
    if (this.liveQuery.selectedFeedId === 0 && (!this.liveQuery.favouritesEventsList || this.liveQuery.favouritesEventsList.length === 0)) {
      if (this.liveQuery.liveFeeds) {
        this.updateSelectedMarket({ ...this.liveQuery.liveFeeds[0].markets[0] });
        this.updateSelectedFeedId(this.liveQuery.liveFeeds[0].id);
      }
    }
  }

  updateSelectedFeedId(selectedFeedId: number): void {
    this.liveStore.updateSelectedFeedId(selectedFeedId);
  }

  updateSelectedMarket(market: MarketModel): void {
    if (this.liveQuery.selectedMarkets) {
      this.liveStore.updateSelectedMarkets([...this.liveQuery.selectedMarkets.filter(sm => sm.sportId !== market.sportId), market]);
    } else {
      this.liveStore.updateSelectedMarkets([market]);
    }
  }

  updateSelectedMarkets(markets: MarketModel[]): void {
    this.liveStore.updateSelectedMarkets(markets);
  }

  updateLiveViewEnabledByUser(liveViewEnabledByUser: boolean): void {
    this.liveStore.updateLiveViewEnabledByUser(liveViewEnabledByUser);
  }

  updateFavouritesEventsList(favouritesEventsList: FavouritesEventListModel[]): void {
    this.liveStore.updateFavouritesEventsList(favouritesEventsList);
  }

  updateSelectedAreaInMatchView(selectedAreaInMatchView: AreaModel): void {
    this.liveStore.updateSelectedAreaInMatchView(selectedAreaInMatchView);
  }

  updateLiveMatchTrackerProvider(liveMatchTrackerProvider: LiveMatchTrackerProvider): void {
    this.liveStore.updateLiveMatchTrackerProvider(liveMatchTrackerProvider);
  }

  clearSelectedAreaInMatchView(): void {
    this.liveStore.clearSelectedAreaInMatchView();
  }

  private createOddModel({
    odd,
    selection,
    event,
    market,
    tournament,
    sport,
  }: {
    odd: any;
    selection: any;
    event: any;
    market: any;
    tournament: any;
    sport: any;
  }): OddModel {
    const oddModel: Partial<OddModel> = {
      id: selection.Id,
      value: odd.Value,
      sportId: sport.Id,
      sportName: sport.Name,
      tournamentId: tournament.Id,
      tournamentName: tournament.Name,
      matchId: event.Id,
      matchName: event.Name,
      matchDate: event.Date,
      marketTypeId: market.TypeId,
      marketName: market.Name,
      marketId: market.Id,
      smartCode: event.SmartCode,
      combinability: market.Combinability,
      selectionId: selection.TypeId,
      selectionName: selection.Name,
      selectionTypeId: selection.TypeId,
      categoryId: event.CategoryId,
      categoryName: event.CategoryName,
      enabled: Boolean(odd.Status),
      eventCategory: 'L', // F = prematch, L = live
      spreadValue: market.SpecialValue || 0,
      incompatibleEvents: event.IncompatibleEvents,
    };

    return new OddModel(oddModel);
  }

  private getMarketGroupingDetailsFromConfig(): void {
    this.liveStore.update({
      marketGroupingDetails: this.appConfig.get('sports').live.marketGroupingDetailsDefaults.map(mg => new MarketGroupingDetailsModel(mg)),
    });
  }

  private parseMarket(responseMarket: any, responseEvent: any, responseTournament: any, responseSport: any): MarketModel {
    const newMarket = new MarketModel({
      combinability: responseMarket.Combinability,
      compatibleMarkets: responseMarket.CompatibleMarkets,
      groupNo: responseMarket.GroupNo,
      id: responseMarket.Id,
      marketNameRaw: responseMarket.MarketNameRaw,
      name: responseMarket.Name,
      scheduleStatus: responseMarket.ScheduleStatus,
      selections: [],
      specialValue: responseMarket.SpecialValue,
      status: responseMarket.Status,
      typeId: responseMarket.TypeId,
      typeIdGroup: responseMarket.TypeIdGroup,
      sortOrder: responseMarket.SortOrder,
      sportId: responseSport.Id,
    });
    responseMarket.Selections.forEach(responseSelections => {
      // remove selections that does not include any value and status as suspended
      if (!responseSelections.Odds[0]?.Status && responseSelections.Odds[0]?.Value <= 1) return;

      const newSelection = new SelectionModel({
        id: responseSelections.Id,
        name: responseSelections.Name,
        odds: [],
        typeId: responseSelections.TypeId,
      });
      responseSelections.Odds.forEach(responseOdds => {
        const newOdd = this.createOddModel({
          odd: responseOdds,
          selection: responseSelections,
          event: responseEvent,
          market: responseMarket,
          tournament: responseTournament,
          sport: responseSport,
        });
        newSelection.odds.push(newOdd);
      });
      newMarket.selections.push(newSelection);
    });

    return newMarket;
  }

  private parseRapidGroupedMarkets(
    responseMarkets: any[],
    responseEvent: any,
    responseTournament: any,
    responseSport: any
  ): GroupedMarketsModel[] {
    const marketMap = new Map<string, MarketModel[]>();

    responseMarkets.forEach(responseMarket => {
      const previousMarketList = marketMap.get(responseMarket.Specifier);
      marketMap.set(responseMarket.Specifier, [
        ...(previousMarketList ? previousMarketList : []),
        this.parseMarket(responseMarket, responseEvent, responseTournament, responseSport),
      ]);
    });

    const results: GroupedMarketsModel[] = [];

    marketMap.forEach((value, key) => {
      const specifiers = key.split(/~/g);
      const spreadValue = specifiers[0];
      const timeRange = `${specifiers[1]} : ${specifiers[2]}`;

      results.push({
        title: `${$localize`1 minute - `}${timeRange}`,
        markets: value.map(market => ({
          ...market,
          name: `${
            market.name.includes(' - ') ? market.name.split(' - ')[1].split(spreadValue)[0] : market.name.split(spreadValue)[0]
          }${spreadValue})`,
        })),
        sortOrder: value[0].sortOrder,
        groupNo: value[0].groupNo,
      });
    });

    return results;
  }
}
