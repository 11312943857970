import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';

@Component({
  selector: 'header-bk-user-balance',
  templateUrl: './bk-header-user-balance.component.html',
  styleUrls: ['./bk-header-user-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BKHeaderUserBalanceComponent implements OnInit, OnDestroy {
  readonly refreshingBalance$ = new BehaviorSubject(false);
  readonly balance$ = this.accountQuery.userData$.pipe(
    map(
      data =>
        data?.wallets?.[0]?.balance && this.currencyFormatPipe.transform(data.wallets[0].balance, undefined, undefined, undefined, true)
    )
  );
  readonly currency$ = this.accountQuery.userData$.pipe(map(data => data?.currency?.symbol));

  private readonly _timeOfLastRefresh$ = new BehaviorSubject(0);
  private readonly _destroy$ = new Subject();
  readonly REFRESH_ANIMATION_DURATION = 500;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly currencyFormatPipe: CurrencyFormatPipe,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.accountQuery.refreshingBalance$
      .pipe(
        tap(isRefreshing => {
          // Ensure one full cycle of animation is shown
          const delayTillStateChange = Date.now() - this._timeOfLastRefresh$.value;
          if (delayTillStateChange > this.REFRESH_ANIMATION_DURATION) {
            this.refreshingBalance$.next(isRefreshing);
          } else {
            setTimeout(() => this.refreshingBalance$.next(isRefreshing), this.REFRESH_ANIMATION_DURATION);
          }
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  refreshBalance(): void {
    if (!this.accountQuery.refreshingBalance) {
      this.accountService.updateBalance();
      this._timeOfLastRefresh$.next(Date.now());
    }
  }

  goToDeposit(): void {
    this.router.navigateByUrl('/account/deposit');
  }
}
