<div class="flexicut-cut-config-container" data-testid="flexicut-cut-config">
  <div class="flexicut-cut-config">
    <button
      class="selections-arrow"
      [class.disabled]="cannotDecreaseCutSelections$ | async"
      (click)="reduceCutNumber()"
      data-testid="flexicut-decrease-cut"
    >
      <i class="material-icons"> {{ 'remove' }}</i>
    </button>

    <span class="cut-info" data-testid="flexicut-cut-number" i18n>Cut {{ cutNumber$ | async }}</span>

    <button
      class="selections-arrow"
      [class.disabled]="cannotIncreaseCutSelections$ | async"
      (click)="increaseCutNumber()"
      data-testid="flexicut-increase-cut"
    >
      <i class="material-icons"> {{ 'add' }}</i>
    </button>
  </div>
  <div i18n class="flexicut-cut-config-label">{{winningSelections$ | async}} or more matches to win</div>
</div>
