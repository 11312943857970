import { Injectable } from '@angular/core';

import { BetCouponOdd, CouponType, Selection } from 'clientside-coupon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';
import { APIType } from 'src/app/shared/models/api.model';
import { CouponGroupingType, OddModel } from 'src/app/shared/models/coupon.model';
import { VirtualsLeagueType } from 'src/app/shared/models/product.model';
import {
  AreaModel,
  CategoryModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SportModel,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { InstantCouponStore } from 'src/app/core/state/instant-coupon/instant-coupon.store';
import { VirtualsCouponService } from './virtuals-coupon.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsCouponSelectionService {
  couponType: typeof CouponType = CouponType;

  constructor(
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly virtualsCouponService: VirtualsCouponService,
    private readonly couponStore: VirtualsCouponStore,
    private readonly instantCouponStore: InstantCouponStore,
    private readonly apiService: APIService
  ) {}

  parseSelections(selections: BetCouponOdd[], leagueIdentifier: VirtualsLeagueType): void {
    const sports: SportModel[] = [];

    selections.forEach(selection => {
      const odd = new OddModel({
        marketId: selection.MarketId,
        marketName: selection.MarketName,
        selectionName: selection.SelectionName,
        selectionTypeId: selection.IDSelectionType,
        id: selection.SelectionId,
        value: selection.OddValue,
        spreadValue: selection.SpecialValue === '' ? undefined : parseInt(selection.SpecialValue, 10),
      });

      let sport = sports.find(o => o.id === selection.IDSport);
      if (!sport) {
        sport = new SportModel({
          id: selection.IDSport,
          name: selection.SportName,
          categories: [
            new CategoryModel({
              id: selection.EventId,
              name: selection.EventName,
              tournaments: [],
            }),
          ],
        });

        sports.push(sport);
      }

      let category = sport.categories.find(c => c.id === selection.EventId);
      if (!category) {
        category = new CategoryModel({
          id: selection.EventId,
          name: selection.EventName,
          tournaments: [],
        });

        sport.categories.push(category);
      }

      let tournament = category.tournaments.find(t => t.id === selection.TournamentId);
      const extractedSpreadValue = selection.SelectionName.match(/\((.*?)\)/);

      if (!tournament) {
        tournament = new TournamentModel({
          id: selection.TournamentId,
          name: selection.TournamentName,
          matches: [
            new MatchModel({
              id: selection.MatchId,
              name: selection.MatchName,
              date: selection.EventDate,
              smartBetCode: selection.SmartCode?.toString(),
              eventCategory: selection.EventCategory,
              categoryName: selection.TournamentName,
              combinability: selection.CompatibilityLevel,
              fixed: selection.Fixed,
              allowFixed: selection.AllowFixed,
              isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed, leagueIdentifier),
              odds: [odd],
            }),
          ],
          regions: [
            new RegionModel({
              isDefault: true,
              areas: [
                new AreaModel({
                  isDefault: true,
                  markets: [
                    new MarketModel({
                      id: selection.MarketId,
                      name: selection.MarketName,
                      groupingType: undefined,
                      marketType: undefined,
                      typeId: selection.MarketTypeId,
                      description: selection.GamePlayDescription,
                      spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
                    }),
                  ],
                }),
              ],
            }),
          ],
        });
        category.tournaments.push(tournament);
      } else {
        let match = tournament.matches.find(m => m.id === selection.MatchId);
        if (!match) {
          match = new MatchModel({
            id: selection.MatchId,
            name: selection.MatchName,
            date: selection.EventDate,
            smartBetCode: selection.SmartCode?.toString(),
            eventCategory: selection.EventCategory,
            categoryName: selection.TournamentName,
            combinability: selection.CompatibilityLevel,
            fixed: selection.Fixed,
            allowFixed: selection.AllowFixed,
            isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed, leagueIdentifier),
            odds: [],
          });

          tournament.matches.push(match);
        }

        match.odds.push(odd);

        const area = tournament.regions[0].areas[0];
        let market = area.markets.find(m => m.id === selection.MarketId);
        if (!market) {
          market = new MarketModel({
            id: selection.MarketId,
            name: selection.MarketName,
            groupingType: undefined,
            marketType: undefined,
            typeId: selection.MarketTypeId,
            description: selection.GamePlayDescription,
            spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
          });

          area.markets.push(market);
        }
      }
    });

    this.sortMatches(sports);
    if (leagueIdentifier === VirtualsLeagueType.Instant) {
      this.instantCouponStore.updateSelections(sports);
    } else {
      this.couponStore.updateSelections(sports);
    }
  }

  sortMatches(sports: SportModel[]): void {
    sports.forEach(sport => {
      sport.categories[0].tournaments.sort((tournamentA, tournamentB) => tournamentA.id - tournamentB.id);

      sport.categories[0].tournaments.forEach(tournament => {
        tournament.matches.sort((matchA: MatchModel, matchB: MatchModel) => matchA.id - matchB.id);
      });
    });

    sports.sort((sportA: SportModel, sportB: SportModel) => sportA.id - sportB.id);
  }

  isBankerDisabled(allowFixed: boolean, fixed: boolean, leagueIdentifier: VirtualsLeagueType): boolean {
    if (leagueIdentifier === VirtualsLeagueType.Instant) {
      return;
    }
    if (
      !allowFixed ||
      this.virtualsCouponQuery.couponData?.CouponType !== this.couponType.System ||
      this.virtualsCouponQuery.groupingsTabSelected !== CouponGroupingType.Combination
    ) {
      // In these cases banker should be disabled, no further logic is required
      return true;
    }

    if (!fixed) {
      // Check if odd can be set as banker or not
      const combGroupings = [];
      this.virtualsCouponQuery.couponData?.AllGroupings.forEach((g, idx) => {
        const isLastGrouping = idx === this.virtualsCouponQuery.couponData.AllGroupings.length - 1;

        if (g.Grouping > 0 && g.Combinations > 0 && this.virtualsCouponService.isGroupingVisible(g, isLastGrouping)) {
          combGroupings.push(g);
        }
      });

      // If only 1 grouping is available for selection, stop any further Bankers
      if (combGroupings.length <= 1) {
        return true;
      }
    }

    return false;
  }

  getMarketSelections(smartBetCode: number, marketId: number): Observable<Selection[]> {
    return this.apiService.get(APIType.VirtualsFeed, `api/feeds/prematch/QuickBet/ByMarketTypes/${smartBetCode}/${marketId}/en`).pipe(
      map(response => {
        const odds: Selection[] = [];
        response.Markets.forEach(market =>
          market.Markets.forEach(matchOdds => {
            const odd = new OddModel({});
            odd.id = matchOdds.MatchOddsID;
            odd.smartCode = parseInt(response.SmartBetCode, 10);
            odd.marketId = market.OddsType.OddsTypeID;
            odd.marketName = market.OddsType.OddsTypeName;
            odd.marketTypeId = market.OddsType.OddsTypeID;
            odd.selectionId = matchOdds.OddAttribute.OddTypeID;
            odd.selectionName = matchOdds.OddAttribute.OddName;
            odd.spreadValue = market.SpecialBetValue;
            odd.value = matchOdds.OddOutcome;
            odd.categoryId = response.IDCategory;
            odd.categoryName = response.CategoryName;
            odd.eventCategory = response.EventCategory;
            odd.matchDate = response.EventDate;
            odd.matchName = response.EventName;
            odd.matchId = response.IDEvent;
            odd.sportId = response.IDSport;
            odd.sportName = response.SportName;
            odd.tournamentId = response.IDTournament;
            odd.tournamentName = response.TournamentName;
            odd.combinability = response.Markets[0].GamePlay;

            odds.push(odd.toSelection());
          })
        );
        return odds;
      })
    );
  }
}
