import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { MarketModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-market-odds',
  templateUrl: './multi-line-market-odds.component.html',
  styleUrls: ['./multi-line-market-odds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiLineMarketOddsComponent implements OnInit {
  @Input() odds: OddModel[];
  @Input() market: MarketModel;
  @Input() bordered: boolean = true;
  @Input() showSpreadLabels = true;

  spreadMap: Map<string | number, OddModel[]> = undefined;

  oddTrackBy(index: number, odd: OddModel): number {
    return odd.id;
  }

  ngOnInit(): void {
    this.spreadMap = this.sortOddsBySpreadValue();
  }

  private readonly sortOddsBySpreadValue = () => {
    const oddsBySpreadValue = new Map<string | number, OddModel[]>();
    this.oddChecker().map(odd => {
      const spreadValueOdds = oddsBySpreadValue.has(odd.spreadValueDisplay) ? oddsBySpreadValue.get(odd.spreadValueDisplay) : [];
      oddsBySpreadValue.set(odd.spreadValueDisplay, [...spreadValueOdds, odd]);
    });

    return oddsBySpreadValue;
  };

  private readonly oddChecker = (): OddModel[] => {
    if (this.market) {
      return this.odds
        ? this.odds.filter(odd => odd.marketTypeId === this.market.typeId && odd.spreadValue === this.market.spreadValue)
        : [];
    } else {
      return this.odds;
    }
  };
}
