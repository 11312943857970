<div class="header-container-spacer" [class.with-product-switcher]="showProductSwitcherBlacklistCheck$ | async"
  [class.with-app-bar]="showAppBarBlacklistCheck$ | async" [class.pin-to-top]="headerPinToTop === true"
  *ngIf="displayHeader$ | async">
  <header class="header-container">
    <div class="app-bar" *ngIf="showAppBarBlacklistCheck$ | async">
      <app-native-app-banner (bannerVisibilityChanged)="onBannerVisibilityChanged($event)"></app-native-app-banner>
      <header-bk-product-switcher *ngIf="isNativeAppBannerVisible"></header-bk-product-switcher>
      <div class="header-flex">
        <header-bk-logo></header-bk-logo>
        <div class="header-action-section">
          <header-bk-user *ngIf="isAuth$ | async; else isNotAuth"></header-bk-user>
          <ng-template #isNotAuth>
            <header-bk-guest></header-bk-guest>
          </ng-template>
        </div>
      </div>
    </div>
    <header-bk-product-switcher
      *ngIf="!isNativeAppBannerVisible && showProductSwitcherBlacklistCheck$ | async"></header-bk-product-switcher>
  </header>
</div>
