import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import {
  AreaMatchModel,
  DayModel,
  MatchByTournamentModel,
  RegionsWithAreasModel,
  SortType,
  SportModel,
  TodaysEventsState,
} from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { AreaModel, MarketModel, MatchModel, RegionModel } from 'src/app/shared/models/sport.model';

function createInitialState(): TodaysEventsState {
  return {
    areaMatches: undefined,
    regionsWithAreas: undefined,
    areas: undefined,
    regions: undefined,
    sports: undefined,
    tournaments: undefined,
    matchesByTournament: undefined,
    matchesByTime: undefined,
    isItMultiline: undefined,
    isItCorrectScores: undefined,
    isItOverUnder: undefined,
    isPlayerMarket: false,
    isRegularMarket: false,
    selectedDay: undefined,
    selectedSport: undefined,
    selectedArea: undefined,
    selectedRegion: undefined,
    selectedMarket: undefined,
    sortType: SortType.ByTime,
    selectedTournamentId: undefined,
    ui: {
      isEventsLoading: false,
      firstTimeLoadIsInProgress: true,
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'todaysEvents' })
export class TodaysEventsStore extends Store<TodaysEventsState> {
  constructor(private readonly sportStore: SportStore) {
    super(createInitialState());
  }

  updateAreas(areas: AreaModel[]): void {
    this.update({ areas });
  }

  updateRegions(regions: RegionModel[]): void {
    this.update({ regions });
  }

  updateFirstTimeLoadIsInProgress(firstTimeLoadIsInProgress: boolean): void {
    this.updateUI({ firstTimeLoadIsInProgress });
  }

  updateMultilineType(isItMultiline: boolean): void {
    this.update({ isItMultiline });
  }

  updateCorrectScoresType(isItCorrectScores: boolean): void {
    this.update({ isItCorrectScores });
  }

  updateOverUnderType(isItOverUnder: boolean): void {
    this.update({ isItOverUnder });
  }

  updateSortType(sortType: SortType): void {
    this.update({ sortType });
  }

  updateSelectedDay(selectedDay: DayModel): void {
    this.update({ selectedDay });
  }

  updateSelectedMarket(selectedMarket: MarketModel): void {
    if (selectedMarket) {
      this.update({ selectedMarket });
      this.sportStore.updateEventSelection({
        selectedMarket: selectedMarket,
      });
    } else {
      this.update({ selectedMarket: undefined });
    }
  }

  updateSelectedRegion(selectedRegion: { region: RegionModel; areas: AreaModel[] }): void {
    this.update({ selectedRegion });
  }

  updateSelectedArea(selectedArea: AreaModel): void {
    this.update({ selectedArea });

    this.sportStore.updateEventSelection({
      selectedArea: selectedArea,
      selectedAreaId: selectedArea ? selectedArea.id : undefined,
    });
  }

  updateRegionsWithAreas(regionsWithAreas: RegionsWithAreasModel[]): void {
    this.update({ regionsWithAreas });
  }

  updateSelectedTournamentId(selectedTournamentId: number): void {
    this.update({ selectedTournamentId });
  }

  updateMatchesByTournament(matchesByTournament: MatchByTournamentModel[]): void {
    this.update({ matchesByTournament });
  }

  updateMatchesByTime(matchesByTime: MatchModel[]): void {
    this.update({ matchesByTime });
  }

  updateAreaMatches(areaMatches: AreaMatchModel[]): void {
    this.update({ areaMatches });
  }

  updateSports(sports: SportModel[]): void {
    this.update({ sports });
  }

  updateSelectedSports(selectedSport: SportModel): void {
    this.update({ selectedSport });
  }

  updateTournaments(tournaments: []): void {
    this.update({ tournaments });
  }

  updateMarketTypes(isRegularMarket: boolean, isPlayerMarket: boolean): void {
    this.update({
      isRegularMarket,
      isPlayerMarket,
    });
  }

  updateUI(ui: Partial<TodaysEventsState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }
}
