export enum APIType {
  BetRadarResults,
  BetRadarStatistics,
  CMS,
  Guardian,
  F2PGuardian,
  Local,
  Platform,
  Sportsbook,
  SportsbookBetting,
  SportsbookCashout,
  SportsbookFeed,
  SportsbookVslFeed,
  SportsbookOffer,
  Virtuals,
  VirtualsBetSearch,
  VirtualsFeed,
  KMJackpots,
  JackpotBets,
  StrapiCms,
  Website,
  Engagement,
  SuperPicksValidationDomain,
  Casino,
  BFFGateway,
  BFFSportsbook,
  UserService,
  SignalrNotifications,
}

export class APISettings {
  contentType: string;
  playSource: number;
  noAuthToken: boolean;
  forceAuthToken: string;
  inBehalfOf: string;
  sendActivitySource: boolean;
  timeout: number;
  adjustId?: string;
  idfa?: string;
  gpsAdid?: string;

  constructor(init?: Partial<APISettings>) {
    Object.assign(this, init);
  }
}

export class APIResult<T> {
  success = false;
  data: T;
  error: string;
}
