<div class="odds">
  <ng-container *ngIf="odds.length > 0">
    <div *ngFor="let odd of odds; let first = first; let last = last; trackBy: idTrackBy">
      <div class="selection-name">{{ odd.selectionName }}</div>
      <app-live-odd [ngClass]="{'first': first, 'last': last}" [odd]="odd" [eventLocation]="eventLocation" [matchPosition]="matchPosition"></app-live-odd>
    </div>
  </ng-container>

  <ng-container *ngIf="odds.length === 0">
    <div>
      <div class="selection-name">-</div>
      <app-live-odd class="first"></app-live-odd>
    </div>
    <div>
      <div class="selection-name">-</div>
      <app-live-odd></app-live-odd>
    </div>
    <div>
      <div class="selection-name">-</div>
      <app-live-odd class="last"></app-live-odd>
    </div>
  </ng-container>
</div>
