import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { JackpotEngineQuery } from 'src/app/core/state/jackpot-engine/jackpot-engine.query';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { JackpotMappingCMS } from 'src/app/shared/models/jackpot-engine.model';
import { JackpotEngineStore } from 'src/app/core/state/jackpot-engine/jackpot-engine.store';
import { LoggerService } from './logger.service';
import { APIService } from './api.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class JackpotEngineService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly jackpotEngineQuery: JackpotEngineQuery,
    private readonly loggingService: LoggerService,
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly jackpotEngineStore: JackpotEngineStore
  ) {}

  getJackpotsCms(): Observable<JackpotMappingCMS[]> {
    if (this.jackpotEngineQuery.jackpotEngineMappings) {
      return of(this.jackpotEngineQuery.jackpotEngineMappings);
    }

    return this.apiService
      .gqlQuery<{ virtualsJackpot: { data: { attributes: { jackpots: JackpotMappingCMS[] } } } }>(
        `
        query VirtualsJackpotJackpots($locale: I18NLocaleCode) {
          virtualsJackpot(locale: $locale) {
            data {
              attributes {
                jackpots {
                  jackpotId
                  jackpotDisplayName
                  jackpotSlug
                  jackpotInfo
                  jackpotDropInfo
                  jackpotLogo {
                    data {
                        id
                        attributes {
                          url
                        }
                      }
                    }
                  calculateProgressBarByDropAmount
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
      .pipe(
        map(({ data }) => data?.virtualsJackpot?.data?.attributes?.jackpots),
        tap(data => {
          !!data && this.jackpotEngineStore.updateJackpotEngineMapping(data);
        }),
        takeUntil(this.destroy$)
      );
  }

  getJackpotName(jackpotId: number | string): string {
    // eslint-disable-next-line
    const jackpotName = this.jackpotEngineQuery.jackpotEngineMappings?.find(jackpot => jackpot.jackpotId == jackpotId)?.jackpotDisplayName;
    if (!jackpotName) {
      this.loggingService.logEvent('JP Error', `Jackpot name mapping error for jackpot with id ${jackpotId}`, SeverityLevel.Error);
    }

    return jackpotName || 'Jackpot';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
