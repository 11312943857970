import { Injectable, OnDestroy } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LocalStorageService } from 'ngx-webstorage';
import {
  CategoryEventsTimings,
  VirtualsBetSuccessDialogModel,
  VirtualsInfoSection,
  VirtualsInstantLeagueMap,
  VirtualsJackpot,
  VirtualsLobbyContent,
  VirtualsSlideUpType,
  VirtualsState,
  VirtualsUIState,
  VirtualsJackpotBanner,
} from 'src/app/shared/models/virtuals.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';

function createInitialState(): VirtualsState {
  return {
    lobbyContent: undefined,
    lobbyInfoSections: undefined,
    groupEntityId: undefined,
    instantLeagueMap: undefined,
    instantUserData: undefined,
    categoryEventsTimings: undefined,
    jackpots: undefined,
    breadcrumbNavigation: undefined,
    ui: {
      slideUps: {},
      betSuccessDialogVisibility: false,
    },
    betSuccessDialogContent: undefined,
    jackpotBanner: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'virtuals' })
export class VirtualsStore extends Store<VirtualsState> implements OnDestroy {
  private readonly instantLeagueConfig = this.appConfig.get('virtuals').instantLeague;
  private readonly instantUserDataKey = this.instantLeagueConfig?.userDataStorageKey;
  private readonly additionalInstantCouponDataKey = this.instantLeagueConfig?.couponExtraDataStorageKey;
  private readonly instantRecentBetsStorageKey = this.instantLeagueConfig?.recentBetsStorageKey;
  private readonly lastEventAndSelectedCategoryKey = this.instantLeagueConfig?.lastEventAndCategoryStorageKey;
  private readonly instantCouponDataStorageKey = this.instantLeagueConfig?.couponDataStorageKey;
  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly localStorage: LocalStorageService, private readonly appConfig: AppConfigService) {
    super(createInitialState());

    this.updateInstantUserData(this.localStorage.retrieve(this.instantUserDataKey));
    this.observeInstantUserData();
  }

  updateLobbyContent(lobbyContent: VirtualsLobbyContent): void {
    this.update({ lobbyContent });
  }

  clearLobbyContent(): void {
    this.update({ lobbyContent: undefined });
  }

  updateLobbyInfoSections(lobbyInfoSections: VirtualsInfoSection[]): void {
    this.update({ lobbyInfoSections });
  }

  clearLobbyInfoSections(): void {
    this.update({ lobbyInfoSections: undefined });
  }

  updateGroupEntityId(groupEntityId: number): void {
    this.update({ groupEntityId });
  }

  clearGroupEntityId(): void {
    this.update({ groupEntityId: undefined });
  }

  updateInstantLeagueMap(instantLeagueMap: VirtualsInstantLeagueMap): void {
    this.update({ instantLeagueMap });
  }

  clearInstantLeagueMap(): void {
    this.update({ instantLeagueMap: undefined });
  }

  updateInstantUserData(instantUserData: any): void {
    if (!instantUserData) {
      this.clearInstantUserData();
      return;
    }

    this.update({ instantUserData });
    this.localStorage.store(this.instantUserDataKey, instantUserData);
  }

  observeInstantUserData(): void {
    this.localStorage
      .observe(this.instantUserDataKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.updateInstantUserData(value);
      });
  }

  clearInstantUserData(): void {
    this.update({ instantUserData: undefined });
    this.localStorage.clear(this.instantUserDataKey);
  }

  clearUserOnDemandData(): void {
    this.clearInstantUserData();
    this.localStorage.clear(this.additionalInstantCouponDataKey);
    this.localStorage.clear(this.instantRecentBetsStorageKey);
    this.localStorage.clear(this.lastEventAndSelectedCategoryKey);
    this.localStorage.clear(this.instantCouponDataStorageKey);
  }

  updateCategoryEventsTimings(categoryEventsTimings: CategoryEventsTimings): void {
    this.update(state => ({
      ...state,
      categoryEventsTimings: {
        ...state.categoryEventsTimings,
        ...categoryEventsTimings,
      },
    }));
  }

  removeCategoryEventsTimings(categoryIds: (number | string)[]): void {
    this.update(state => {
      const updatedTimings = { ...state.categoryEventsTimings };
      categoryIds.forEach(id => delete updatedTimings[id]);

      return {
        ...state,
        categoryEventsTimings: {
          ...updatedTimings,
        },
      };
    });
  }

  clearCategoryEventsTimings(): void {
    this.update(state => ({
      ...state,
      categoryEventsTimings: undefined,
    }));
  }

  updateJackpots(jackpots: VirtualsJackpot[]): void {
    this.update(state => ({
      ...state,
      jackpots,
    }));
  }

  clearJackpots(): void {
    this.update(state => ({
      ...state,
      jackpots: undefined,
    }));
  }

  updateUI(ui: VirtualsUIState): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateBetSuccessDialogVisibility(visibility: boolean): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        betSuccessDialogVisibility: visibility,
      },
    }));
  }

  openSlideUp(slideUpType: VirtualsSlideUpType): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        slideUps: {
          [slideUpType]: true,
        },
      },
    }));
  }

  closeSlideUp(slideUpType: VirtualsSlideUpType): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        slideUps: {
          ...state.ui.slideUps,
          [slideUpType]: false,
        },
      },
    }));
  }

  closeSlideUps(): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        slideUps: {},
      },
    }));
  }

  updateBreadcrumbNavigation(breadcrumbNavigation: BreadcrumbNavigation): void {
    this.update({ breadcrumbNavigation });
  }

  clearBreadcrumbNavigation(): void {
    this.update({ breadcrumbNavigation: undefined });
  }

  updateSuccessBetDialogContent(betSuccessDialogContent: VirtualsBetSuccessDialogModel): void {
    this.update({ betSuccessDialogContent });
  }

  // Strapi_Jackpot
  updateJackpotBanner(jackpotBanner: VirtualsJackpotBanner): void {
    this.update({ jackpotBanner });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
