import { Injectable } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { EventOddsWrapperComponent } from 'src/app/modules/sport/components/event-odds/wrapper/event-odds-wrapper.component';
import { SportsHomePageComponent } from 'src/app/modules/sport/components/home-page/home-page.component';
import { SportBreadcrumb, SportBreadcrumbRouteData } from 'src/app/shared/models/sport.model';
@Injectable({
  providedIn: 'root',
})
export class SportsBreadcrumbService {
  constructor(private readonly sportStore: SportStore) {}

  openDropdownForActiveItem(parentItem: SportBreadcrumb): void {
    this.sportStore.updateUI({
      openedBreadcrumbSection: parentItem.id,
    });
  }

  closeDropdown(): void {
    this.sportStore.updateUI({
      openedBreadcrumbSection: undefined,
    });
  }

  setBreadcrumbs(
    breadcrumbData$: BehaviorSubject<any>,
    activatedRoute: ActivatedRoute,
    instance: SportsHomePageComponent | EventOddsWrapperComponent,
    routeData: Data,
    showTabsAndCategoryBreadcrumbChildren: boolean,
    destroy$: Subject<boolean>
  ) {
    const breadcrumbData = routeData.breadcrumb as SportBreadcrumbRouteData[];
    if (breadcrumbData && breadcrumbData.length > 0) {
      breadcrumbData$.next(
        breadcrumbData.map(
          (crumb, index) =>
            ({
              label: crumb.label && crumb.label(instance),
              children:
                (index !== 1 || showTabsAndCategoryBreadcrumbChildren) &&
                crumb.getChildren &&
                crumb.getChildren(instance, activatedRoute).pipe(takeUntil(destroy$)),
              id: crumb.id && crumb.id(instance),
            } as SportBreadcrumb)
        )
      );
    }
  }
}
