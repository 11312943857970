import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LandingQuery } from 'src/app/core/state/landing/landing.query';
import { PaginationDirective } from 'src/app/shared/directives/pagination.directive';
import { ShowMoreDirective } from 'src/app/shared/directives/show-more.directive';

import { EventSummaryModel, MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-summary',
  templateUrl: './events-summary.component.html',
  styleUrls: ['./events-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSummaryComponent implements AfterViewInit, OnDestroy {
  @Input() eventsInfo: EventSummaryModel;
  @Input() showMarketName: boolean = false;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() sortable?: boolean = false;
  @Input() selectedOption: MarketModel;
  @Input() isNewLiveWidget: boolean = false;
  @Input() stickyZIndex: number = 0;
  @Input() variant: string;
  @Input() pageSize: number = 5;

  @Output() readonly optionSelected = new EventEmitter<MarketModel>();

  @ViewChild(PaginationDirective, { static: false }) pagination: PaginationDirective;
  @ViewChild(ShowMoreDirective, { static: false }) showMoreDirective: ShowMoreDirective;
  @ViewChild('toggleSpan', { static: false }) toggleSpan: ElementRef;

  readonly currentPage$ = new BehaviorSubject<number>(undefined);
  readonly totalPages$ = new BehaviorSubject<number>(undefined);
  readonly pages$ = new BehaviorSubject<number[]>(undefined);
  private previousMatches: MatchModel[];
  private refreshPaginationInterval: any;

  constructor(readonly landingQuery: LandingQuery) {}

  ngAfterViewInit() {
    if (this.variant === 'Variation-1') {
      this.initPagination();
      this.startPolling();
    } else if (this.variant === 'Variation-2') {
      this.initShowMore();
      this.startPollingShowMore();
    }
  }

  ngOnDestroy() {
    if (this.variant === 'Variation-1') {
      this.stopPolling();
    } else if (this.variant === 'Variation-2') {
      this.stopPollingShowMore();
    }
  }

  private initPagination() {
    if (this.pagination) {
      this.updatePagination();
      this.pagination.pageChange.subscribe(page => {
        this.currentPage$.next(page);
        this.updatePages();
      });
    } else {
      console.error('Pagination directive not found');
    }
  }

  private refreshPagination() {
    if (this.pagination) {
      this.pagination.refresh();
      this.updatePagination();
    }
  }

  private startPolling() {
    this.previousMatches = [...this.eventsInfo.matches];
    this.refreshPaginationInterval = setInterval(() => {
      if (this.hasMatchesChanged()) {
        this.refreshPagination();
      }
    }, 0);
  }

  private stopPolling() {
    if (this.refreshPaginationInterval) {
      clearInterval(this.refreshPaginationInterval);
    }
  }

  private initShowMore() {
    if (this.showMoreDirective) {
      this.showMoreDirective.resetItems();
      this.showMoreDirective.updateToggleElement();
    } else {
      console.error('Show More directive not found');
    }
  }

  private startPollingShowMore() {
    this.previousMatches = [...this.eventsInfo.matches];
    this.refreshPaginationInterval = setInterval(() => {
      if (this.hasMatchesChanged()) {
        this.showMoreDirective.renderItems();
        this.showMoreDirective.updateToggleElement();
      }
    }, 0);
  }

  private stopPollingShowMore() {
    if (this.refreshPaginationInterval) {
      clearInterval(this.refreshPaginationInterval);
    }
  }

  private hasMatchesChanged(): boolean {
    if (!this.eventsInfo || !this.eventsInfo.matches) return false;
    if (this.previousMatches.length !== this.eventsInfo.matches.length) {
      this.previousMatches = [...this.eventsInfo.matches];
      return true;
    }
    for (let i = 0; i < this.previousMatches.length; i++) {
      if (this.previousMatches[i].id !== this.eventsInfo.matches[i].id) {
        this.previousMatches = [...this.eventsInfo.matches];
        return true;
      }
    }
    return false;
  }

  private updatePagination() {
    if (this.pagination) {
      this.currentPage$.next(this.pagination.getCurrentPage());
      this.totalPages$.next(this.pagination.getTotalPages());
      this.updatePages();
      this.ensurePageWithinBounds();
    }
  }

  private ensurePageWithinBounds() {
    if (this.currentPage$.value > this.totalPages$.value) {
      this.currentPage$.next(this.totalPages$.value || 1);
      this.pagination.goToPage(this.currentPage$.value);
    }
  }

  private updatePages() {
    if (this.totalPages$.value > 0) {
      this.pages$.next(Array.from({ length: this.totalPages$.value }, (_, i) => i + 1));
    } else {
      this.pages$.next([]);
    }
  }

  nextPage() {
    if (this.pagination) {
      this.pagination.nextPage();
      this.updatePagination();
    }
  }

  previousPage() {
    if (this.pagination) {
      this.pagination.previousPage();
      this.updatePagination();
    }
  }

  goToPage(page: number) {
    if (this.pagination) {
      this.pagination.goToPage(page);
      this.updatePagination();
    }
  }

  updatePageInfo() {
    if (this.pagination) {
      this.updatePagination();
    }
  }

  showMore() {
    if (this.showMoreDirective) {
      this.showMoreDirective.showMore();
      this.showMoreDirective.updateToggleElement();
    }
  }

  showLess() {
    if (this.showMoreDirective) {
      this.showMoreDirective.showLess();
      this.showMoreDirective.updateToggleElement();
    }
  }

  toggleShowMore() {
    const toggleSpanElement = this.toggleSpan.nativeElement;
    if (toggleSpanElement.classList.contains('show-more')) {
      this.showMore();
    } else {
      this.showLess();
    }
  }

  eventTrackBy(index: number, match: MatchModel): number {
    return match.id;
  }

  groupedMarketSelected(event: MarketModel) {
    this.selectedOption = { ...event };
    this.optionSelected.emit(event);
  }
}
