import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BannerService } from 'src/app/core/services/banner.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BannerStore } from 'src/app/core/state/banner/banner.store';
import { BannerModel, BannerVisibility, BannerState } from 'src/app/shared/models/banner.model';

@Injectable({ providedIn: 'root' })
export class BannerQuery extends Query<BannerState> {
  loading$ = this.selectLoading();
  error$ = this.selectError();
  bannerDetails$ = this.select(state => (state.bannerDetails ? state.bannerDetails.filter(banner => this.authCheck(banner)) : []));

  constructor(protected store: BannerStore, private readonly accountQuery: AccountQuery, private readonly bannerService: BannerService) {
    super(store);
  }

  get bannerStateHistory() {
    return this.getValue().bannerStateHistory;
  }

  authCheck(banner: BannerModel): boolean {
    let bannerState;
    const allBannerHistory = this.bannerService.getAllBannerHistoryState();
    if (this.accountQuery.isAuthenticated && allBannerHistory && allBannerHistory[this.accountQuery.userData.id]) {
      bannerState = allBannerHistory[this.accountQuery.userData.id].bannerState;
    }
    if (!this.accountQuery.isAuthenticated && allBannerHistory) {
      for (const key in allBannerHistory) {
        if (allBannerHistory[key].isLastUser) {
          bannerState = allBannerHistory[key].bannerState;
          break;
        }
      }
    }

    return (
      banner.display === BannerVisibility.Always ||
      (banner.display === BannerVisibility.NewUser && bannerState === undefined) ||
      (banner.display === BannerVisibility.Authorised && bannerState === 'Authorised') ||
      (banner.display === BannerVisibility.Deposited && bannerState === 'Deposited') ||
      (banner.display === BannerVisibility.PlacedBet && bannerState === 'PlacedBet')
    );
  }
}
