<div class="statistics-container"  [@fadeIn] >
  <div class="statistics-header" appAdjustTabOnSelected>
    <div class="statistics-tab" *ngFor="let tab of tabs" [class.selected]="(selectedTab$ | async) === tab.key" (click)="onTabSelected(tab.key)">
      {{tab.name}}
    </div>
  </div>
  <div class="statistics-widget" id="playerFrame">
    <div id="cppContainer"  *ngIf="(noStatistics$ | async) === false"></div>
    <div class="empty-statistics" *ngIf="noStatistics$ | async">
      <div i18n class="empty-statistics-text">No stats currently available</div>
    </div>
  </div>
</div>
