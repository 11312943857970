import { Injectable, OnDestroy } from '@angular/core';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { Flexicut, FlexicutComputationInitialisationVariables } from '@kingmakers-tech/flexicut-csc';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { Subject, combineLatest } from 'rxjs';
import { ClientsideCouponService, CouponAction, UpdateFlexicutCouponRequest } from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { filter, tap } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UPDATE_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';

@Injectable({
  providedIn: 'root',
})
export class CouponFlexicutService implements OnDestroy {
  private readonly _fcService = new Flexicut();
  private readonly _destroy$ = new Subject<boolean>();

  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore,
    private readonly dataLayerService: DataLayerService,
    private readonly notificationService: NotificationService
  ) {
    this.initialise();
  }

  updateFlexicutOdds(odds?: number[]): number[] {
    const flexicutCSCResponse = this._fcService.computeFlexicutOdds({ odds: odds ? odds : this.couponQuery.selectionOdds });
    this.couponStore.updateFlexicutOdds(flexicutCSCResponse);
    if (flexicutCSCResponse.error) {
      return [];
    } else {
      return flexicutCSCResponse.cutOdds.result;
    }
  }

  setFlexicutCutNumber = (cutNumber: number) => {
    if (!this.couponQuery.hasFlexicutOdds) {
      //  Do first time computation
      this.updateFlexicutOdds();
    }

    const updateCouponResponse = this.clientsideCouponService.updateFlexicutCoupon(
      new UpdateFlexicutCouponRequest({
        action: CouponAction.UpdateFlexicutNumber,
        brandID: this.appConfigService.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        flexicutOddValue: this.couponQuery.getFlexicutOddForACutNumber(cutNumber),
        cutNumber: cutNumber,
      })
    );

    if (updateCouponResponse.success) {
      this.couponStore.updateCouponData(updateCouponResponse.updatedCoupon);
    }
  };

  initFlexicutService = (args: FlexicutComputationInitialisationVariables) => {
    this._fcService.initialiseFlexicutService(args);
    this.couponStore.updateFlexicutServiceInitialised(true);
  };

  updateFlexicutSelectedOptionByCutNumber = (updatedCutNumber: number) => {
    const mapOptionId = {
      1: 'option_1',
      2: 'option_2',
    };
    const optionId = updatedCutNumber && (mapOptionId[updatedCutNumber] ? mapOptionId[updatedCutNumber] : 'option_3');
    const selectedOption = this.couponQuery.getFlexicutSelectedOption();
    if (!(selectedOption && selectedOption.optionId === optionId && selectedOption.cutNumber === updatedCutNumber)) {
      this.couponStore.updateFlexicutSelectedOption({
        optionId,
        cutNumber: updatedCutNumber,
      });
    }
  };

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private initialise(): void {
    // Turn off flexicut is logged in with invalid user
    combineLatest([this.accountQuery.isAuthenticated$, this.couponQuery.isFlexicutCoupon$])
      .pipe(
        filter(
          ([isAuth, isFlexicutCoupon]) =>
            isAuth &&
            !(this.appConfigService.get('sports').coupon.flexicutWhitelistedUserTypes as string[]).includes(
              this.accountQuery.userData.userTypeCode
            ) &&
            isFlexicutCoupon
        ),
        tap(() => this.couponStore.updateFlexicutSelectedOption(undefined))
      )
      .subscribe();

    this.couponQuery.flexicutSelectedOption$
      .pipe(
        tap(selectedFlexicut => {
          if (this.couponQuery.hasFlexicutOdds) {
            const cutNumber = selectedFlexicut?.cutNumber ? selectedFlexicut.cutNumber : undefined;
            const flexicutOddValue = selectedFlexicut?.cutNumber ? this.couponQuery.getFlexicutOddForACutNumber(cutNumber) : undefined;
            this.toggleFlexicut(cutNumber, flexicutOddValue);
          }
        })
      )
      .subscribe();
  }

  readonly sendApplyFlexicutEvent = (cutNumber: number) => {
    const selections = this.couponQuery.couponData?.Odds.length;
    this.dataLayerService.createDataLayerEvent({
      event: 'apply_flexicut',
      cut_selections: cutNumber,
      selections,
    });
  };

  private readonly toggleFlexicut = (cutNumber: number, flexicutOddValue: number) => {
    const updateCouponResponse = this.clientsideCouponService.updateFlexicutCoupon(
      new UpdateFlexicutCouponRequest({
        action: CouponAction.ToggleFlexicut,
        brandID: this.appConfigService.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        flexicutOddValue,
        cutNumber,
      })
    );
    this.couponStore.updateCouponData(updateCouponResponse.updatedCoupon);
    if (!updateCouponResponse.success) {
      this.notificationService.showErrorNotification(UPDATE_COUPON_STATUS_CODES[updateCouponResponse.statusCode]);
    }
  };
}
