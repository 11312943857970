import { Injectable } from '@angular/core';

import { CouponStore } from 'src/app/core/state/coupon/coupon.store';

import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BetCouponGroup, CouponAction, UpdateCouponRequest, UpdateCouponResponse } from 'clientside-coupon';
import { CouponGroupingType } from 'src/app/shared/models/coupon.model';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ClientsideCouponWrapperService } from 'src/app/core/services/coupon/clientside-coupon-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class CouponGroupingsService {
  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfigService: AppConfigService,
    private readonly clientsideCouponWrapperService: ClientsideCouponWrapperService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore
  ) {}

  updateGroupingsTab(groupingType: CouponGroupingType): void {
    const groupUpdates: BetCouponGroup[] = [];

    this.couponQuery.couponData.AllGroupings.forEach(group => {
      if (!group.Selected) {
        return;
      }

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = false;

      groupUpdates.push(newGrouping);
    });

    if (groupingType === CouponGroupingType.Multiple || groupingType === CouponGroupingType.Split) {
      const lastGroup = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = lastGroup.Grouping;
      newGrouping.Combinations = lastGroup.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Singles) {
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = singlesGrouping.Grouping;
      newGrouping.Combinations = singlesGrouping.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Combination) {
      const group = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 2];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    }

    if (groupUpdates.length > 0) {
      this.updateGroupings(groupUpdates);
    }
  }

  isGroupingTypeVisible(groupingButtonType: CouponGroupingType): boolean {
    if (this.couponQuery.couponData) {
      const lastGrouping = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      if (groupingButtonType === CouponGroupingType.Multiple) {
        if (lastGrouping.Combinations === 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Split) {
        if (lastGrouping !== singlesGrouping && lastGrouping.Combinations > 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Singles) {
        if (singlesGrouping !== null) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Combination) {
        if (this.couponQuery.couponData.AllGroupings.filter(g => g.Grouping !== 1).length > 1) {
          return true;
        }
      }
    }
    return false;
  }

  setGroupingTab(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      this.couponStore.updateGroupingTab(groupingType);
    }
  }

  isCouponGroupVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.couponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupings,
        brandID: this.appConfigService.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: groupings,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }
}
