import { ChangeDetectionStrategy, Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ClientsideCouponService, UpdateFlexicutCouponRequest, CouponAction } from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'coupon-flexicut-option',
  templateUrl: './flexicut-option.component.html',
  styleUrls: ['./flexicut-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexicutOptionComponent implements OnInit, OnDestroy {
  @Input() decreaseThreshold: number = undefined;
  @Input() selected: boolean = false;
  @Input() isCustomized = false;
  @Input() id: string;
  @Output() readonly optionClick = new EventEmitter();

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly selectedOption$ = this.couponQuery.flexicutSelectedOption$.pipe(map(data => data));
  readonly showTotalOddsInfoText$ = new BehaviorSubject(false);

  readonly cutNumber$ = new BehaviorSubject<number>(0);
  readonly cannotDecreaseCut$ = new BehaviorSubject(false);
  readonly cannotIncreaseCut$ = new BehaviorSubject(false);

  readonly maxSelections$ = this.couponQuery.couponData$.pipe(
    filter(couponData => !!couponData),
    map(couponData => couponData.Odds.length)
  );

  readonly winningSelections$ = combineLatest([this.cutNumber$, this.maxSelections$]).pipe(
    map(([cutNumber, maxSelections]) => maxSelections - cutNumber)
  );

  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfigService: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponQuery: CouponQuery
  ) {}

  get potentialWinning(): number {
    const cutNumber = this.cutNumber$.getValue();
    const flexicutOddValue = this.couponQuery.getFlexicutOddForACutNumber(cutNumber);

    if (!flexicutOddValue) {
      return 0;
    }

    const updateCouponResponse = this.clientsideCouponService.updateFlexicutCoupon(
      new UpdateFlexicutCouponRequest({
        action: CouponAction.UpdateFlexicutNumber,
        brandID: this.appConfigService.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        flexicutOddValue,
        cutNumber,
      })
    );

    return updateCouponResponse.updatedCoupon.MaxWinNet;
  }

  @Input() set cutNumber(value: number) {
    this.cutNumber$.next(value);
  }

  ngOnInit(): void {
    // Increase cut value check
    combineLatest([this.winningSelections$, this.couponQuery.flexicutOdds$, this.maxSelections$])
      .pipe(
        filter(([_winningSelections, fcOdds, maxSelections]) => fcOdds.length > 0 && maxSelections > 0),
        map(([winningSelections, fcOdds, maxSelections]) => winningSelections <= maxSelections - fcOdds.length)
      )
      .subscribe(state => {
        this.cannotIncreaseCut$.next(state);
      });

    // Decrease cut value check
    combineLatest([this.winningSelections$, this.maxSelections$, this.cutNumber$])
      .pipe(
        map(
          ([winningSelections, maxSelections, cutNumber]) => winningSelections >= maxSelections - 1 || cutNumber <= this.decreaseThreshold
        )
      )
      .subscribe(state => {
        this.cannotDecreaseCut$.next(state);
      });
  }

  onOptionSelected(): void {
    const isSelected = !this.selected;
    if (isSelected) {
      this.optionClick.emit({
        optionId: this.id,
        cutNumber: this.cutNumber$.getValue(),
      });
    } else {
      this.optionClick.emit(undefined);
    }
    this.showTotalOddsInfoText$.next(false);
  }

  increaseCut = () => {
    if (this.cannotIncreaseCut$.value) {
      this.showTotalOddsInfoText$.next(true);
    } else {
      this.cutNumber$.next(this.cutNumber$.getValue() + 1);
    }

    if (this.selected) {
      this.optionClick.emit({
        optionId: this.id,
        cutNumber: this.cutNumber$.getValue(),
      });
    }
  };

  reduceCut = () => {
    this.showTotalOddsInfoText$.next(false);
    if (!this.cannotDecreaseCut$.value) {
      this.cutNumber$.next(this.cutNumber$.getValue() - 1);
    }

    if (this.selected) {
      this.optionClick.emit({
        optionId: this.id,
        cutNumber: this.cutNumber$.getValue(),
      });
    }
  };

  ngOnDestroy(): void {
    this.showTotalOddsInfoText$.next(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
