import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { BetCoupon, CouponType } from 'clientside-coupon';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { expandCollapseSimpler, fadeInOutDelay } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-coupon-totals',
  templateUrl: './coupon-totals.component.html',
  styleUrls: ['./coupon-totals.component.scss'],
  animations: [fadeInOutDelay(), expandCollapseSimpler()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponTotalsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() couponData: BetCoupon;
  @Input() bookedCoupon: boolean = false;
  @Input() bookedCouponCode: string;
  @Input() bookedCouponDate: string;

  private readonly couponConfig = this.appConfigService.get('sports').coupon;
  private readonly currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  freeBetProductType: typeof FreeBetProductType = FreeBetProductType;
  currencySymbol: string;
  stakeGrossDisplay: string;
  fixedStakes: number[];
  resetButtonStyle: any = {
    height: '34px',
    padding: '0px 2px 0px 5px',
    minWidth: '0',
    textAlign: 'center',
  };
  taxPercentageToShow = this.couponConfig.stakeTaxPercentageToShow;

  readonly winningsInfoBubble$ = new BehaviorSubject<boolean>(false);
  readonly firstStakePress$ = new BehaviorSubject<boolean>(true);
  readonly winningDetailsExpanded$ = new BehaviorSubject(false);
  readonly stakeGrossDisplay$ = new BehaviorSubject<string>('');

  readonly selectedFreebetVoucher$ = this.freebetQuery.selectedFreebetVoucher$(FreeBetProductType.SportsBook);
  readonly hasSelectedFreebetVoucher$ = this.freebetQuery.hasSelectedFreebetVoucher$(FreeBetProductType.SportsBook);
  readonly isFlexicutCoupon$ = this.couponQuery.isFlexicutCoupon$;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly appConfigService: AppConfigService,
    private readonly dataLayerService: DataLayerService,
    private readonly notificationService: NotificationService,
    private readonly ngNavigatorShareService: NgNavigatorShareService,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly freebetQuery: FreebetsQuery,
    private readonly clipboard: Clipboard,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly couponQuery: CouponQuery
  ) {}

  ngOnInit(): void {
    this.fixedStakes = this.couponConfig.fixedStakes;
    this.currencySymbol = this.currencyService.getCurrency(this.applicationQuery.currency).symbol;
    this.stakeGrossDisplay$.next(this.couponData.StakeGross.toString());
    this.couponQuery.defaultCouponStake$.subscribe(defaultCouponStake => {
      if (defaultCouponStake && defaultCouponStake.allowSaveDefault) {
        this.couponStakeHandlerService.updateStakeValue(defaultCouponStake.defaultStake);
        this.stakeGrossDisplay$.next(defaultCouponStake.defaultStake.toString());
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.couponData && changes.couponData.previousValue?.StakeGross !== changes.couponData.currentValue.StakeGross) {
      this.stakeGrossDisplay$.next(this.couponData.StakeGross.toString());
    }
    this.winningsInfoBubble$.next(
      this.couponConfig.winningsInfoBubble &&
        ((this.couponData.TurnoverTax > 0 &&
          this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled &&
          this.couponData.CouponType !== this.couponType.System) ||
          (this.couponData.MinOdd > 0 && this.couponData.MinOdd !== this.couponData.MaxOdd))
    );

    // If info bubble is expanded, check whether we are still using it to see if we should keep it open
    this.winningDetailsExpanded$.next(this.winningDetailsExpanded$.getValue() ? this.winningsInfoBubble$.getValue() : false);
  }

  resetStakeValue(): void {
    // reset stake to 0
    this.updateStakeValue(0);

    this.firstStakePress$.next(true);
  }

  addStakeValue(amount: string): void {
    let stakeAmount;

    if (this.firstStakePress$.getValue() && amount.toString() !== this.couponQuery.globalVariables.MinBetStake.toString()) {
      stakeAmount = parseFloat(amount);
    } else {
      stakeAmount = this.couponData.StakeGross + parseFloat(amount);
    }

    this.updateStakeValue(stakeAmount);
    this.firstStakePress$.next(false);
  }

  updateInputStakeValue(event: any): void {
    event.preventDefault();
    if (!event.currentTarget.value) {
      event.currentTarget.value = 0;
    }
    this.updateStakeValue(event.currentTarget.value);
  }

  copyBookedBet(): void {
    const bookingCode = this.bookedCouponCode;

    this.clipboard.copy(bookingCode);
    this.dataLayerService.createDataLayerEvent({
      event: 'booking-code-copy',
      user_id: this.accountQuery.userData?.id,
      booking_code: bookingCode,
    });

    this.notificationService.showInfoMessage($localize`Booking code copied to clip board`, 2000, false);
  }

  shareBookedBet(): void {
    if (!this.ngNavigatorShareService.canShare()) {
      const message = $localize`This service is not supported in your browser!`;
      this.notificationService.showErrorMessage(message);
      return;
    }

    const bookingCode = this.bookedCouponCode;
    const selection = this.couponData.Odds.length;
    const stake = this.currencyFormatPipe.transform(this.couponData.StakeGross);
    const potentialWin = this.currencyFormatPipe.transform(this.couponData.MaxWinNet);

    this.ngNavigatorShareService
      .share({
        title: $localize`Check out my BetKing bet! `,
        text: $localize`Check out my ${selection} selection bet! \nStake: ${stake} \nPotential Win: ${potentialWin}\n`,
        url: `/book-bet/${bookingCode}`,
      })
      .then(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'booking-code-share',
          user_id: this.accountQuery.userData?.id,
          booking_code: bookingCode,
        });
      });
  }

  toggleWinningsDetails(): void {
    this.winningDetailsExpanded$.next(!this.winningDetailsExpanded$.value);
  }

  hideInfoBubble(): void {
    this.winningDetailsExpanded$.next(false);
  }

  indexTrackBy(index: number): number {
    return index;
  }

  scrollToBottom(): void {
    window.scrollTo(0, window.document.body.scrollHeight);
  }

  private updateStakeValue(stakeValue: number): void {
    this.couponStakeHandlerService.updateStakeValue(stakeValue);
    this.stakeGrossDisplay = stakeValue.toString();

    if (this.couponQuery.defaultCouponStake?.allowSaveDefault) {
      this.couponStakeHandlerService.updateDefaultCouponStake(
        new DefaultCouponStake({
          allowSaveDefault: true,
          defaultStake: this.couponQuery.couponData.StakeGross,
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.stakeGrossDisplay$.next('');
  }
}
