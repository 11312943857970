import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BetCoupon } from 'clientside-coupon';
import { BetCouponFreebetDetails } from 'clientside-coupon/lib/clientside-coupon.model';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { VirtualsCouponStakeHandlerService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-stake-handler.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';
import {
  FreeBetProductType,
  FreebetsBetslipContent,
  FreebetsConfiguration,
  FreebetsMyBetsContent,
  FreebetsState,
  FreebetsVoucher,
} from 'src/app/modules/freebets/models/freebets.model';

const DEFAULT_BETSLIP_FREEBETCONTENT: FreebetsBetslipContent = {
  singleFreebetAvailable: $localize`You have 1 Free Bet`,
  multipleFreebetsAvailable: $localize`You have {0} Free Bets`,
  modalHeaderTitle: $localize`Your Offers`,
  modalSubHeaderDescription: $localize`Free Bets are only valid for Single and Multiple. Winnings from a free bet will not include the stake.`,
  modalSubHeaderTitle: $localize`FreeBets Available`,
  tcLabel: $localize`T&Cs`,
  tcUrl: $localize`/help`,
  freebetApplied: $localize`Free Bet applied`,
};

const DEFAULT_MYBETS_FREEBETCONTENT: FreebetsMyBetsContent = {
  freebetApplied: $localize`Free Bet applied`,
  freebetsStakeDisclaimer: $localize`Stake not included in potential winnings`,
};

function createInitialState(): FreebetsState {
  return {
    sportsBook: {
      betslipContentCallDone: false,
      configCallDone: false,
      freeBetsBetslipContent: DEFAULT_BETSLIP_FREEBETCONTENT,
      freeBetsConfig: {
        allowFreeBets: false,
        allowOnMultipleBets: false,
        allowOnSingleBets: false,
        allowOnSystemBets: false,
      },
      freeBetsMyBetsContent: DEFAULT_MYBETS_FREEBETCONTENT,
      mybetsContentCallDone: false,
      vouchers: [],
      voucherCallIsLoading: false,
      lastTimeVouchersHaveBeenRetrieved: 0,
    },
    virtualsScheduled: {
      betslipContentCallDone: false,
      configCallDone: false,
      freeBetsBetslipContent: DEFAULT_BETSLIP_FREEBETCONTENT,
      freeBetsConfig: {
        allowFreeBets: false,
        allowOnMultipleBets: false,
        allowOnSingleBets: false,
        allowOnSystemBets: false,
      },
      freeBetsMyBetsContent: DEFAULT_MYBETS_FREEBETCONTENT,
      mybetsContentCallDone: false,
      vouchers: [],
      voucherCallIsLoading: false,
      lastTimeVouchersHaveBeenRetrieved: 0,
    },
    activeProduct: FreeBetProductType.VirtualsScheduled,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'freebets' })
export class FreebetsStore extends Store<FreebetsState> {
  constructor(
    private readonly couponQuery: CouponQuery,
    private readonly virtualScheduledCouponQuery: VirtualsCouponQuery,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly virtualsCouponStakeHandlerService: VirtualsCouponStakeHandlerService,
    private readonly couponStore: CouponStore,
    private readonly virtualScheduledCouponStore: VirtualsCouponStore
  ) {
    super(createInitialState());
  }

  setActiveFreeBetProduct = (activeProduct: FreeBetProductType): void => {
    this.update(state => ({ ...state, activeProduct }));
  };

  updateFreebetsConfiguration = (productType: FreeBetProductType, freeBetsConfig: FreebetsConfiguration): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], freeBetsConfig } }));
  };

  updateBetslipContent = (productType: FreeBetProductType, freeBetsBetslipContent: FreebetsBetslipContent): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], freeBetsBetslipContent } }));
  };
  updateMyBetsContent = (productType: FreeBetProductType, freeBetsMyBetsContent: FreebetsMyBetsContent): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], freeBetsMyBetsContent } }));
  };

  updateFreebetsVouchers = (productType: FreeBetProductType, vouchers: FreebetsVoucher[]): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], vouchers } }));
  };

  updateCouponDataSelectedFreebetVouchers = (productType: FreeBetProductType, voucher?: FreebetsVoucher): void => {
    let freebetDetails: BetCouponFreebetDetails = undefined;
    let stake: number;
    let couponData: BetCoupon;
    let couponStore;
    let stakeHandlerService;

    if (productType === FreeBetProductType.SportsBook) {
      couponData = this.couponQuery.couponData;
      couponStore = this.couponStore;
      stakeHandlerService = this.couponStakeHandlerService;
    } else if (productType === FreeBetProductType.VirtualsScheduled) {
      couponData = this.virtualScheduledCouponQuery.couponData;
      couponStore = this.virtualScheduledCouponStore;
      stakeHandlerService = this.virtualsCouponStakeHandlerService;
    }

    if (voucher) {
      freebetDetails = {
        code: voucher.code,
        name: voucher.name,
        type: voucher.reward.rewardTypeCode,
      } as BetCouponFreebetDetails;
      stake = voucher.reward.remainingValue;
    }
    if (couponData) {
      couponStore.updateCouponData({
        ...couponData,
        BetDetails: {
          ...couponData.BetDetails,
          FreeBetDetails: freebetDetails,
        },
      } as BetCoupon);
    }

    // Update coupon stake
    if (stake) {
      stakeHandlerService.updateStakeValue(stake);
    }
  };

  updateConfigCallDone = (productType: FreeBetProductType, configCallDone: boolean): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], configCallDone } }));
  };

  updateBetslipContentCallDone = (productType: FreeBetProductType, betslipContentCallDone: boolean): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], betslipContentCallDone } }));
  };

  updateMyBetsContentCallDone = (productType: FreeBetProductType, mybetsContentCallDone: boolean): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], mybetsContentCallDone } }));
  };

  updateVoucherCallIsLoading = (productType: FreeBetProductType, voucherCallIsLoading: boolean): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], voucherCallIsLoading } }));
  };

  updateLastTimeVouchersHaveBeenRetrieved = (productType: FreeBetProductType, lastTimeVouchersHaveBeenRetrieved: number): void => {
    this.update(state => ({ ...state, [productType]: { ...state[productType], lastTimeVouchersHaveBeenRetrieved } }));
  };
}
