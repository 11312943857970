<div class="menu-container">
  <app-menu-tabs></app-menu-tabs>
  <div class="content-wrapper">
    <ng-container [ngSwitch]="menuQuery.selectedTab$ | async">
      <app-menu-sports *ngSwitchCase="menuTab.SportsAZ"></app-menu-sports>
      <app-menu-promos *ngSwitchCase="menuTab.Promos"></app-menu-promos>
      <virtuals-game-lobby *ngSwitchCase="menuTab.Virtuals" [showPageTitle]="false"
        [showInfoBubble]="false" [showHeader]="false"></virtuals-game-lobby>
      <app-menu-quicklinks *ngSwitchCase="menuTab.QuickLinks"></app-menu-quicklinks>
      <app-menu-how-to-play *ngSwitchCase="menuTab.HowToPlay"></app-menu-how-to-play>
    </ng-container>
  </div>
</div>
