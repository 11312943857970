import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SelectionDescriptionService {
  /**
   * Parse odd's selection description, to include event specific information
   *
   * @param eventName Event name
   * @param selectionDescription Selection Description to parse
   */
  readonly parseSelectionDescription = (eventName: string, selectionDescription: string): string => {
    if (!!eventName && !!selectionDescription) {
      let parsedSelectionDescription = '';
      const startIndexofPlayer = eventName.lastIndexOf('[');
      const eventNameWithoutPlayer = eventName.slice(0, startIndexofPlayer !== -1 ? startIndexofPlayer : undefined).trim();
      const eventTeams = eventNameWithoutPlayer.split('-');

      // Player
      parsedSelectionDescription = selectionDescription.replace(
        '{{PLAYER}}',
        eventName.includes('[') ? eventName.slice(startIndexofPlayer + 1, eventName.lastIndexOf(']')) : ''
      );
      // Home
      parsedSelectionDescription = parsedSelectionDescription.replace('{{HOME}}', eventTeams[0].trim());
      // Away
      parsedSelectionDescription = parsedSelectionDescription.replace('{{AWAY}}', eventTeams[1].trim());

      return parsedSelectionDescription;
    } else {
      return '';
    }
  };
}
