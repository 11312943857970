<div class="user-header-container">
  <header-bk-user-balance></header-bk-user-balance>
  <a href="/message-center">
    <button class="message-centre">
      <span
        *ngIf="showMessageCountBadge$ | async"
        [appBadge]="messagesCount$ | async"
        [badgeSize]="16"
        [rightAdjustment]="0"
        [topAdjustment]="0"
        badgeClass="badge-red"
      ></span>
      <span class="material-icons message-centre-icon">{{ 'mail' }}</span>
    </button>
  </a>
  <a href="/account">
    <button class="account">
      <span *ngIf="menuItemProfileStatus()" class="indicator"></span>
      <span class="material-icons account-icon">{{ 'person' }}</span>
    </button>
  </a>
</div>
