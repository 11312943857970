import { NgModule } from '@angular/core';
import { HeaderModule } from 'src/app/modules/header/header.module';
import { VirtualsAlertComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-alert/virtuals-alert.component';
import { VirtualsGameLobbyComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-game-lobby/virtuals-game-lobby.component';
import { VirtualsInfoSectionComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-info-section/virtuals-info-section.component';
import { VirtualsInfoSubSectionComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-info-section/virtuals-info-sub-section/virtuals-info-sub-section.component';
import { VirtualsSlideUpComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-slide-up/virtuals-slide-up.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VirtualsJackpotTooltipComponent } from './components/virtuals-jackpot-tooltip/virtuals-jackpot-tooltip.component';
import { VirtualsJackpotBannerItemComponent } from './components/virtuals-jackpot-banner/jackpot-banner-item/virtuals-jackpot-banner-item.component';
import { VirtualsJackpotBannerComponent } from './components/virtuals-jackpot-banner/virtuals-jackpot-banner.component';

@NgModule({
  declarations: [
    VirtualsGameLobbyComponent,
    VirtualsSlideUpComponent,
    VirtualsInfoSectionComponent,
    VirtualsInfoSubSectionComponent,
    VirtualsAlertComponent,
    VirtualsJackpotTooltipComponent,
    VirtualsJackpotBannerItemComponent,
    VirtualsJackpotBannerComponent,
  ],
  imports: [HeaderModule, SharedModule],
  exports: [
    VirtualsGameLobbyComponent,
    VirtualsSlideUpComponent,
    VirtualsInfoSectionComponent,
    VirtualsAlertComponent,
    VirtualsJackpotTooltipComponent,
    VirtualsJackpotBannerItemComponent,
    VirtualsJackpotBannerComponent,
  ],
})
export class VirtualsWidgetsModule {}
