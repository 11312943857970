import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-odd-group',
  templateUrl: './odd-group.component.html',
  styleUrls: ['./odd-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OddGroupComponent {
  @Input() odds: OddModel[];
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() isLive: boolean = false;
  @Input() readonly itemIndex: number;
  @Input() readonly showSelectionName: boolean = false;
  // Input for styling
  @Input() haveSpreadSelector = false;

  idTrackBy(index: number, odd: OddModel): number {
    return odd ? odd.id : index;
  }
}
