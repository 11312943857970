import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { OTPInputFieldComponent } from 'src/app/shared/components/otp/otp-input-field/otp-input-field.component';
import { ButtonType } from 'src/app/shared/models/button.model';
import { OTPScreenContentModel } from 'src/app/shared/models/otp.model';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { debounce } from 'lodash-es';

@Component({
  selector: 'app-otp-screen',
  templateUrl: './otp-screen.component.html',
  styleUrls: ['./otp-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OTPScreenComponent implements OnInit, OnDestroy {
  @Input() content: OTPScreenContentModel;
  // To be used only for Registration or Reset Password
  // when user may not be logged in and needs OTP on his username, if he has a verified mobile number
  @Input() username: string;
  // If the username is present, the mobile prefix and mobile number is ignored
  @Input() mobilePrefix: string;
  @Input() mobileNumber: string;
  @Input() genericError = false;
  @Input() otpOption: number;
  @Input() interval: NodeJS.Timeout;
  @Input() timeLeft$: Observable<number>;
  // This will be used once the OTP screen will be implemented for when an unverified user logs in and is shown the OTP process.
  @Input() otpOptionAfterResend: number = undefined;
  @ViewChild(OTPInputFieldComponent) otpInputField: OTPInputFieldComponent;
  @Output() readonly postOTP = new EventEmitter();
  @Output() readonly resendCodeOutput = undefined;
  @Output() readonly codeResent = new EventEmitter();
  @Output() readonly startLoading = new EventEmitter();
  @Output() readonly stopLoading = new EventEmitter();

  buttonType = ButtonType;
  inputOTPMessage: string[];
  inputOTPMessageHasUserPhoneNumber: boolean;
  inputOTPMessageHasUserUsername: boolean;
  invalidCode$ = new BehaviorSubject<boolean>(false);
  tooManyRequests$ = new BehaviorSubject<boolean>(false);
  OneTimePasswordVerificationExceeded$ = new BehaviorSubject<boolean>(false);
  limitExceeded$ = new BehaviorSubject<boolean>(false);
  noOfAttempts = 1;
  otpCodeLength = this.appConfig.get('otp').otpCodeLength;
  otpAllowResendWithoutTimer = this.appConfig.get('otp').otpAllowResendWithoutTimer;
  resendTimer = this.appConfig.get('otp').otpResendTimer;
  verifyingCode$ = new BehaviorSubject<boolean>(false);
  mobilePrefixHasPlusSymbol: boolean;
  timerValue: number | null = null;
  timer: any = null;
  isEnableOtpOnCall = this.appConfig.get('enableOTPOnCall');
  isNigeria: boolean = this.applicationQuery.isBrand(['Nigeria']);
  debouncedCallToOTP: () => void;
  initialTimerValue: number = 59;
  ctaAdditionalStylesConfirmation = {
    fontSize: '14px',
  };

  ctaAdditionalStylesOld = {
    fontSize: '14px',
    minWidth: '110px',
    height: '40px',
  };

  ctaAdditionalStyles = {
    fontSize: '14px',
    textDecoration: 'underline',
    height: '25px',
    width: '80%',
    marginTop: '10px',
  };
  ctaVoiceAdditionalStyles = {
    fontSize: '14px',
    border: '1px solid',
    borderRadius: '5px',
    height: '25px',
    width: '80%',
  };

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly hasResentCode$ = new BehaviorSubject(false);

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly notificationService: NotificationService,
    private readonly ref: ChangeDetectorRef,
    readonly applicationQuery: ApplicationQuery,
    private readonly mobileNumberInputService: MobileNumberInputService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.initialiseInputOTPView();
    this.startTimer(this.initialTimerValue);
    this.debouncedCallToOTP = debounce(() => this.callOnPhone(), 1000);
    /*
      There might be a case with a LoginType of UsernameOrMobile
      that the user submits a full number (prefix wihtout + & mobile number),
      which is then accepted under the criteria of a username.
      This is still a valid user, as at BE level, the username will be constructed from the mobileprefix (without +),
      and the mobile number
      In this case, we need to format what we use in the OTP process to cater for this, and avoid having duplicate prefixes.
    */
    if (!this.username) {
      // check mobile Number if it has '0' in front
      this.mobileNumber = this.mobileNumberInputService.mobileNumberCleanup(this.mobileNumber);

      this.mobileNumber =
        !this.mobileNumber.match(this.appConfig.get('registration').mobileRegex) &&
        this.mobileNumber.startsWith(this.mobilePrefix.replace('+', ''))
          ? this.mobileNumber.replace(this.mobilePrefix.replace('+', ''), '')
          : this.mobileNumber;
    }

    this.verifyingCode$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (status) {
        this.startLoading.emit();
      } else {
        this.stopLoading.emit();
      }
    });
  }
  private startTimer(counter: number) {
    this.timerValue = counter;
    clearTimeout(this.timer);
    this.timer = null;
    this.timer = setInterval(() => {
      --this.timerValue;
      this.ref.detectChanges();
      if (this.timerValue <= 0) {
        clearTimeout(this.timer);
        this.timer = 0;
        this.ref.detectChanges();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  callOnPhone(): void {
    if (this.timer) {
      return;
    }
    const body: any = {
      Username: this.username || `${this.mobilePrefix.replace('+', '')}${this.mobileNumber}`,
    };

    // clear previous error statuses
    this.tooManyRequests$.next(false);
    this.limitExceeded$.next(false);
    this.OneTimePasswordVerificationExceeded$.next(false);

    this.accountService
      .regenerateTokenForCallOTP(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.startTimer(this.initialTimerValue);
          this.hasResentCode$.next(true);
          this.codeResent.emit();
        },
        error: error => {
          if (error.error.ResponseCode === 20805) {
            this.limitExceeded$.next(true);
          } else if (error.error.ResponseCode === 20810) {
            this.tooManyRequests$.next(true);
          } else {
            this.notificationService.showErrorNotification($localize`An error occurred during PIN generation. Please try again later.`);
          }
        },
      });
  }
  resendCode(): void {
    if (this.timer && this.isEnableOtpOnCall) {
      return;
    }
    const body: any = {
      RequestTypeOption: this.otpOption,
      Data: {},
    };

    switch (this.otpOption) {
      case 2: // Reset Password
      case 3: // Registration
        body.Data = {
          Username: this.username || `${this.mobilePrefix.replace('+', '')}${this.mobileNumber}`,
        };
        break;
      case 4: // Change Phone Number
      case 5: // Verify Phone Number
        body.Data = {
          UserId: this.accountQuery.userData.id,
          Mobile: `${this.mobilePrefix}${this.mobileNumber}`,
        };
        break;
      default:
        break;
    }

    // clear previous error statuses
    this.tooManyRequests$.next(false);
    this.limitExceeded$.next(false);
    this.OneTimePasswordVerificationExceeded$.next(false);

    this.accountService
      .regenerateTokenForService(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.startTimer(this.initialTimerValue);
          this.hasResentCode$.next(true);
          this.codeResent.emit();
        },
        error: error => {
          if (error.error.ResponseCode === 20805) {
            this.limitExceeded$.next(true);
          } else if (error.error.ResponseCode === 20810) {
            this.tooManyRequests$.next(true);
          } else {
            this.notificationService.showErrorNotification($localize`An error occurred during PIN generation. Please try again later.`);
          }
        },
      });
  }

  verifyCode(): void {
    if (this.otpInputField.code$.value) {
      const username = this.accountQuery.isAuthenticated
        ? this.accountQuery.userData.username
        : this.username || `${this.mobilePrefix.replace('+', '')}${this.mobileNumber}`;
      this.verifyingCode$.next(true);
      if (this.isNigeria) {
        this.dataLayerService.createDataLayerEvent({
          event: 'registration-submitted',
          stepname: 'OTP submitted',
        });
      }

      this.accountService
        .validateOneTimePassword(
          username,
          this.otpInputField.code$.value,
          this.hasResentCode$.value && this.otpOptionAfterResend !== undefined ? this.otpOptionAfterResend : this.otpOption
        )
        .pipe(
          finalize(() => {
            this.verifyingCode$.next(false);
            this.otpInputField.clearCode();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(
          () => {
            if (this.isNigeria) {
              this.dataLayerService.createDataLayerEvent({
                event: 'registration-success',
                use_id: window.localStorage.newUserId,
              });
              delete window.localStorage.newUserId;
            }
            // Success
            this.postOTP.emit({
              username,
              otpCode: this.otpInputField.code$.value,
            });
          },
          error => {
            if (this.isNigeria) {
              this.dataLayerService.createDataLayerEvent({
                event: 'registration-failure',
                error_message: error.error.ResponseMessage,
              });
            }
            // Error
            if (error.error.ResponseCode === 20814 && this.isEnableOtpOnCall) {
              this.OneTimePasswordVerificationExceeded$.next(true);
              this.noOfAttempts = error.error.noOfAttempts;
            } else {
              this.invalidCode$.next(true);
            }
          }
        );
    } else {
      this.invalidCode$.next(true);
    }
  }

  private initialiseInputOTPView(): void {
    if (this.content) {
      this.inputOTPMessageHasUserPhoneNumber = this.content.sentOTPMessage.includes('[[User_PhoneNumber]]');
      this.inputOTPMessageHasUserUsername = this.content.sentOTPMessage.includes('[[User_Username]]');
      this.inputOTPMessage = this.inputOTPMessageHasUserPhoneNumber
        ? this.content.sentOTPMessage.split('[[User_PhoneNumber]]')
        : this.inputOTPMessageHasUserUsername
        ? this.content.sentOTPMessage.split('[[User_Username]]')
        : (this.inputOTPMessage = [this.content.sentOTPMessage]);
    }

    this.mobilePrefixHasPlusSymbol = this.mobilePrefix.includes('+');
  }
}
