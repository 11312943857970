import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BestSellerOddModel } from 'src/app/shared/models/coupon.model';
@Component({
  selector: 'coupon-empty-odd',
  templateUrl: './coupon-empty-odd.component.html',
  styleUrls: ['./coupon-empty-odd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponEmptyOddComponent {
  private _odd: BestSellerOddModel;

  get odd(): BestSellerOddModel {
    return {
      ...this._odd,
      SelectionName: this.parseSelectionName(this._odd.SelectionName, this._odd.HomeTeam, this._odd.AwayTeam),
      isBoosted: this._odd.isBoosted,
    };
  }

  @Input() set odd(value: BestSellerOddModel) {
    this._odd = value;
  }

  private parseSelectionName(selectionName: string, homeTeam: string, awayTeam: string): string {
    switch (selectionName.toLowerCase().trim()) {
      case '1':
        return homeTeam ? homeTeam : selectionName;
      case 'x':
        return $localize`Draw`;
      case '2':
        return awayTeam ? awayTeam : selectionName;
      default:
        return selectionName;
    }
  }
}
