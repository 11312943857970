import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  constructor(private readonly appConfigService: AppConfigService, private readonly accountQuery: AccountQuery) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.data.externalUrl) {
      // backwards compatibility for old implementations
      window.location.href = `${route.data.externalUrl}${window.location.search}`;
    } else {
      // new implementation which supports deep paths
      const trafficPercentage =
        (route.data.slowRollout?.percentageConfigKey &&
          this.appConfigService.get('sports').slowRollout?.[route.data.slowRollout.percentageConfigKey]) ||
        0;
      const redirectUrl = this.getRedirectUrl(route);
      const [redirectPath, redirectParams] = redirectUrl.split('?');

      const continueAsNormal = () => {
        if (route.data.stayOnCurrentPath) {
          // Do not redirect to handle current path
          return true;
        }

        window.location.href = `${window.location.origin}${route.data.slowRollout?.currentPath || redirectPath}${this.getQueryParams(
          redirectParams
        )}`;
      };

      if (trafficPercentage) {
        // Slow rollout logic
        const proceedToVariant =
          trafficPercentage === 100 || (this.accountQuery.isAuthenticated && this.accountQuery.userData.id % 100 < trafficPercentage);
        if (proceedToVariant) {
          window.location.href = `${window.location.origin}${this.liveRoutingSubPathPersistence(route, state)}${this.getQueryParams(
            redirectParams
          )}`;
        } else {
          return continueAsNormal();
        }
      } else {
        return continueAsNormal();
      }
    }
    return false;
  }

  private getRedirectUrl(route: ActivatedRouteSnapshot): string {
    const url = `/${route.pathFromRoot
      .map(snapshot => snapshot.url.map(segment => segment.toString()).join('/'))
      .filter(segment => Boolean(segment))
      .join('/')}`;
    return decodeURIComponent(url);
  }

  private getQueryParams(redirectParams: string = ''): string {
    const queryParams = new URLSearchParams(`${window.location.search}&${redirectParams}`);
    // remove the queryparams which are used to initiate the redirect
    queryParams.delete('returnUrl');
    queryParams.delete('urlAfterLogin');

    return queryParams.toString() ? `?${queryParams.toString()}` : '';
  }

  /**
   * Need additional logic for live routes to be able to persist event routing
   */
  private liveRoutingSubPathPersistence(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    if (route.data.isLive) {
      const pathWithoutQueryStrings = state.url.split('?')[0];
      const path = (pathWithoutQueryStrings[0] === '/' ? pathWithoutQueryStrings.slice(1) : pathWithoutQueryStrings).split('/');
      if (path[0] === 'live' && path.length > 1) {
        // Persist event id
        return `${route.data.slowRollout?.variantPath}/${path[1]}`;
      }
    }
    return route.data.slowRollout?.variantPath;
  }
}
