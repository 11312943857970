import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() readonly clickOutside = new EventEmitter<MouseEvent>();
  constructor(private readonly _eref: ElementRef) {}
  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement) {
    if (targetElement && document.body.contains(targetElement) && !this._eref.nativeElement.contains(targetElement)) {
      this.clickOutside.emit(event);
    }
  }

  // Adding window blur to target iframes as well
  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: MouseEvent): void {
    this.clickOutside.emit(event);
  }
}
