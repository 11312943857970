/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { flatMap } from 'lodash-es';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import {
  CouponDetailsModel,
  OddResultsModel,
  CouponDetailsOddModel,
  CouponDetailsGroupModel,
  CouponStatus,
} from 'src/app/shared/models/coupon-details.model';
import { mapBetFinalState, mapCouponStatus, mapCouponType } from 'src/app/shared/utils/virtuals-instant-league';

@Injectable({
  providedIn: 'root',
})
export class ParseCouponDetailsService {
  constructor(private readonly virtualsQuery: VirtualsQuery) {}

  parseCoupon(betDetailsData: any): CouponDetailsModel {
    let couponStatus: CouponStatus;
    switch (betDetailsData.CouponStatusId) {
      case 1:
        couponStatus = CouponStatus.Running;
        break;
      case 2:
        couponStatus = CouponStatus.Lost;
        break;
      case 3:
        couponStatus = CouponStatus.Won;
        break;
      case 4:
        couponStatus = CouponStatus.Cancelled;
        break;
      case 5:
        couponStatus = CouponStatus.SystemEvaluation;
        break;
      default:
        couponStatus = CouponStatus.Unknown;
        break;
    }

    let rebetEnabled = false;

    for (const odd of betDetailsData.Odds) {
      if (odd.Result === 'Unset') {
        rebetEnabled = true;
        break;
      }
    }
    const couponDetails = new CouponDetailsModel({
      betFinalState: betDetailsData.BetFinalState,
      couponCode: betDetailsData.CouponCode,
      couponDate: betDetailsData.CouponDate,
      couponStatus: couponStatus,
      couponType: betDetailsData.CouponType,
      couponTypeId: betDetailsData.CouponTypeId,
      currencySymbol: betDetailsData.Currency.CurrencySymbol,
      groups: [],
      jackpotId: betDetailsData.JackpotId,
      jackpotWinnings: betDetailsData.JP,
      maxBonus: betDetailsData.MaxBonus,
      maxOdd: betDetailsData.MaxOdd,
      maxPotWin: parseFloat(betDetailsData.NetStakeMaxWin) + parseFloat(betDetailsData.MaxBonus),
      maxPotWinNet: betDetailsData.MaxWinNet,
      maxPotWinTax: betDetailsData.MaxWithholdingTax,
      maxWin: betDetailsData.MaxWin,
      minBonus: betDetailsData.MinBonus,
      minOdd: betDetailsData.MinOdd,
      minWin: betDetailsData.MinWin,
      minWinNet: betDetailsData.MinWinNet,
      minWinTax: betDetailsData.MinWithholdingTax,
      netStakeMaxWin: betDetailsData.NetStakeMaxWin,
      netStakeMinWin: betDetailsData.netStakeMinWin,
      odds: [],
      paymentDate: betDetailsData.PaymentDate,
      rePrint: betDetailsData.IsPrinted,
      rebetEnabled,
      stake: betDetailsData.StakeGross,
      stakeNet: betDetailsData.Stake,
      stakeTax: betDetailsData.TurnoverTax,
      totalCombinations: betDetailsData.TotalCombinations,
      totalOdds: betDetailsData.TotalOdds,
      userId: betDetailsData.UserId,
      userName: betDetailsData.UserName,
      won: betDetailsData.Won,
      wonTax: betDetailsData.TotalTaxed,
    });

    betDetailsData.Odds.forEach(odd => {
      const halftimeScore = odd.Results.filter(oddResults => oddResults.Family === 'HT');
      const fulltimeScore = odd.Results.filter(oddResults => oddResults.Family === 'FT');
      let results = {};
      let batch = [];
      for (const result of odd.Results) {
        batch.push(
          new OddResultsModel({
            family: result.Family,
            symbol: result.Symbol,
            value: result.Value,
          })
        );
        if (batch.length === 2) {
          results[batch[0].family.toLowerCase()] = batch;
          batch = [];
        }
      }

      if (!Object.keys(results).length) {
        results = undefined;
      }

      couponDetails.odds.push(
        new CouponDetailsOddModel({
          eventId: odd.IDEvent,
          orderId: odd.IDOrder,
          sportName: odd.SportName,
          categoryName: odd.CategoryName,
          tournamentName: odd.Championship,
          roundNumber: odd.RoundNumber,
          eventCategory: odd.EventCategory,
          eventDate: odd.EventDate,
          eventName: odd.EventName,
          isBanker: odd.FixedOdd,
          marketId: odd.IDMarketType,
          marketName: odd.MarketName,
          selectionName: odd.SelectionName,
          oddValue: odd.OddValue,
          unboostedOddValue: odd.UnboostedOddValue,
          leagueNo: odd.LeagueNo,
          results,
          resultStatus: odd.Result,
          resultStatusId: odd.Win,
          resultHTScore:
            halftimeScore.length !== 0
              ? {
                  teamOne: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT1').Value,
                  teamTwo: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT2').Value,
                }
              : undefined,
          resultFTScore:
            fulltimeScore.length !== 0
              ? {
                  teamOne: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT1').Value,
                  teamTwo: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT2').Value,
                }
              : undefined,
        })
      );
    });

    betDetailsData.Groupings.forEach(group => {
      couponDetails.groups.push(
        new CouponDetailsGroupModel({
          combinations: group.Combinations,
          grouping: group.Grouping,
          maxBonus: group.MaxBonus,
          maxWin: group.MaxWin,
          minBonus: group.MinBonus,
          minWin: group.MinWin,
          netStakeMaxWin: group.netStakeMaxWin,
          netStakeMinWin: group.NetStakeMinWin,
          stake: group.Stake,
          stakeNet: group.NetStake,
          stakeTax: group.TurnoverTax,
        })
      );
    });
    return couponDetails;
  }

  parseVirtualsInstantCoupon(betDetailsData): CouponDetailsModel {
    const couponDetails = new CouponDetailsModel({
      betFinalState: mapBetFinalState(betDetailsData.status),
      couponCode: betDetailsData.ticketId,
      couponDate: betDetailsData.timeRegister,
      couponType: mapCouponType(betDetailsData.details.systemBets[0]?.systemCount, betDetailsData.details.events.length),
      couponStatus: mapCouponStatus(betDetailsData.status),
      currencySymbol: betDetailsData.currency?.symbol,
      groups: [],
      maxBonus: betDetailsData.winningData?.maxBonus,
      maxWin: betDetailsData.winningData?.limitMaxPayout,
      minBonus: betDetailsData.winningData?.minBonus,
      minWin: betDetailsData.winningData?.minWinning,
      netStakeMaxWin: betDetailsData.winningData?.maxWinning,
      netStakeMinWin: betDetailsData.winningData?.minWinning,
      maxOdd: undefined, // calculated further down
      maxPotWin: betDetailsData.winningData
        ? parseFloat(betDetailsData.winningData.maxWinning) + parseFloat(betDetailsData.winningData.maxBonus)
        : 0,
      maxPotWinNet: betDetailsData.winningData?.limitMaxPayout,
      maxPotWinTax: betDetailsData.wonData?.wonTaxes ? betDetailsData.wonData?.wonTaxes : 0,
      minOdd: undefined, // calculated further down
      minWinNet: betDetailsData.winningData?.minWinning,
      minWinTax: betDetailsData.wonData?.wonTaxes,
      odds: [],
      paymentDate: betDetailsData.timeRegister,
      selectionCount: betDetailsData.numBets,
      stake: betDetailsData.stake,
      stakeNet: betDetailsData.stake,
      stakeTax: betDetailsData.stakeTaxes,
      totalCombinations: betDetailsData.details.systemBets[0]?.systemCount,
      totalOdds: undefined, // calculated further down
      userId: betDetailsData.sellStaff?.id,
      userName: betDetailsData.unit.name,
      won: betDetailsData.wonData ? Number(betDetailsData.wonData.wonAmount) + Number(betDetailsData.wonData.wonBonus) : 0,
    });

    let minOdd: number;
    let maxOdd: number;
    const eventOdds: number[] = [];

    betDetailsData.details.events.forEach(event => {
      const results = {};

      if (event.finalOutcome?.length) {
        const teamOneFirstHalfGoals = Number(event.finalOutcome[0]) || 0;
        const teamTwoFirstHalfGoals = Number(event.finalOutcome[1]) || 0;
        const teamOneSecondHalfGoals = Number(event.finalOutcome[2]) || 0;
        const teamTwoSecondHalfGoals = Number(event.finalOutcome[3]) || 0;

        results['HT'] = [
          new OddResultsModel({
            value: teamOneFirstHalfGoals,
            family: 'HT',
            symbol: 'HT1',
          }),
          new OddResultsModel({
            value: teamTwoFirstHalfGoals,
            family: 'HT',
            symbol: 'HT2',
          }),
        ];
        results['FT'] = [
          new OddResultsModel({
            value: teamOneFirstHalfGoals + teamOneSecondHalfGoals,
            family: 'FT',
            symbol: 'FT1',
          }),
          new OddResultsModel({
            value: teamTwoFirstHalfGoals + teamTwoSecondHalfGoals,
            family: 'FT',
            symbol: 'FT2',
          }),
        ];
      }
      event.bets.forEach(odd => {
        const mappedPlaylist = this.virtualsQuery.instantLeagueMap?.playlists?.find(
          playlist => playlist.playlistID === event.playlistId?.toString()
        );
        const mappedMarket = flatMap(this.virtualsQuery.instantLeagueMap?.areas, area => area.markets)?.find(
          market => market.marketID === odd.marketId
        );
        const mappedSelection = mappedMarket?.selections?.find(selection => selection.selectionUniqueId === odd.oddId);
        couponDetails.odds.push(
          new CouponDetailsOddModel({
            championship: mappedPlaylist?.playlistName || event.playlistDescription,
            eventId: event.eventId,
            sportName: event.playlistDescription,
            categoryName: mappedPlaylist?.playlistName || event.playlistDescription,
            tournamentName: mappedPlaylist?.playlistName || event.playlistDescription,
            eventCategory: undefined,
            eventDate: event.eventTime,
            eventName: `${event.data?.participants[0].fifaCode} - ${event.data?.participants[1].fifaCode}`,
            isBanker: event.isBanker,
            marketId: odd.marketId,
            marketName: mappedMarket?.marketName || odd.marketId,
            homeTeamName: event.data?.participants[0].fifaCode,
            awayTeamName: event.data?.participants[1].fifaCode,
            marketOutright: 0,
            selectionName: mappedSelection?.selectionName || odd.oddId,
            oddValue: odd.oddValue,
            unboostedOddValue: odd.oddValue,
            isBetBuilder: odd.isBetBuilder,
            leagueNo: undefined,
            results,
            resultStatus: odd.status,
            resultStatusId: odd.status === 'WON' ? 1 : 0,
            resultHTScore: results['HT']
              ? {
                  teamOne: results['HT'].find(res => res.symbol === 'HT1')?.value,
                  teamTwo: results['HT'].find(res => res.symbol === 'HT2')?.value,
                }
              : undefined,
            resultFTScore: results['FT']
              ? {
                  teamOne: results['FT'].find(res => res.symbol === 'FT1')?.value,
                  teamTwo: results['FT'].find(res => res.symbol === 'FT2')?.value,
                }
              : undefined,
          })
        );
      });
    });

    betDetailsData.details.systemBets.forEach(bet => {
      const groupingMaxOdd = bet.winningData.maxWinning / (bet.stake / bet.systemCount);
      maxOdd === undefined ? (maxOdd = groupingMaxOdd) : (maxOdd += groupingMaxOdd);
      minOdd = bet.winningData.minWinning / (bet.stake / bet.systemCount);

      couponDetails.groups.push(
        new CouponDetailsGroupModel({
          combinations: betDetailsData.details.systemBets.length,
          grouping: bet.grouping,
          maxBonus: bet.winningData.maxBonus,
          maxWin: bet.winningData.maxWinning,
          minBonus: bet.winningData.minBonus,
          minWin: bet.winningData.minWinning,
          stake: bet.stake,
        })
      );
    });

    // get two smallest odd values
    eventOdds.sort((a, b) => a - b);

    couponDetails.minOdd = minOdd;
    couponDetails.maxOdd = maxOdd;
    couponDetails.totalOdds = maxOdd;
    return couponDetails;
  }
}
