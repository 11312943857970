import { Directive, ElementRef, Renderer2, Input, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appPagination]',
  exportAs: 'appPagination',
})
export class PaginationDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<boolean>();

  @Input() itemsPerPage: number;
  @Output() readonly pageChange: EventEmitter<number> = new EventEmitter<number>();
  private currentPage: number = 1;
  private totalItems: number;
  private totalPages: number;

  constructor(private readonly el: ElementRef, private readonly renderer: Renderer2) {}

  ngAfterViewInit() {
    this.calculateTotalItems();
    this.paginate();
  }

  private calculateTotalItems() {
    this.totalItems = this.el.nativeElement.children.length;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  paginate() {
    const items = Array.from(this.el.nativeElement.children);

    items.forEach((item, index) => {
      if (index >= (this.currentPage - 1) * this.itemsPerPage && index < this.currentPage * this.itemsPerPage) {
        this.renderer.setStyle(item, 'display', 'block');
      } else {
        this.renderer.setStyle(item, 'display', 'none');
      }
    });
    this.pageChange.emit(this.currentPage); // Emit the current page after pagination
  }

  goToPage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.paginate();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
  }

  refresh() {
    this.calculateTotalItems();
    this.paginate();
  }

  getCurrentPage() {
    return this.currentPage;
  }

  getTotalPages() {
    return this.totalPages;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
