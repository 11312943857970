import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { filter, map, takeUntil, tap, distinctUntilChanged, startWith, mergeMap } from 'rxjs/operators';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { ChangeDetectionStrategy, Component, OnDestroy, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeOut } from 'src/app/shared/animations';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusService } from 'src/app/core/services/bonuses/accumulator-bonus.service';
import { combineLatest, BehaviorSubject, Subject, Observable } from 'rxjs';
import { AccaBonusProgressionBarComponent } from 'src/app/shared/components/acca-bonus-progression-bar/acca-bonus-progression-bar.component';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [fadeOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<boolean>();
  private readonly couponQuerySubsDestroy$ = new Subject<boolean>();
  private readonly couponQuery$ = new BehaviorSubject<CouponQuery | VirtualsCouponQuery | InstantCouponQuery>(this.couponQuery);
  private readonly navbarAccumulatorBonusProgressionBarComponent$ = new BehaviorSubject<AccaBonusProgressionBarComponent>(null);

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly couponQuery: CouponQuery,
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly instantCouponQuery: InstantCouponQuery,
    private readonly accumulatorBonusService: AccumulatorBonusService,
    private readonly applicationService: ApplicationService,
    private readonly appConfig: AppConfigService
  ) {}

  get accaBonusProgressBarElementVisibility$(): Observable<boolean> {
    // ACCA Bonus outer element visibilty is determined via three conditions
    // - Whether the user is in the coupon
    // - Whether the user is in whitelisted url
    // - Whether the ACCA bonus is enabled
    return combineLatest([this.applicationQuery.inCoupon$, this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$]).pipe(
      map(([inCoupon, isActiveUrlInAccumulatorBonusWhiteList]) => {
        return !inCoupon && isActiveUrlInAccumulatorBonusWhiteList && this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled;
      })
    );
  }

  get useVirtualsNavbar$(): Observable<boolean> {
    const scheduledValue = this.appConfig.get('virtuals')?.scheduledLeague.useVirtualsNavbar;
    const instantValue = this.appConfig.get('virtuals')?.instantLeague.useVirtualsNavbar;

    return combineLatest([this.applicationQuery.isVirtualsScheduled$, this.applicationQuery.isVirtualsInstant$]).pipe(
      map(([isVirtualsScheduled, isVirtualsInstant]) => (isVirtualsScheduled ? scheduledValue : isVirtualsInstant ? instantValue : false)),
      takeUntil(this.destroy$)
    );
  }

  @ViewChild(AccaBonusProgressionBarComponent)
  set navbarAccCompSetter(component: AccaBonusProgressionBarComponent) {
    if (component) {
      this.navbarAccumulatorBonusProgressionBarComponent$.next(component);
    }
  }

  closeOddsValueInfoPopup(): void {
    this.accumulatorBonusService.dismissAccumulatorBonusOddsValuePopup();
  }

  ngOnInit(): void {
    combineLatest([this.applicationQuery.activeProduct$, this.applicationQuery.activeVirtualsLeague$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([activeProduct, activeVirtualsLeague]) => {
        if (activeProduct || activeVirtualsLeague) {
          this.initCouponQueryForActiveProduct();
          this.unacceptableSelectionPromptVisibility();
        }
      });
    this.initialiseCouponQuerySubscriptions();
  }

  ngAfterViewInit(): void {
    this.navbarAccumulatorBonusProgressionBarComponent$
      .pipe(
        filter(navbarAccumulatorBonusProgressionBarComponent => !!navbarAccumulatorBonusProgressionBarComponent),
        mergeMap(navbarAccumulatorBonusProgressionBarComponent =>
          // There are two conditionals that determine the visibility of the bonus bar
          // - The inner conditional that is determined via the business logic
          //   of the acca bonus bar component - NavbarAccumulatorBonusProgressionBarComponent.isVisible$
          // - The outer conditional handled via accaBonusProgressBarElementVisibility$
          combineLatest([navbarAccumulatorBonusProgressionBarComponent.isVisible$, this.accaBonusProgressBarElementVisibility$]).pipe(
            startWith([false, false]),
            tap(([accumulatorBonusProgressionBarIsVisible, accaBonusProgressBarElementVisibility]) => {
              setTimeout(() => {
                this.applicationService.updateUI({
                  isShowingNavbarBetslipAccumulatorBonusBar:
                    accumulatorBonusProgressionBarIsVisible && accaBonusProgressBarElementVisibility,
                });
              }, 0);
            })
          )
        )
      )
      .subscribe();
  }

  private readonly initCouponQueryForActiveProduct = () => {
    combineLatest([this.applicationQuery.isVirtualsInstant$, this.applicationQuery.isVirtualsScheduled$])
      .pipe(
        distinctUntilChanged(),
        tap(([isInstant, isScheduled]) => {
          this.couponQuerySubsDestroy$.next(true);
          this.couponQuery$.next(isInstant ? this.instantCouponQuery : isScheduled ? this.virtualsCouponQuery : this.couponQuery);
          // When switching between sports and virtuals, since we are changing the query we need to
          // close current subscriptions on the old query
          // and open new ones using the new query
          this.initialiseCouponQuerySubscriptions();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  };
  // Maintain visibility of acca bonus unacceptable selection prompt
  private readonly unacceptableSelectionPromptVisibility = () => {
    combineLatest([
      this.accumulatorBonusQuery.showOddsValueInfoPopup$,
      this.applicationQuery.inCoupon$,
      this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$,
      this.couponQuery.isFlexicutCoupon$,
    ])
      .pipe(
        tap(([showOddsValueInfoPopup, inCoupon, isActiveUrlInAccumulatorBonusWhiteList, isFlexicutCoupon]) => {
          setTimeout(() => {
            this.applicationService.updateUI({
              isShowingAccumulatorBonusUnacceptableSelectionsPrompt:
                showOddsValueInfoPopup &&
                isActiveUrlInAccumulatorBonusWhiteList &&
                !inCoupon &&
                !isFlexicutCoupon &&
                this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled,
            });
          }, 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  };
  private readonly initialiseCouponQuerySubscriptions = () => {
    combineLatest([
      this.couponQuery$.getValue().hasCouponSelections$,
      this.applicationQuery.inCoupon$,
      this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$,
    ])
      .pipe(
        tap(([couponSelections, inCoupon, isActiveUrlInAccumulatorBonusWhiteList]) => {
          this.applicationService.updateUI({
            isShowingNavbarBetslipSelections: couponSelections && !inCoupon && isActiveUrlInAccumulatorBonusWhiteList,
          });
        }),
        takeUntil(this.couponQuerySubsDestroy$)
      )
      .subscribe();
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.couponQuerySubsDestroy$.next(true);
    this.couponQuerySubsDestroy$.complete();
  }
}
