<div class="overlay-filter" (click)="openFilter()">
  <div class="filter-button">
    <button class="text-icon-button main-btn" [class.selected]="isFilterApplied$ | async">
      <span i18n>Filter</span>
      <i class="material-icons"> {{ 'filter_list' }}</i>
    </button>
  </div>
</div>

<ng-template #filterOptions>
  <div class="filter-content full-page-overlay">
    <div>
      <div class="header" (click)="disposeFilter()">
        <span i18n class="title">Filters</span>
        <span class="material-icons icon">{{'close'}}</span>
      </div>
      <div class="content" *ngFor="let sortFilterOption of sortFilterOptions$ | async">
        <div>
          <span i18n>{{sortFilterOption.title}}</span>
        </div>
        <div class="filter-button">
          <button  class="text-icon-button"
          *ngFor="let sortOption of sortFilterOption.options; let sortIndex = index; trackBy: sortTrackBy"
          [class.selected]="sortOption.name === sortFilterOption.selectedValue?.name"
          (click)="sortFilterOption.selectedValue = sortOption">
            {{sortOption.name}}
          </button>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="link-btn" (click)="resetFilter()" i18n>Reset</button>
      <button class="link-btn" (click)="applyFilter()" i18n>Apply</button>
    </div>
  </div>
</ng-template>
