import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Hammer from '@egjs/hammerjs';
import { BehaviorSubject, interval, Subject, throwError } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { VirtualsJackpot, VirtualsJackpotBanner, VirtualsJackpotBannerJackpot } from 'src/app/shared/models/virtuals.model';
import { JackpotEngineService } from 'src/app/core/services/jackpot-engine.service';
import { JackpotMappingCMS } from 'src/app/shared/models/jackpot-engine.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'virtuals-jackpot-banner',
  templateUrl: './virtuals-jackpot-banner.component.html',
  styleUrls: ['./virtuals-jackpot-banner.component.scss'],
})
export class VirtualsJackpotBannerComponent implements OnInit, OnDestroy {
  @Input() jackpotBanner: VirtualsJackpotBanner;
  @Input() inGameBanner: boolean = false;
  currentIndex: number = 0;
  initialState: boolean = true;
  readonly CAROUSEL_INTERVAL: number = 7000;
  readonly jackpotBannerItems$ = new BehaviorSubject<VirtualsJackpotBannerJackpot[]>([]);
  private readonly bannerContainer$ = new BehaviorSubject(undefined);
  private readonly destroy$ = new Subject<void>();
  private isGesturesRegistered = false;
  private intervalId;
  private isAutoChangeEnabled = true;

  constructor(
    private readonly virtualsService: VirtualsService,
    private readonly jackpotEngineService: JackpotEngineService,
    private readonly appConfig: AppConfigService
  ) {}

  @ViewChild('bannerContainer')
  set bannerContainerSetter(bannerContainer: ElementRef) {
    if (bannerContainer) {
      this.bannerContainer$.next(bannerContainer);
    }
  }

  ngOnInit(): void {
    this.initiateJackpotBanner();
    this.setupBannerContainer();
  }

  private setupBannerContainer(): void {
    this.bannerContainer$.subscribe(bannerContainer => {
      this.disableAutoChange();
      if (bannerContainer) {
        if (!this.isGesturesRegistered) {
          this.registerGestures();
        }
        if (this.jackpotBannerItems$.value.length > 1) {
          this.enableAutoChange();
          this.startCarousel();
        }
      }
    });
  }

  private initiateJackpotBanner() {
    this.jackpotEngineService
      .getJackpotsCms()
      .pipe(
        switchMap(jackpotMappings =>
          interval(
            this.jackpotBanner.getJackpotValuesInterval * 1000 || this.appConfig.get('virtuals')?.jackpot?.jackpotApiCallInterval
          ).pipe(
            startWith(0),
            switchMap(() => this.virtualsService.getJackpots()),
            map(jackpots => ({ jackpots, jackpotMappings })),
            catchError(error => {
              return throwError(error.message);
            })
          )
        ),
        map(({ jackpots, jackpotMappings }) => {
          const matchingJackpots = jackpots.filter(jackpot => jackpotMappings.some(mapping => Number(mapping.jackpotId) === jackpot.id));

          this.jackpotBannerItems$.next(
            matchingJackpots.reverse().map(jackpot => {
              const matchingJackpotMapping = jackpotMappings.find(j => Number(j.jackpotId) === jackpot.id);
              return this.createJackpotItem(matchingJackpotMapping, jackpot, this.jackpotBanner.latestJackpotUrl);
            })
          );
          this.initialState = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private createJackpotItem(
    matchingJackpotMapping: JackpotMappingCMS,
    jackpot: VirtualsJackpot,
    latestJackpotUrl: string
  ): VirtualsJackpotBannerJackpot {
    return {
      ...matchingJackpotMapping,
      jackpotAmount: jackpot.value,
      jackpotDropAmount: jackpot.tippingPoint,
      jackpotNextDrop: jackpot.nextDrop,
      jackpotLogo: matchingJackpotMapping.jackpotLogo.data.attributes.url,
      latestJackpotUrl,
    };
  }

  private registerGestures() {
    const hammer = new Hammer(this.bannerContainer$.value.nativeElement);
    hammer.on('swipeleft', () => {
      this.showNext();
    });
    hammer.on('swiperight', () => {
      this.showPrevious();
    });
    hammer.on('press', () => {
      this.disableAutoChange();
    });
    hammer.on('pressup', () => {
      this.enableAutoChange();
    });

    this.isGesturesRegistered = true;
  }

  private startCarousel() {
    this.intervalId = setInterval(() => {
      if (this.isAutoChangeEnabled) {
        this.showNext();
      }
    }, this.CAROUSEL_INTERVAL);
  }

  private showNext() {
    this.disableAutoChange();
    this.currentIndex = (this.currentIndex + 1) % this.jackpotBannerItems$.value.length;
    this.enableAutoChange();
  }

  private showPrevious() {
    this.disableAutoChange();
    this.currentIndex = (this.currentIndex - 1 + this.jackpotBannerItems$.value.length) % this.jackpotBannerItems$.value.length;
    this.enableAutoChange();
  }

  private disableAutoChange() {
    this.isAutoChangeEnabled = false;
  }

  private enableAutoChange() {
    this.isAutoChangeEnabled = true;
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
