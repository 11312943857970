import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({ name: 'todayDate' })
export class TodayDatePipe implements PipeTransform {
  transform(date: Date): string {
    const currentDate = new Date();
    const eventDate = new Date(date);

    // Check if the date is today
    if (
      eventDate.getDate() === currentDate.getDate() &&
      eventDate.getMonth() === currentDate.getMonth() &&
      eventDate.getFullYear() === currentDate.getFullYear()
    ) {
      return $localize`Today`;
    }

    // Return the formatted date
    return format(new Date(date), 'dd MMM');
  }
}
