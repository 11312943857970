import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appXSellSwipeable]',
})
export class XSellSwipeableDirective implements OnInit, OnChanges, OnDestroy {
  @Input() readonly startingScreenPosition: 'left' | 'right';

  private _element: HTMLElement;
  /*
   Something when a user taps, they can sometimes drag their finger and it is not taken as a click.
   A buffer for the movement is taken into consideration so that it is taken as a click instead
  */
  private readonly CLICK_BUFFER_X = 10;
  private readonly CLICK_BUFFER_Y = 20;
  private _onClickYPosition = 0;

  // Internal subscription to keep track of whether or not user is swiping
  private readonly _isSwipingNotDistinct$ = new BehaviorSubject(false);
  private readonly _destroy$ = new Subject<boolean>();

  // Public API
  readonly isSwiping$ = this._isSwipingNotDistinct$.pipe(distinctUntilChanged());
  readonly currentXPosition$ = new BehaviorSubject<number>(0);
  readonly currentSwiperContentXPosition$ = new BehaviorSubject(0);
  readonly currentYPosition$ = new BehaviorSubject<number>(undefined);
  readonly isOpen$ = new BehaviorSubject(false);
  readonly hasBeenShown$ = new BehaviorSubject(false);
  readonly previousInteractionWasASwipe$ = new BehaviorSubject(false);

  constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private readonly document: any) {}

  private get _xOpenedClosedPositions() {
    return {
      closedXPosition: this.startingScreenPosition === 'left' ? 0 : parseInt(this.document.body.clientWidth),
      openedXPosition: this.startingScreenPosition === 'left' ? parseInt(this.document.body.clientWidth) : 0,
    };
  }

  ngOnInit(): void {
    this._element = this.elementRef.nativeElement as HTMLElement;
    this.handleSwipeTracking();

    this.currentXPosition$
      .pipe(
        tap(x =>
          // X Position tracker for actual content, to cater for both left or right starting position
          this.currentSwiperContentXPosition$.next(
            this.startingScreenPosition === 'left' ? x : x + parseInt(this.document.body.clientWidth)
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe();

    // Reset swiper upon resize.
    fromEvent<Event>(window, 'resize')
      .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
      .subscribe(() => {
        // Check if the swipeable has gone out of bounds on resize before resetting swipeable position
        const outOfBoundsY = this.currentYPosition$.value > this.document.body.clientHeight || this.currentYPosition$.value < 0;

        this.currentXPosition$.next(
          this.isOpen$.value ? this._xOpenedClosedPositions.openedXPosition : this._xOpenedClosedPositions.closedXPosition
        );
        this._element.style.left = this.isOpen$.value
          ? this._xOpenedClosedPositions.openedXPosition.toString()
          : this._xOpenedClosedPositions.closedXPosition.toString();

        if (outOfBoundsY) {
          this.currentYPosition$.next(undefined);
          this._onClickYPosition = 0;
          this._element.style.top = '';
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.startingScreenPosition.currentValue &&
      changes.startingScreenPosition.currentValue !== changes.startingScreenPosition.previousValue
    ) {
      this.currentXPosition$.next(changes.startingScreenPosition.currentValue === 'left' ? 0 : this.document.body.clientWidth);
      this.isOpen$.next(false);
    }
  }

  private handleSwipeTracking(): void {
    // Mouse
    const mouseDragEnd$ = fromEvent<MouseEvent>(this._element, 'mouseup').pipe(
      tap(e => e.preventDefault()),
      takeUntil(this._destroy$)
    );

    const mouseDragStart$ = fromEvent<MouseEvent>(this._element, 'mousedown').pipe(
      tap(e => e.preventDefault()),
      takeUntil(this._destroy$)
    );

    const mouseDrag$ = fromEvent<MouseEvent>(this.document.body, 'mousemove').pipe(
      tap(e => e.preventDefault()),
      takeUntil(mouseDragEnd$)
    );

    // Touch
    const touchSwipeStart$ = fromEvent<TouchEvent>(this._element, 'touchstart').pipe(
      tap(e => e.preventDefault()),
      takeUntil(this._destroy$)
    );
    const touchSwipeEnd$ = fromEvent<TouchEvent>(this._element, 'touchend').pipe(
      tap(e => e.preventDefault()),
      takeUntil(this._destroy$)
    );
    const touchDrag$ = fromEvent<TouchEvent>(this._element, 'touchmove', { passive: false }).pipe(
      tap(e => e.preventDefault()),
      takeUntil(touchSwipeEnd$)
    );

    let swipeSub: Subscription;

    const swipeStartLogic = (isTouch: boolean, event: MouseEvent | TouchEvent, dragEventSub$: Observable<MouseEvent | TouchEvent>) => {
      // Get the position where click happened to calculate offset of element to pointer
      const clickOffsetX =
        (isTouch ? (event as TouchEvent).touches[0].clientX : (event as MouseEvent).clientX) - this.currentXPosition$.value;
      const clickOffsetY =
        (isTouch ? (event as TouchEvent).touches[0].clientY : (event as MouseEvent).clientY) -
        (this.currentYPosition$.value === undefined ? this._element.offsetTop : this.currentYPosition$.value);

      // Store Y position at start of swipe
      this._onClickYPosition = (isTouch ? (event as TouchEvent).touches[0].clientY : (event as MouseEvent).clientY) - clickOffsetY;

      // Subscription that handles position tracking
      swipeSub = dragEventSub$.pipe(takeUntil(this._destroy$)).subscribe((subEvent: MouseEvent | TouchEvent) => {
        this._isSwipingNotDistinct$.next(true);

        // Bounds checking
        const calculatedX = isTouch
          ? (subEvent as TouchEvent).touches[0].clientX - clickOffsetX
          : (subEvent as MouseEvent).clientX - clickOffsetX;
        const calculatedY = isTouch
          ? (subEvent as TouchEvent).touches[0].clientY - clickOffsetY
          : (subEvent as MouseEvent).clientY - clickOffsetY;

        this.currentXPosition$.next(
          calculatedX >= 0 && calculatedX <= this.document.body.clientWidth ? calculatedX : this.currentXPosition$.value
        );
        this.currentYPosition$.next(
          calculatedY >= 0 && calculatedY <= this.document.body.clientHeight ? calculatedY : this.currentYPosition$.value
        );
      });
    };

    const swipeEndLogic = () => {
      const isXMovementWithinBuffer =
        Math.abs(this.isOpen$.value ? this.document.body.clientWidth - this.currentXPosition$.value : this.currentXPosition$.value) <
        this.CLICK_BUFFER_X;
      const isYMovementWithinBuffer = Math.abs(this.currentYPosition$.value - this._onClickYPosition) < this.CLICK_BUFFER_Y;
      const xPositionCondition =
        this.startingScreenPosition === 'left'
          ? this.currentXPosition$.value > this.document.body.clientWidth / 2
          : this.currentXPosition$.value < this.document.body.clientWidth / 2;

      if (this._isSwipingNotDistinct$.value) {
        setTimeout(() => {
          // Check if swipe is within buffer
          if (isXMovementWithinBuffer && isYMovementWithinBuffer) {
            // User has not skipped buffer whilst swiping, toggle to next edge
            this.previousInteractionWasASwipe$.next(false);
            if (xPositionCondition) {
              this.currentXPosition$.next(this._xOpenedClosedPositions.closedXPosition);
              this.isOpen$.next(false);
            } else {
              this.currentXPosition$.next(this._xOpenedClosedPositions.openedXPosition);
              this.isOpen$.next(true);
            }
          } else {
            // User swiped; Snap to nearest edge
            this.previousInteractionWasASwipe$.next(true);
            if (xPositionCondition) {
              this.currentXPosition$.next(this._xOpenedClosedPositions.openedXPosition);
              this.isOpen$.next(true);
            } else {
              this.currentXPosition$.next(this._xOpenedClosedPositions.closedXPosition);
              this.isOpen$.next(false);
            }
          }
        });

        this._isSwipingNotDistinct$.next(false);
      } else {
        // User just clicked; Toggle Open/Close
        this.previousInteractionWasASwipe$.next(false);
        if (xPositionCondition) {
          this.currentXPosition$.next(this._xOpenedClosedPositions.closedXPosition);
          this.isOpen$.next(false);
        } else {
          this.currentXPosition$.next(this._xOpenedClosedPositions.openedXPosition);
          this.isOpen$.next(true);
        }
      }

      if (swipeSub) {
        swipeSub.unsubscribe();
      }
    };

    // Swipe start
    mouseDragStart$.pipe(takeUntil(this._destroy$)).subscribe((event: MouseEvent) => {
      swipeStartLogic(false, event, mouseDrag$);
    });
    touchSwipeStart$.pipe(takeUntil(this._destroy$)).subscribe((event: TouchEvent) => {
      swipeStartLogic(true, event, touchDrag$);
    });

    // Swipe end
    mouseDragEnd$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      swipeEndLogic();
    });
    touchSwipeEnd$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      swipeEndLogic();
    });

    // Writing of element position style during a swipe
    this.currentXPosition$
      .pipe(
        mergeMap(x => this.currentYPosition$.pipe(map(y => [x, y]))),
        takeUntil(this._destroy$)
      )
      .subscribe(([currentX, currentY]) => {
        this._element.style.left = `${currentX}px`;
        this._element.style.top = `${currentY}px`;
      });

    // Toggling 'swiping' element class
    this.isSwiping$.pipe(takeUntil(this._destroy$)).subscribe(isSwiping => {
      isSwiping ? this._element.classList.add('swiping') : this._element.classList.remove('swiping');
    });

    // Tracking of whether or not content has been shown to the user.
    this.isSwiping$
      .pipe(
        mergeMap(isSwiping => this.isOpen$.pipe(map(isOpen => isSwiping || isOpen))),
        filter(value => {
          return !!value;
        }),
        first()
      )
      .subscribe(() => {
        this.hasBeenShown$.next(true);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
