<div class="selection-container">
  <!-- Expired Events -->
  <div
    class="selection-content expired-events"
    *ngIf="(couponQuery.expiredEvents$ | async) && (couponQuery.expiredEvents$ | async).expiredEvents.length > 0"
  >
    <div class="tournament-name">
      <span i18n class="expired-events-title">EXPIRED EVENTS</span>
      <button class="clear-expired-button" (click)="clearExpiredEvents()" i18n>REMOVE</button>
    </div>

    <div class="event-content" *ngFor="let match of (couponQuery.expiredEvents$ | async).expiredEvents; trackBy: eventTrackBy">
      <coupon-expired-selection
        [bookedCoupon]="bookedCoupon"
        [eventCategory]="match.eventCategory"
        [eventName]="match.eventName"
        [marketName]="match.marketName"
        [selectionName]="match.isBetBuilder ? '' : parseSelectionName(match.marketTypeId, match.selectionName, match.eventName)"
        [betBuilderConditions]="match.isBetBuilder ? getConditions(match.selectionName) : []"
      ></coupon-expired-selection>
    </div>
  </div>

  <!-- Non-Expired Events -->
  <ng-container
    *ngIf="{
      selections: couponQuery.selections$ | async,
      groupedSelections: couponQuery.groupedSelections$ | async,
      invalidFreebetSelections: couponQuery.invalidFreebetSelections$ | async,
      oddsBelowFlexicutMinOdds: couponQuery.oddsBelowFlexicutMinOdds$ | async,
      invalidFlexicutSelections: couponQuery.invalidFlexicutSelections$ | async,
      allowCompetitionGrouping: (couponQuery.couponSettings$ | async).allowCompetitionGrouping
    } as subscriptions"
  >
    <!-- allowCompetitionGrouping is ON -->
    <ng-container *ngIf="subscriptions.allowCompetitionGrouping">
      <ng-container *ngFor="let selection of subscriptions.groupedSelections; trackBy: selectionTrackBy">
        <ng-container *ngFor="let category of selection.categories">
          <div class="selection-content" *ngFor="let tournament of category.tournaments; trackBy: tournamentTrackBy">
            <div class="tournament-name">
              <span class="sport-icon icon-{{ selection.id }} brand-light"></span>
              <span i18n>{{ category.name }} - {{ tournament.name }}</span>
            </div>
            <ng-container
              [ngTemplateOutlet]="eventsSwitcher"
              [ngTemplateOutletContext]="{ tournament: tournament, subscriptions: subscriptions, checkTournament: false }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- allowCompetitionGrouping is OFF -->
    <ng-container *ngIf="!subscriptions.allowCompetitionGrouping">
      <div class="spacing"></div>
      <div class="selection-content" *ngFor="let tournament of subscriptions.selections; trackBy: tournamentTrackBy">
        <ng-container
          [ngTemplateOutlet]="eventsSwitcher"
          [ngTemplateOutletContext]="{ tournament: tournament, subscriptions: subscriptions, checkTournament: true }"
        ></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template let-match="match" let-odd="odd" let-className="className" #selectionName>
  <div class="{{ className }}">{{ parseSelectionName(odd.marketTypeId, odd.selectionName, match.name) }}</div>
</ng-template>

<ng-template let-match="match" let-className="className" #matchName>
  <ng-container *ngIf="match.groupingType === 2 || match.hasExpiredEvents; else hrefContent">
    <div class="{{ className }}">{{ match.name }}</div>
  </ng-container>
  <ng-template #hrefContent>
    <div class="{{ className }}" (click)="goToPage($event, match)">{{ match.name }}</div>
  </ng-template>
</ng-template>

<ng-template let-tournament="tournament" let-subscriptions="subscriptions" let-match="match" #eventContent>
  <div class="banker-container" *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
    <button
      class="banker"
      *ngIf="match.allowFixed"
      [disabled]="match.isBankerDisabled"
      [class.selected]="match.fixed"
      (click)="updateOddBankerStatus(match.odds[0].id, !match.fixed)"
    >
      <i class="material-icons icon banker-icon">{{ 'push_pin' }}</i>
    </button>

    <span *ngIf="!match.allowFixed" class="banker locked material-icons">{{ 'lock' }}</span>
  </div>
  <div class="event-container">
    <div class="event-details-container" [class.one-market]="match.odds.length === 1">
      <div class="event-details">
        <span class="live" *ngIf="match.eventCategory === 'L'">
          <span class="material-icons">{{ 'schedule' }}</span>
          <span class="label" i18n>Live</span>
        </span>
        <ng-container
          *ngIf="match.odds.length === 1"
          [ngTemplateOutlet]="selectionName"
          [ngTemplateOutletContext]="{ match: match, odd: match.odds[0], className: 'selection-name' }"
        ></ng-container>
        <ng-container
          *ngIf="match.odds.length > 1"
          [ngTemplateOutlet]="matchName"
          [ngTemplateOutletContext]="{ match: match, className: 'selection-name' }"
        ></ng-container>
      </div>
    </div>

    <div class="market-odds-container">
      <ng-container *ngFor="let marketOddMap of couponQuery.selectionMarketMatches$ | async; trackBy: indexTrackBy">
        <ng-container *ngFor="let matchId of marketOddMap.matchIds">
          <ng-container *ngIf="matchId === match.id">
            <ng-container *ngFor="let market of filterMarkets(tournament.regions[0].areas[0].markets); trackBy: indexTrackBy">
              <div
                class="markets"
                [class.no-edit]="bookedCoupon || match.eventCategory === 'L'"
                [class.one-market]="match.odds.length === 1"
                [class.one-selection]="selectionCount(market.id, match.odds)"
                *ngIf="marketOddMap.marketId === market.id"
              >
                <div class="details-container">
                  <div class="market-details">
                    <div
                      class="edit-odd"
                      *ngIf="!bookedCoupon && !(match.eventCategory === 'L')"
                      (click)="editOdd(match.smartBetCode, market.typeId, market.spreadValue)"
                      [class.selected]="
                        (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                        (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                      "
                    >
                      <i class="fa fa-pencil"></i>
                    </div>
                    <div class="market-name">
                      <span>{{ market.name }} -</span>
                    </div>
                  </div>
                  <div class="selection-details">
                    <ng-container *ngFor="let odd of match.odds; trackBy: oddTrackBy">
                      <div class="selection-details-content" *ngIf="market.id === odd.marketId">
                        <!-- Render event name when only 1 market and 1 selection is selected-->
                        <ng-container
                          *ngIf="match.odds.length === 1 && selectionCount(market.id, match.odds)"
                          [ngTemplateOutlet]="matchName"
                          [ngTemplateOutletContext]="{ match: match, className: 'market-selection' }"
                        ></ng-container>

                        <div class="odd-details-row" [ngClass]="hasOddChanges$ | async | oddChanged : odd.id">
                          <div
                            class="odd-details"
                            [class.boosted]="!!odd.unboostedValue"
                            [class.invalid-for-freebet]="isInvalidFreebetSelection(odd, subscriptions.invalidFreebetSelections)"
                            [class.invalid-for-flexicut]="
                              isInvalidFlexicutSelection(
                                odd,
                                subscriptions.oddsBelowFlexicutMinOdds,
                                subscriptions.invalidFlexicutSelections
                              )
                            "
                          >
                            <!-- This selection name for handling more than 1 market or more than 1 selection -->
                            <ng-container
                              *ngIf="match.odds.length > 1 || !selectionCount(market.id, match.odds)"
                              [ngTemplateOutlet]="selectionName"
                              [ngTemplateOutletContext]="{ match: match, odd: odd, className: 'market-selection' }"
                            ></ng-container>

                            <ng-container *ngIf="match.eventCategory === 'L' && odd.isLocked; else oddValue">
                              <div class="suspended" i18n>Suspended</div>
                            </ng-container>

                            <ng-template #oddValue>
                              <span *ngIf="odd.isExpired" class="expired" i18n>Expired</span>
                              <div *ngIf="!odd.isExpired" class="odd-value" [class.boosted]="!!odd.unboostedValue">
                                <div class="normal-odd-value">
                                  <ng-container
                                    *ngIf="
                                      isInvalidFreebetSelection(odd, subscriptions.invalidFreebetSelections) ||
                                        isInvalidFlexicutSelection(
                                          odd,
                                          subscriptions.oddsBelowFlexicutMinOdds,
                                          subscriptions.invalidFlexicutSelections
                                        );
                                      else oddsTemplate
                                    "
                                  >
                                    <span class="invalid-selection" i18n>Not Valid</span>
                                  </ng-container>
                                  <ng-template #oddsTemplate>
                                    <i *ngIf="!!odd.isBoosted" class="odds-boost-badge"></i>
                                    <span>{{ odd.value | number : '1.2-2' }}</span>
                                  </ng-template>
                                </div>
                                <div *ngIf="!!odd.isBoosted" class="unboosted-odd-value">
                                  {{ odd.unboostedValue | number : '1.2-2' }}
                                </div>
                              </div>
                            </ng-template>
                          </div>
                          <span class="close-odd material-icons" *ngIf="!bookedCoupon" (click)="removeOdd(odd.id)">{{ 'delete' }}</span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template let-tournament="tournament" let-subscriptions="subscriptions" let-match="match" #eventContentBetBuilder>
  <div class="banker-container" *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
    <button
      class="banker"
      *ngIf="match.allowFixed"
      [disabled]="match.isBankerDisabled"
      [class.selected]="match.fixed"
      (click)="updateOddBankerStatus(match.odds[0].id, !match.fixed)"
    >
      <i class="material-icons icon banker-icon">{{ 'push_pin' }}</i>
    </button>

    <span *ngIf="!match.allowFixed" class="banker locked material-icons">{{ 'lock' }}</span>
  </div>
  <div class="event-container">
    <div class="event-details-container bet-builder-content" [class.one-market]="match.odds.length === 1">
      <div class="event-details-betbuilder">
        <div i18n class="market-name">{{ match.odds[0].marketName }}</div>
        <ng-container
          [ngTemplateOutlet]="matchName"
          [ngTemplateOutletContext]="{ match: match, className: 'selection-name' }"
        ></ng-container>
        <div class="bet-builder-conditions">
          <div *ngFor="let condition of getConditions(match.odds[0].selectionName)" class="bet-builder-condition">
            <div i18n class="bet-builder-condition-icon"><span></span></div>
            <div class="bet-builder-condition-text">{{ condition }}</div>
          </div>
        </div>
      </div>
      <div class="market-odds-container">
        <div class="markets no-edit one-market one-selection">
          <div class="details-container">
            <div class="selection-details">
              <div class="selection-details-content">
                <div class="odd-details-row bet-bulider-odds" [ngClass]="hasOddChanges$ | async | oddChanged : match.odds[0].id">
                  <div
                    class="odd-details bet-builder-odds"
                    id="bet-builder-odds"
                    [class.boosted]="!!match.odds[0].unboostedValue"
                    [class.invalid-for-freebet]="isInvalidFreebetSelection(match.odds[0], subscriptions.invalidFreebetSelections)"
                    [class.invalid-for-flexicut]="
                      isInvalidFlexicutSelection(
                        match.odds[0],
                        subscriptions.oddsBelowFlexicutMinOdds,
                        subscriptions.invalidFlexicutSelections
                      )
                    "
                  >
                    <ng-container *ngIf="match.eventCategory === 'L' && odd.isLocked">
                      <div class="suspended" i18n>Suspended</div>
                    </ng-container>
                    <span *ngIf="match.odds[0].isExpired" class="expired" i18n>Expired</span>

                    <div
                      *ngIf="!match.odds[0].isExpired && !(match.eventCategory === 'L' && odd.isLocked)"
                      class="odd-value"
                      [class.boosted]="!!match.odds[0].unboostedValue"
                    >
                      <div class="normal-odd-value">
                        <ng-container
                          *ngIf="
                            isInvalidFreebetSelection(match.odds[0], subscriptions.invalidFreebetSelections) ||
                              isInvalidFlexicutSelection(
                                match.odds[0],
                                subscriptions.oddsBelowFlexicutMinOdds,
                                subscriptions.invalidFlexicutSelections
                              );
                            else oddsTemplate
                          "
                        >
                          <span class="invalid-selection" i18n>Not Valid</span>
                        </ng-container>
                        <ng-template #oddsTemplate>
                          <i *ngIf="!!match.odds[0].isBoosted" class="odds-boost-badge"></i>
                          <span i18n>{{ match.odds[0].value | number : '1.2-2' }}</span>
                        </ng-template>
                      </div>
                      <div i18n *ngIf="!!match.odds[0].isBoosted" class="unboosted-odd-value">
                        {{ match.odds[0].unboostedValue | number : '1.2-2' }}
                      </div>
                    </div>
                  </div>
                  <span
                    id="bet-builder-action"
                    class="close-odd material-icons bet-builder-action"
                    *ngIf="!bookedCoupon"
                    (click)="removeOdd(match.odds[0].id)"
                    >{{ 'delete' }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-tournament="tournament" let-subscriptions="subscriptions" let-checkTournament="checkTournament" #eventsSwitcher>
  <div class="event-content" *ngFor="let match of tournament.matches; trackBy: matchTrackBy">
    <ng-container
      [ngTemplateOutlet]="switchEventTemplate(tournament.isBetBuilder, match, checkTournament) ? eventContentBetBuilder : eventContent"
      [ngTemplateOutletContext]="{ tournament: tournament, subscriptions: subscriptions, match: match }"
    ></ng-container>
  </div>
</ng-template>
