import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly REFRESH_TOKEN_EXPIRY = 14;
  private readonly NUMBER_OF_SECONDS_PER_DAY = 86400;
  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) {}

  getAccessToken(username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('brandId', this.appConfig.get('brandId').toString())
      .set('grant_type', 'password')
      .toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.Guardian, 'oauth/token', body, apiSettings).pipe(
      map(tokenData => {
        if (tokenData && tokenData.expires_in) {
          this.cookieService.setCookie('accessToken', tokenData.access_token, tokenData.expires_in / this.NUMBER_OF_SECONDS_PER_DAY);
          this.cookieService.setCookie('refreshToken', tokenData?.refresh_token, this.REFRESH_TOKEN_EXPIRY);
        }
        return tokenData ? tokenData.access_token : undefined;
      })
    );
  }

  getF2PAccessToken(username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('brandId', this.appConfig.get('f2pBrandId').toString())
      .set('grant_type', 'password')
      .toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.F2PGuardian, 'oauth/Token', body, apiSettings).pipe(
      map(tokenData => {
        if (tokenData && tokenData.expires_in) {
          this.cookieService.setCookie('accessToken', tokenData.access_token, tokenData.expires_in / this.NUMBER_OF_SECONDS_PER_DAY);
          this.cookieService.setCookie('refreshToken', tokenData?.refresh_token, this.REFRESH_TOKEN_EXPIRY);
        }
        return tokenData ? tokenData.access_token : undefined;
      })
    );
  }

  validateToken(accessToken: string): Observable<any> {
    const body = new HttpParams().set('token', accessToken).toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.Guardian, 'auth/validate', body, apiSettings);
  }

  validateF2PToken(accessToken: string): Observable<any> {
    const body = new HttpParams().set('token', accessToken).toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.F2PGuardian, 'auth/validate', body, apiSettings);
  }

  revokeToken(accessToken: string): Observable<any> {
    const body = new HttpParams().set('token', accessToken).toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.Guardian, 'auth/revokeToken', body, apiSettings);
  }

  revokeF2PToken(accessToken: string): Observable<any> {
    const body = new HttpParams().set('token', accessToken).toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
      sendActivitySource: true,
    });

    return this.apiService.post<any>(APIType.F2PGuardian, 'auth/revokeToken', body, apiSettings);
  }
}
