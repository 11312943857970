import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { HeaderContent, HeaderState } from 'src/app/modules/header/models/header.model';

export const createInitialApplicationState = (): HeaderState => ({
  headerContentLoading: false,
  headerContent: {
    accountMenuUrl: '/account',
    loginLabel: 'Login',
    loginUrl: '/login',
    messageCenterUrl: '/message-center',
    productSwitcherList: [
      {
        hasNewBadge: false,
        link: {
          title: 'Sports',
          url: '/',
        },
      },
      {
        hasNewBadge: false,
        link: {
          title: 'Virtual',
          url: '/virtual',
        },
      },
      {
        hasNewBadge: true,
        link: {
          title: 'Casino',
          url: '/casino',
        },
      },
      {
        hasNewBadge: false,
        link: {
          title: 'Jackpot Bets',
          url: '/jackpot-bets',
        },
      },
    ],
    registerLabel: 'Join',
    registerUrl: '/account/registration',
  },
  productSwitcherBlacklist: [],
  appBarBlacklist: [],
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'header' })
export class HeaderStore extends Store<HeaderState> {
  constructor(private readonly appConfigService: AppConfigService) {
    super(createInitialApplicationState());
    const headerConfig = this.appConfigService.get('header');
    this.update({ productSwitcherBlacklist: headerConfig?.productSwitcher.blacklist, appBarBlacklist: headerConfig?.appBar.blacklist });
  }

  updateHeaderContentLoading(headerContentLoading: boolean): void {
    this.update({ headerContentLoading });
  }

  updateHeaderContent(headerContent: HeaderContent): void {
    this.update({ headerContent });
  }
}
