import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { XSellSwipeableDirective } from 'src/app/modules/x-sell/directives/x-sell-swipeable.directive';

@Component({
  selector: 'x-sell-swiper',
  templateUrl: './x-sell-swiper.component.html',
  styleUrls: ['./x-sell-swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XSellSwiperComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(XSellSwipeableDirective, { static: true }) readonly swipeable: XSellSwipeableDirective;
  @ViewChild('swiperContent', { static: true }) readonly swiperContent: ElementRef;

  @Input() readonly xSellIconURL?: string;
  @Input() readonly xSellIconBGColor?: string;
  @Input() readonly spbIconURL?: string;
  @Input() readonly spbIconBGColor?: string;
  @Input() readonly startingScreenPosition: 'left' | 'right' = 'left';

  customXSellIconStyle = {};
  customXSellContentLoadingIconStyle = {};
  customSPBIconStyle = {};

  readonly isSwiping$ = new BehaviorSubject(false);
  readonly swipeableContentOpen$ = new BehaviorSubject(false);
  readonly swipeableContentHasBeenShown$ = new BehaviorSubject(false);
  readonly previousInteractionWasASwipe$ = new BehaviorSubject(false);

  private readonly destroy$ = new Subject<boolean>();

  ngOnInit(): void {
    this.swipeable.currentSwiperContentXPosition$.pipe(takeUntil(this.destroy$)).subscribe(currentX => {
      this.swiperContent.nativeElement.style.left = `${currentX}px`;
    });

    this.swipeable.isOpen$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(isOpen => {
      this.swipeableContentOpen$.next(isOpen);
      document.querySelectorAll('iframe').forEach(
        i =>
          i.id === 'swiper-iframe' &&
          i.contentWindow.postMessage(
            {
              source: 'BetkingMobile',
              type: 'bk.x-sell',
              payload: {
                isOpen: isOpen,
              },
            },
            '*'
          )
      );
    });

    this.swipeable.isSwiping$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(isSwiping => this.isSwiping$.next(isSwiping));

    this.swipeable.hasBeenShown$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(hasBeenShown => this.swipeableContentHasBeenShown$.next(hasBeenShown));

    this.swipeable.previousInteractionWasASwipe$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(previousInteractionWasASwipe => this.previousInteractionWasASwipe$.next(previousInteractionWasASwipe));
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Custom x-sell element and content loading icon styles
    if (changes.xSellIconURL && changes.xSellIconURL.previousValue !== changes.xSellIconURL.currentValue) {
      if (this.xSellIconURL) {
        this.customXSellIconStyle['background-image'] = `url('${this.xSellIconURL}')`;
        this.customXSellContentLoadingIconStyle['background-image'] = `url('${this.xSellIconURL}')`;
        if (this.xSellIconBGColor) {
          this.customXSellIconStyle['background-color'] = this.xSellIconBGColor;
          this.customXSellContentLoadingIconStyle['background-color'] = this.xSellIconBGColor;
        }
      }
    }

    if (changes.spbIconURL && changes.spbIconURL.previousValue !== changes.spbIconURL.currentValue) {
      // Custom sportsbook element styles
      if (this.spbIconURL) {
        this.customSPBIconStyle['background-image'] = `url('${this.spbIconURL}')`;
        if (this.spbIconBGColor) {
          this.customSPBIconStyle['background-color'] = this.spbIconBGColor;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
