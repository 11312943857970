import { Injectable } from '@angular/core';
import { BetCouponGroup, CouponAction, UpdateCouponRequest, UpdateCouponResponse } from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { UPDATE_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';
import { ClientsideCouponWrapperService } from 'src/app/core/services/coupon/clientside-coupon-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class CouponStakeHandlerService {
  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfigService: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponWrapperService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore,
    private readonly currencyFormat: CurrencyFormatPipe,
    private readonly notificationService: NotificationService
  ) {}

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.UpdateStakeValue,
          brandID: this.appConfigService.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          stakeValue: stakeValue,
        })
      );
      this.couponStore.updateCouponData(response.updatedCoupon);

      return response;
    }
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupingStakeValue,
        brandID: this.appConfigService.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  checkCouponStake(): boolean {
    if (this.couponQuery.couponData.Stake < this.couponQuery.globalVariables.MinBetStake) {
      this.notificationService.showErrorNotification($localize`${UPDATE_COUPON_STATUS_CODES[19]} of ${this.getFormattedMinStake()}`);
      return false;
    }

    if (this.couponQuery.couponData.Stake > this.couponQuery.globalVariables.MaxBetStake) {
      this.notificationService.showErrorNotification($localize`${UPDATE_COUPON_STATUS_CODES[19]} of ${this.getFormattedMinStake()}`);
      return false;
    }

    return true;
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    this.couponStore.updateDefaultCouponStake(defaultCouponStake);
  }

  getFormattedMinStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinBetStake, '1.0-0');
  }

  getFormattedMinGroupStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinGroupingsBetStake, '1.0-0');
  }

  getFormattedTotalStake(): string {
    let totalCombinations = 0;

    this.couponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinGroupingsBetStake * totalCombinations);
  }
}
