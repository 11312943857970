import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-live-odds-widget',
  templateUrl: './live-odds-widget.component.html',
  styleUrls: ['./live-odds-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveOddsWidgetComponent {
  @Input() odds: OddModel[];
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() matchPosition: string = undefined; // Used for data layer events

  idTrackBy(index: number, odd: OddModel): number {
    return odd ? odd.id : index;
  }
}
