<div class="bet" data-testid="my-bets-bet">
  <div class="bet-details" *ngIf="item">
    <div class="bet-details-jp-won" *ngIf="item.isJackpotWinner" data-testid="my-bets-jp-won">
      <span class="bet-details-jp-won-label" i18n>You won the {{ jackpotName$ | async }}: </span>
      <span class="bet-details-jp-won-value" [innerHTML]="item.jackpotWinnings | currencyFormat"></span>
    </div>

    <div class="status-type-odds" (click)="getBetDetailsCall()" [ngClass]="{ 'jp-won': item.isJackpotWinner }">
      <div class="status-type" data-testid="my-bets-bet-status-type">
        <ng-container *ngIf="item.betFinalState !== betFinalState.Placed && item.betFinalState !== betFinalState.InEvaluation">
          <ng-container *ngIf="item.betFinalState === betFinalState.Void; else wonOrLost">
            <span class="status icon-void">
              <span class="material-icons">{{ 'remove_circle' }}</span>
            </span>
          </ng-container>
          <ng-template #wonOrLost>
            <ng-container *ngIf="!item.hasFreebetsVoucher; else freeBetStatus">
              <span class="status icon-won" *ngIf="item.stakeGross <= item.won">
                <span class="material-icons">{{ 'check_circle' }}</span>
              </span>
              <span class="status icon-lost" *ngIf="item.stakeGross > item.won">
                <span class="material-icons">{{ 'cancel' }}</span>
              </span>
            </ng-container>
            <ng-template #freeBetStatus>
              <span class="status icon-won" *ngIf="item.betFinalState === betFinalState.Won">
                <span class="material-icons">{{ 'check_circle' }}</span>
              </span>
              <span class="status icon-lost" *ngIf="item.betFinalState === betFinalState.Lost">
                <span class="material-icons">{{ 'cancel' }}</span>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <span class="total-combinations" *ngIf="item.couponType === 'Combinations'">{{ item.totalCombinations }}</span>
        <!-- Have to use couponType string, as coupon type id is not always available for some reason -->
        <span class="bet-type fc-logo" *ngIf="isFlexicut; else normalCouponType" data-testid="bet-search-results-bet-type"></span>
        <ng-template #normalCouponType>
          <span class="bet-type" data-testid="bet-search-results-bet-type">{{ item.couponType }}</span>
        </ng-template>

        <span class="bet-count" data-testid="bet-search-results-bet-count" *ngIf="item.couponType !== 'Combinations'">
          <ng-container
            *ngIf="item.betDetails && item.betDetails.events && item.betDetails.events.length > 0; else eventLength"
            [ngSwitch]="item.betFinalState"
          >
            <ng-container *ngSwitchCase="betFinalState.Placed" [ngTemplateOutlet]="eventLength"></ng-container>
            <ng-container *ngSwitchCase="betFinalState.Won" [ngTemplateOutlet]="wonAndEventLength"></ng-container>
            <ng-container *ngSwitchCase="betFinalState.Lost" [ngTemplateOutlet]="wonAndEventLength"></ng-container>
            <ng-container *ngSwitchCase="betFinalState.PartiallyWon" [ngTemplateOutlet]="wonAndEventLength"> </ng-container>
          </ng-container>
          <ng-template #eventLength>
            <span *ngIf="item.betFinalState !== betFinalState.Cashout && item.betFinalState !== betFinalState.Void">
              ({{ item.selectionCount }})
            </span>
          </ng-template>
          <ng-template #wonAndEventLength>
            <span>({{ getWonCount() }}/{{ item.selectionCount }})</span>
          </ng-template>
        </span>
        <span
          class="bet-state"
          *ngIf="item.betFinalState === betFinalState.Cashout || item.betFinalState === betFinalState.Void"
          [ngSwitch]="item.betFinalState"
        >
          <ng-container *ngSwitchCase="betFinalState.Cashout" [ngTemplateOutlet]="cashedOut"></ng-container>
          <ng-container *ngSwitchCase="betFinalState.Void" [ngTemplateOutlet]="voidedOrCancelled"></ng-container>

          <ng-template #cashedOut>
            (<span i18n>Cashed Out:</span> <span>{{ item.won | currencyFormat }}</span
            >)
          </ng-template>
          <ng-template #voidedOrCancelled>
            <span *ngIf="item.won || item.won === 0" i18n>(Voided)</span>
            <span *ngIf="item.won === null" i18n>(Cancelled)</span>
          </ng-template>
        </span>
      </div>
      <div class="expand-collapse-icon">
        <span
          class="expand-collapse-bet material-icons"
          [appDropdownArrowRotator]="!isCollapsed"
          data-testid="my-bets-bet-expand-more"
          [rotateDegrees]="180"
          >{{ 'expand_more' }}</span
        >
      </div>
    </div>

    <div class="bet-details-container" [@expandCollapse]="isCollapsed ? 'collapsed' : 'expanded'">
      <div class="loading-container" *ngIf="!isCollapsed && !item.betDetails" data-testid="my-bets-bet-loading">
        <app-loading-bar [show]="true" [delay]="200"></app-loading-bar>
      </div>
      <!-- Events Section -->
      <ng-container *ngIf="item.betDetails">
        <div class="events-container" data-testid="my-bets-events">
          <div
            class="event-row"
            data-testid="my-bets-bet-event"
            *ngFor="let event of item.betDetails?.events | orderBy : 'eventDate'; trackBy: indexTrackBy"
          >
            <!-- Event Status -->
            <ng-container [ngTemplateOutlet]="statusContent" [ngTemplateOutletContext]="{ statusId: event.eventStatusId }"></ng-container>

            <div
              class="event-details"
              [class.one-selection]="event.odds.length === 1 || item.isJackpotBets"
              data-testid="my-bets-bet-event-details"
            >
              <!-- One Odd Selection -->
              <ng-container *ngIf="event.odds.length === 1 || item.isJackpotBets">
                <div class="market-event-info">
                  <!-- Market Details -->
                  <ng-container
                    [ngTemplateOutlet]="marketContent"
                    [ngTemplateOutletContext]="{
                      oddsContent: item.couponType !== 'Combinations' ? event.odds : event.odds ? event.odds[0] : undefined,
                      homeTeam: event.homeTeamName,
                      awayTeam: event.awayTeamName,
                      statusId: event.eventStatusId
                    }"
                  >
                  </ng-container>
                  <!-- Event Details -->
                  <ng-container [ngTemplateOutlet]="eventContent"></ng-container>
                </div>
                <!-- Banker & Odd Value -->
                <ng-container
                  [ngTemplateOutlet]="bankerOddsContent"
                  [ngTemplateOutletContext]="{ oddsContent: event.odds ? event.odds[0] : undefined }"
                >
                </ng-container>
              </ng-container>

              <!-- Multiple Odd Selection -->
              <ng-container *ngIf="event.odds.length > 1 && !item.isJackpotBets">
                <div class="multiple-odds-title" data-testid="my-bets-bet-event-title" i18n>Combination selection</div>
                <div class="markets-container">
                  <ng-container *ngFor="let odd of event.odds; trackBy: indexTrackBy">
                    <div class="market-details" data-testid="my-bets-bet-event-details">
                      <!-- Event Status -->
                      <ng-container
                        [ngTemplateOutlet]="statusContent"
                        [ngTemplateOutletContext]="{ statusId: odd.oddStatusId }"
                      ></ng-container>
                      <!-- Market Details -->
                      <ng-container
                        [ngTemplateOutlet]="marketContent"
                        [ngTemplateOutletContext]="{
                          oddsContent: odd,
                          homeTeam: event.homeTeamName,
                          awayTeam: event.awayTeamName,
                          statusId: odd.oddStatusId
                        }"
                      >
                      </ng-container>
                      <!-- Banker & Odd Value -->
                      <ng-container [ngTemplateOutlet]="bankerOddsContent" [ngTemplateOutletContext]="{ oddsContent: odd }"></ng-container>
                    </div>
                  </ng-container>
                </div>
                <!-- Event Details -->
                <ng-container [ngTemplateOutlet]="eventContent"></ng-container>
              </ng-container>
            </div>

            <ng-template let-statusId="statusId" #statusContent>
              <div class="event-status" data-testid="my-bets-bet-event-status">
                <ng-container [ngSwitch]="statusId">
                  <!-- running -->
                  <ng-container *ngSwitchCase="recentBetsStatus.Running" [ngTemplateOutlet]="runningEvent"> </ng-container>
                  <!-- won -->
                  <ng-container
                    *ngSwitchCase="recentBetsStatus.Won"
                    [ngTemplateOutlet]="wonEvent"
                    [ngTemplateOutletContext]="{ isHalfWon: false }"
                  ></ng-container>
                  <!-- lost -->
                  <ng-container
                    *ngSwitchCase="recentBetsStatus.Lost"
                    [ngTemplateOutlet]="lostEvent"
                    [ngTemplateOutletContext]="{ isHalfLost: false }"
                  ></ng-container>
                  <!-- cancelled -->
                  <ng-container *ngSwitchCase="recentBetsStatus.Cancelled" [ngTemplateOutlet]="cancelledEvent"> </ng-container>
                  <!-- cashout -->
                  <ng-container *ngSwitchCase="recentBetsStatus.CashedOut" [ngTemplateOutlet]="cashoutEvent"> </ng-container>
                  <!-- half lost -->
                  <ng-container
                    *ngSwitchCase="recentBetsStatus.HalfLost"
                    [ngTemplateOutlet]="lostEvent"
                    [ngTemplateOutletContext]="{ isHalfLost: true }"
                  ></ng-container>
                  <!-- half won -->
                  <ng-container
                    *ngSwitchCase="recentBetsStatus.HalfWon"
                    [ngTemplateOutlet]="wonEvent"
                    [ngTemplateOutletContext]="{ isHalfWon: true }"
                  ></ng-container>

                  <ng-template #runningEvent>
                    <span class="status icon-running" *ngIf="!event.isLive"></span>
                    <span class="status icon-running-live" *ngIf="event.isLive">
                      <span class="inner-circle"></span>
                    </span>
                  </ng-template>
                  <ng-template #wonEvent let-isHalfWon="isHalfWon">
                    <span class="status icon-won" [class.half-won]="isHalfWon">
                      <span class="material-icons">{{ 'check_circle' }}</span>
                    </span>
                  </ng-template>
                  <ng-template #lostEvent let-isHalfLost="isHalfLost">
                    <span class="status icon-lost" [class.half-lost]="isHalfLost">
                      <span class="material-icons">{{ 'cancel' }}</span>
                    </span>
                  </ng-template>
                  <ng-template #cancelledEvent>
                    <span class="status icon-void">
                      <span class="material-icons">{{ 'remove_circle' }}</span>
                    </span>
                  </ng-template>
                  <ng-template #cashoutEvent>
                    <span class="status icon-cashout"></span>
                  </ng-template>
                </ng-container>
              </div>
            </ng-template>

            <ng-template let-odds="oddsContent" let-homeTeam="homeTeam" let-awayTeam="awayTeam" let-statusId="statusId" #marketContent>
              <div class="market-status" data-testid="my-bets-bet-event-market-status">
                <div class="market" *ngIf="item.couponType === 'Combinations'">
                  <span class="selection-name" data-testid="my-bets-bet-event-details-selection-name">
                    <span>{{ parseSelectionName(odds.marketTypeId, odds.selectionName, homeTeam, awayTeam) }}</span>
                  </span>
                  <span class="divider">-</span>
                  <span class="market-name" data-testid="my-bets-bet-event-details-market-name">{{ odds.marketName }}</span>
                </div>
                <div class="market" *ngIf="item.couponType !== 'Combinations'">
                  <span
                    class="selection-name"
                    data-testid="my-bets-bet-event-details-selection-name"
                    *ngFor="let odd of odds; let i = index; trackBy: indexTrackBy"
                  >
                    <span class="or-divider" *ngIf="i !== 0" i18n>or</span>
                    <span>{{ parseSelectionName(odd.marketTypeId, odd.selectionName, homeTeam, awayTeam) }}</span>
                  </span>
                  <span class="divider">-</span>
                  <span class="market-name" data-testid="my-bets-bet-event-details-market-name">{{ odds[0].marketName }}</span>
                </div>
                <div class="status-text">
                  <ng-container [ngSwitch]="statusId">
                    <!-- half lost -->
                    <span *ngSwitchCase="recentBetsStatus.HalfLost" i18n>(Half Lost)</span>
                    <!-- half won -->
                    <span *ngSwitchCase="recentBetsStatus.HalfWon" i18n>(Half Won)</span>
                  </ng-container>
                </div>
              </div>
            </ng-template>

            <ng-template #eventContent>
              <!-- prematch event -->
              <div
                class="event-container"
                *ngIf="
                  (!event.isLive || event.eventStatusId !== recentBetsStatus.Running || !myBetsQuery.isLiveDetailsPollingEnabled) &&
                  !event.marketOutright &&
                  !event.isGoalScorer
                "
                data-testid="my-bets-bet-event-details-event-container"
              >
                <div class="event">
                  <ng-container *ngIf="(myBetsQuery.selectedProductTab$ | async) === productType.Virtuals">
                    <span class="championship">
                      <ng-container *ngIf="!isVirtualsInstantLeague">{{ event.categoryName }} | </ng-container>
                      {{ event.championship }}
                    </span>
                    <span class="divider">|</span>
                  </ng-container>
                  <span class="event-name">{{ event.homeTeamName }}</span>
                  <span class="divider" *ngIf="!event.fullTimeScore && event.awayTeamName">{{ 'v' }}</span>
                  <span class="event-score" *ngIf="event.fullTimeScore">
                    <span class="ft-score">{{ event.fullTimeScore }}</span>
                  </span>
                  <span class="event-name">{{ event.awayTeamName }}</span>
                  <span class="ht-score" *ngIf="event.halfTimeScore">
                    (<span i18n>HT:</span> <span>{{ event.halfTimeScore }}</span
                    >)
                  </span>
                  <span class="divider">|</span>
                  <span class="event-date" *ngIf="event.result === 'Unset' && !event.isLive">
                    {{ event.eventDate | weekDays }} {{ event.eventDate | date : 'HH:mm' }}
                  </span>
                  <span class="event-ended" *ngIf="event.result === 'Unset' && event.isLive">
                    <span i18n>In Play</span>
                    <span class="blink">'</span>
                  </span>
                  <span class="event-ended" *ngIf="event.result !== 'Unset'" i18n>Event Ended</span>
                </div>
              </div>

              <!-- outright or goalscorer event -->
              <div
                class="event-container"
                *ngIf="
                  (!event.isLive || event.eventStatusId !== recentBetsStatus.Running || !myBetsQuery.isLiveDetailsPollingEnabled) &&
                  (event.marketOutright === 1 || event.isGoalScorer)
                "
                data-testid="my-bets-bet-event-details-event-container"
              >
                <div class="event">
                  <span class="event-name">{{ event.eventName }}</span>
                  <span class="divider">|</span>
                  <span class="event-date" *ngIf="!event.fullTimeScore">
                    {{ event.eventDate | weekDays }} {{ event.eventDate | date : 'HH:mm' }}
                  </span>
                  <span class="event-ended" *ngIf="event.fullTimeScore" i18n>Event Ended</span>
                </div>
              </div>

              <!-- live event -->
              <div
                class="event-container"
                (click)="redirectToLiveEvent(event.eventId)"
                *ngIf="
                  event.isLive && event.eventStatusId === recentBetsStatus.Running && liveDetails && myBetsQuery.isLiveDetailsPollingEnabled
                "
                data-testid="my-bets-bet-event-details-event-container"
              >
                <div class="event" *ngIf="getLiveEventDetails(event.eventId) as liveInfo">
                  <span class="event-name">{{ event.homeTeamName }}</span>
                  <span class="event-live-score" *ngIf="liveInfo.homeGameScore && liveInfo.awayGameScore">
                    {{ liveInfo.homeGameScore + ' - ' + liveInfo.awayGameScore }}
                  </span>
                  <span class="event-name">{{ event.awayTeamName }}</span>
                  <span class="divider">|</span>
                  <span class="event-live-details">
                    <span class="event-live-status" *ngIf="liveInfo.matchStatus">
                      {{ myBetsQuery.getLiveMatchStatusLabel(liveInfo) }}
                    </span>
                    <span class="event-live-time" *ngIf="liveInfo.matchTime">
                      <span>{{ liveInfo.matchTime }}</span>
                      <span class="blink">'</span>
                    </span>
                  </span>
                </div>
              </div>
            </ng-template>

            <ng-template let-odd="oddsContent" #bankerOddsContent>
              <div class="banker-odd-value" data-testid="my-bets-bet-event-details-odd-value">
                <div class="banker" *ngIf="odd.isBanker" i18n>Banker</div>

                <div class="odd-value" [class.boosted]="!!odd.unboostedOddValue">
                  <div class="normal-odd-value">
                    <i *ngIf="!!odd.isBoosted" class="odds-boost-badge"></i>
                    <span>{{ odd.oddValue | number : '1.2-2' }}</span>
                  </div>
                  <div *ngIf="!!odd.isBoosted" class="unboosted-odd-value">
                    {{ odd.unboostedOddValue | number : '1.2-2' }}
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <!-- Rebet Section -->
      <app-rebet
        [couponCode]="item.couponCode"
        [couponCanBeRebet]="!item.isJackpotBets && isRebetEnabled"
        [selectionCount]="item.selectionCount"
        [smallButton]="true"
      >
      </app-rebet>

      <!-- Bet Information Section -->
      <div class="bet-info-container" *ngIf="item.betDetails" data-testid="my-bets-bet-info">
        <div class="header">
          <div class="bet-info" (click)="toggleBetInfoCall()" data-testid="my-bets-bet-info-btn">
            <span class="material-icons" data-testid="my-bets-bet-info-icon">{{ 'info' }}</span>
            <div class="label" data-testid="my-bets-bet-info-label" i18n>Bet Information</div>
          </div>
        </div>
        <div class="content" [@expandCollapse]="!isBetInfoCollapsed ? 'expanded' : 'collapsed'">
          <div class="content-info-container" data-testid="my-bets-bet-info-content">
            <div class="details-row">
              <div class="details-content odd" data-testid="my-bets-bet-info-content-code">
                <div class="label" data-testid="my-bets-bet-info-content-code-label" i18n>Code</div>
                <div
                  class="value"
                  (click)="!item.isJackpotBets ? navigateToBetDetailsCall() : undefined"
                  [innerHTML]="item.couponCode | highlight : filterBets"
                  data-testid="my-bets-bet-info-content-code-value"
                ></div>
              </div>
              <div class="details-content even" data-testid="my-bets-bet-info-content-date">
                <div class="label" data-testid="my-bets-bet-info-content-date-label" i18n>Date Placed</div>
                <div class="value" data-testid="my-bets-bet-info-content-date-value">
                  {{ item.couponDate | weekDays }} {{ item.couponDate | date : 'HH:mm' }}
                </div>
              </div>
            </div>
            <div class="details-row" data-testid="my-bets-bet-info-content-bet-type">
              <div class="details-content bet-type odd" [class.has-flexicut]="isFlexicut" [class.has-flexicut-first]="isFlexicut">
                <div class="label" data-testid="my-bets-bet-info-content-bet-type-label" i18n>Bet Type</div>
                <div class="value" [class.has-flexicut]="isFlexicut" data-testid="my-bets-bet-info-content-bet-type-value">
                  {{ item.couponType }}
                </div>
              </div>
              <div class="details-content even" *ngIf="item.isJackpotBets">
                <div class="label" i18n>Total Combinations</div>
                <div class="value">{{ item.totalCombinations }}</div>
              </div>
            </div>
            <div class="details-row" data-testid="my-bets-condition" *ngIf="isFlexicut">
              <div class="details-content even" [class.has-flexicut]="isFlexicut">
                <div class="label" data-testid="my-bets-condition-label" i18n>Condition</div>
                <div class="value" data-testid="my-bets-condition-value">
                  <span>{{ item.selectionCount - item.flexicutDetails.Cut }}</span>
                  <span i18n>+ Correct</span>
                </div>
              </div>
            </div>
            <div class="details-row" *ngIf="item.betDetails.turnoverTax > 0">
              <div class="details-content even" data-testid="my-bets-bet-info-content-tax">
                <div class="label">
                  <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>VAT</ng-container>
                  <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Excise Duty</ng-container>
                  <span class="perc" *ngIf="taxPercentageToShow">({{ taxPercentageToShow }})</span>
                </div>
                <div class="value">{{ item.betDetails.turnoverTax | currencyFormat }}</div>
              </div>
              <div class="details-content odd" data-testid="my-bets-bet-info-content-net-value">
                <div class="label" data-testid="my-bets-bet-info-content-net-value-label" i18n>Net Stake</div>
                <div class="value" data-testid="my-bets-bet-info-content-net-value-value">
                  {{ item.stakeGross - item.betDetails.turnoverTax | currencyFormat }}
                </div>
              </div>
            </div>

            <!-- System Bet -->
            <div class="details-row" *ngIf="item.couponType === 'Combinations'">
              <div class="details-content even" data-testid="my-bets-bet-info-content-min-odds">
                <div class="label" data-testid="my-bets-bet-info-content-min-odds-label" i18n>Min. Odds</div>
                <div class="value" data-testid="'my-bets-bet-info-content-min-odds-value">
                  {{ item.betDetails.minOdd | number : '1.2-2' }}
                </div>
              </div>
              <div class="details-content odd" data-testid="my-bets-bet-info-content-max-odds">
                <div class="label" data-testid="my-bets-bet-info-content-max-odds-label" i18n>Max. Odds</div>
                <div class="value" data-testid="my-bets-bet-info-content-max-odds-value">
                  {{ item.betDetails.maxOdd | number : '1.2-2' }}
                </div>
              </div>
            </div>
            <div class="details-row" *ngIf="item.couponType === 'Combinations'">
              <div class="details-content even" data-testid="my-bets-bet-info-content-min-win">
                <div class="label" data-testid="my-bets-bet-info-content-min-win-label" i18n>Min. Win</div>
                <div class="value" data-testid="my-bets-bet-info-content-min-win-value">
                  {{ item.betDetails.netStakeMinWin | currencyFormat }}
                </div>
              </div>
              <div class="details-content odd" data-testid="my-bets-bet-info-content-max-win">
                <div class="label" data-testid="my-bets-bet-info-content-max-win-label" i18n>Max. Win</div>
                <div class="value" data-testid="my-bets-bet-info-content-max-win-value">
                  {{ item.betDetails.netStakeMaxWin | currencyFormat }}
                </div>
              </div>
            </div>
            <div
              class="details-row"
              [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled"
              *ngIf="item.couponType === 'Combinations' && item.betDetails.minBonus + item.betDetails.maxBonus > 0"
            >
              <div
                class="details-content even bonus"
                *ngIf="item.betDetails.minBonus !== 0"
                data-testid="my-bets-bet-info-content-min-bonus"
              >
                <div class="label" data-testid="my-bets-bet-info-content-bonus-label">
                  <div class="icon" data-testid="my-bets-bet-info-content-min-bonus-icon"></div>
                  <div class="perc" *ngIf="item.betDetails.minPercentageBonus" data-testid="my-bets-bet-info-content-min-bonus-perc">
                    +{{ item.betDetails.minPercentageBonus * 100 | number : '1.0-0' }}% &nbsp;
                  </div>
                  <span i18n>Min. Acca Bonus!</span>
                </div>
                <div class="value">
                  <div class="arrow"></div>
                  <div class="number" data-testid="my-bets-bet-info-content-min-bonus-value">
                    {{ item.betDetails.minBonus | currencyFormat }}
                  </div>
                </div>
              </div>
              <div
                class="details-content odd bonus"
                *ngIf="item.betDetails.maxBonus !== 0"
                data-testid="my-bets-bet-info-content-max-bonus"
              >
                <div class="label">
                  <div class="icon" data-testid="my-bets-bet-info-content-max-bonus-icon"></div>
                  <div class="perc" *ngIf="item.betDetails.maxPercentageBonus" data-testid="my-bets-bet-info-content-max-bonus-perc">
                    +{{ item.betDetails.maxPercentageBonus * 100 | number : '1.0-0' }}% &nbsp;
                  </div>
                  <span i18n>Max. Acca Bonus!</span>
                </div>
                <div class="value">
                  <div class="arrow"></div>
                  <div class="number" data-testid="my-bets-bet-info-content-max-bonus-value">
                    {{ item.betDetails.maxBonus | currencyFormat }}
                  </div>
                </div>
              </div>
            </div>
            <div class="details-row" *ngIf="item.couponType === 'Combinations' && item.betDetails.maxWithholdingTax > 0">
              <div class="details-content even" data-testid="my-bets-bet-info-content-min-witholding-tax">
                <div class="label" data-testid="my-bets-bet-info-content-min-witholding-tax-label">
                  <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Min Inc. Tax</ng-container>
                  <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Min. WTH Tax</ng-container>
                </div>
                <div class="value" data-testid="my-bets-bet-info-content-min-witholding-tax-value">
                  {{ item.betDetails.minWithholdingTax | currencyFormat }}
                </div>
              </div>
              <div class="details-content odd" data-testid="my-bets-bet-info-content-max-witholding-tax">
                <div class="label" data-testid="my-bets-bet-info-content-max-witholding-tax-label">
                  <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Max Inc. Tax</ng-container>
                  <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Max. WTH Tax</ng-container>
                </div>
                <div class="value" data-testid="my-bets-bet-info-content-max-witholding-tax-value">
                  {{ item.betDetails.maxWithholdingTax | currencyFormat }}
                </div>
              </div>
            </div>

            <!-- Not System Bet -->
            <div class="details-row" *ngIf="item.isJackpotBets && (item.exciseDuty || item.incomeTax)">
              <div class="details-content odd" *ngIf="item.exciseDuty" data-testid="my-bets-bet-info-content-excise-duty">
                <div class="label" data-testid="my-bets-bet-info-content-excise-duty-label" i18n>
                  Excise Duty ({{ item.exciseDutyPercentage }}%)
                </div>
                <div class="value" data-testid="my-bets-bet-info-content-excise-duty-value">{{ item.exciseDuty | currencyFormat }}</div>
              </div>
              <div class="details-content even" *ngIf="item.incomeTax" data-testid="my-bets-bet-info-content-witholding-tax">
                <div class="label" data-testid="my-bets-bet-info-content-witholding-tax-label" i18n>
                  Withholding Tax ({{ item.incomeTaxPercentage }}%)
                </div>
                <div class="value" data-testid="my-bets-bet-info-content-witholding-tax-value">{{ item.incomeTax | currencyFormat }}</div>
              </div>
            </div>
            <div class="details-row" *ngIf="item.couponType !== 'Combinations' && item.betDetails.netStakeMaxWin">
              <div
                class="details-content even"
                data-testid="my-bets-bet-info-content-gross-winnings"
                [class.has-flexicut]="isFlexicut"
                [class.has-flexicut-last]="isFlexicut"
              >
                <div class="label" data-testid="my-bets-bet-info-content-gross-winnings-label" i18n>Potential Winnings (Gross)</div>
                <div class="value" data-testid="my-bets-bet-info-content-gross-winnings-value">
                  {{ item.betDetails.netStakeMaxWin | currencyFormat }}
                </div>
              </div>
            </div>
            <div
              class="details-row"
              *ngIf="item.couponType !== 'Combinations'"
              [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled"
            >
              <div
                class="details-content bonus"
                data-testid="my-bets-bet-info-content-bonus"
                *ngIf="item.betDetails.maxBonus && item.betDetails.maxBonus !== 0"
              >
                <div class="label" data-testid="my-bets-bet-info-content-max-bonus-label">
                  <div class="icon" data-testid="my-bets-bet-info-content-max-bonus-icon"></div>
                  <div class="perc" *ngIf="item.betDetails.maxPercentageBonus" data-testid="my-bets-bet-info-content-max-bonus-perc">
                    +{{ item.betDetails.maxPercentageBonus * 100 | number : '1.0-0' }}% &nbsp;
                  </div>
                  <span i18n>Acca Bonus!</span>
                </div>
                <div class="value">
                  <div class="arrow"></div>
                  <div class="number" data-testid="my-bets-bet-info-content-max-bonus-value">
                    {{ item.betDetails.maxBonus | currencyFormat }}
                  </div>
                </div>
              </div>
            </div>
            <div class="details-row" *ngIf="item.couponType !== 'Combinations' && item.betDetails.maxWithholdingTax > 0">
              <div
                class="details-content"
                data-testid="my-bets-bet-info-content-max-witholding-tax"
                [ngClass]="item.betDetails.maxBonus && item.betDetails.maxBonus !== 0 ? 'even' : 'odd'"
              >
                <div class="label" data-testid="my-bets-bet-info-content-max-witholding-tax-label">
                  <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Income TAX (15%)</ng-container>
                  <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Withholding Tax</ng-container>
                </div>
                <div class="value" data-testid="my-bets-bet-info-content-max-witholding-value">
                  {{ item.betDetails.maxWithholdingTax | currencyFormat }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bet-overview">
      <my-bets-freebet-voucher
        *ngIf="item.hasFreebetsVoucher"
        [voucher]="freebetVoucher$ | async"
        [showStakeDisclaimer]="!isBetInfoCollapsed"
      ></my-bets-freebet-voucher>
      <div class="stake" *ngIf="!item.hasFreebetsVoucher" data-testid="my-bets-bet-info-content-stake">
        <div class="label" data-testid="my-bets-bet-info-content-stake-label" i18n>Stake:</div>
        <div class="value" data-testid="my-bets-bet-info-content-stake-value">
          <span [innerHTML]="item.stakeGross | currencyFormat | highlight : filterBets"></span>
        </div>
      </div>
      <ng-container *ngIf="item.couponType !== 'Combinations'; else combinations">
        <div class="total-odds" *ngIf="item.totalOdds" data-testid="my-bets-bet-info-odds">
          <div class="label" i18n>Total Odds:</div>
          <div class="value" data-testid="my-bets-bet-info-odds-value">
            <span [innerHTML]="item.totalOdds | number : '1.2-2' | highlight : filterBets"></span>
          </div>
        </div>
      </ng-container>
      <ng-template #combinations>
        <div class="total-odds" *ngIf="item.betDetails?.maxOdd" data-testid="my-bets-bet-info-odds">
          <div class="label" i18n>Total Odds:</div>
          <div class="value comb" data-testid="my-bets-bet-info-odds-value">
            <span [innerHTML]="item.betDetails?.maxOdd | number : '1.2-2' | highlight : filterBets"></span>
          </div>
        </div>
      </ng-template>
      <div class="pot-win">
        <div class="pot-win-details" data-testid="my-bets-bet-info-content-potentional-win">
          <div class="label" data-testid="my-bets-bet-info-content-potentional-win-label" i18n>Potential Win:</div>
          <div class="value" data-testid="my-bets-bet-info-content-potentional-win-value">
            <span [innerHTML]="item.maxWinNet | currencyFormat | highlight : filterBets"></span>
            <span *ngIf="item.isJackpotBets && item.incomeTaxDisclaimer && item.incomeTaxPercentage > 0">*</span>
          </div>
        </div>
        <div
          class="tax-disclaimer"
          data-testid="my-bets-bet-info-content-tax-disclamer"
          *ngIf="item.isJackpotBets && item.incomeTaxDisclaimer && item.incomeTaxPercentage > 0"
        >
          {{ item.incomeTaxDisclaimer }}
        </div>
      </div>
    </div>

    <div class="bet-overview won" *ngIf="item.isWinningBet">
      <div class="won-bet" data-testid="my-bets-bet-won-bet">
        <div class="label" data-testid="my-bets-bet-won-bet-label">
          <ng-container [ngSwitch]="item.betFinalState">
            <ng-container *ngSwitchCase="betFinalState.Cashout" i18n>Cashed out:</ng-container>
            <ng-container *ngSwitchCase="betFinalState.Void" i18n>Stake Refunded:</ng-container>
            <ng-container *ngSwitchDefault i18n>You won:</ng-container>
          </ng-container>
        </div>
        <div
          i18n
          class="value"
          [class.loss]="!item.hasFreebetsVoucher && (!item.won || item.won <= item.stakeGross)"
          data-testid="my-bets-bet-won-bet-value"
        >
          <span *ngIf="item.won" [innerHTML]="item.won | currencyFormat | highlight : filterBets"></span>
          <span *ngIf="!item.won" [innerHTML]="item.stakeGross | currencyFormat | highlight : filterBets"></span>
        </div>
      </div>
    </div>

    <div class="bonus-cashout-disabled" *ngIf="isFlexicut" i18n>Accumulator Bonus & Cashout are unavailable when Flexicut is used</div>

    <app-cashout
      [code]="item.couponCode"
      [parentComponent]="parentComponent"
      *ngIf="item.betFinalState !== betFinalState.Cashout"
    ></app-cashout>

    <div class="logo-container" data-testid="my-bets-logo-container">
      <span class="logo"></span>
    </div>
  </div>
</div>
