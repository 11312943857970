import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CouponType } from 'clientside-coupon';
import { ApplicationService } from 'src/app/core/services/application.service';
import { expandCollapse } from 'src/app/shared/animations';
import { CouponGroupingType, OddsByMarket } from 'src/app/shared/models/coupon.model';
import { CategoryModel, MatchModel, SportModel, TournamentModel } from 'src/app/shared/models/sport.model';

import { OddsLayoutResolverService } from 'mobile-virtuals-soccer';
import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { ButtonType } from 'src/app/shared/models/button.model';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';

@Component({
  selector: 'app-virtuals-coupon-selections',
  templateUrl: './virtuals-coupon-selections.component.html',
  styleUrls: ['./virtuals-coupon-selections.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OddsLayoutResolverService],
})
export class VirtualsCouponSelectionsComponent {
  @Input() expiredWeeks: number[];
  @Input() selectedGroupingTab: CouponGroupingType;
  @Output() readonly removeOddsForWeek = new EventEmitter<number>();

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  groupingType: typeof CouponGroupingType = CouponGroupingType;

  smallButtonStyle: any = {
    fontSize: '12px',
    padding: '4px 16px',
    borderRadius: '50px',
    minWidth: 'unset',
  };

  constructor(
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly applicationService: ApplicationService,
    private readonly couponService: VirtualsCouponService,
    private readonly router: Router
  ) {}

  updateOddBankerStatus(oddId: number, banker: boolean): void {
    this.couponService.updateOddBankerStatus(oddId, banker);
  }

  removeOdd(selectionId: number): void {
    this.couponService.removeOdd(selectionId);
  }

  categoryTrackBy(index: number, category: CategoryModel): number {
    return category.id;
  }

  selectionTrackBy(index: number, item: SportModel): number {
    return item.id;
  }

  tournamentTrackBy(index: number, item: TournamentModel): number {
    return item.id;
  }

  matchTrackBy(index: number, item: MatchModel): number {
    return item.id;
  }

  oddsByMarketTrackBy(index: number, oddsByMarket: OddsByMarket): number {
    return oddsByMarket.marketId;
  }

  goToPage(id: number, name: string): void {
    const kebabCaseName = kebabCase(name);
    this.router.navigate([`sports/match/${id}${kebabCaseName ? `/${kebabCaseName}` : ''}`]);

    this.applicationService.closeAnySlideUps();
  }
}
